/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import configData from "./config/index.json";

const localConfig = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:ffb4fe27-ca8b-4b51-8782-fea50d98ce8f",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_Q7qMOi1av",
    "aws_user_pools_web_client_id": "2pra9t6qkpj489s17jbie7trfe",
    "oauth": {
        domain: 'icatchsocialsigin-dev.auth.ap-south-1.amazoncognito.com',
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'http://localhost:3000/',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code' // or 'token'
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};

const devConfig = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:c24d756b-bb6d-4f8b-8a1e-8c0ff728cae7",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_8DxwE3r6d",
    "aws_user_pools_web_client_id": "7cva1u9b5pesus78nj90vvp9nm",
    "oauth": {
        domain: 'icatchdevelopmentsociallogin.auth.ap-south-1.amazoncognito.com',
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://d2p34iofjq73xw.cloudfront.net/',
        redirectSignOut: 'https://d2p34iofjq73xw.cloudfront.net/',
        responseType: 'code' // or 'token'
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


const getEnvConfig = () => {
    switch (configData.react_app) {
        case 'local':
            return localConfig;
        case 'dev':
            return devConfig;
        default:
            return '';
    }
};

const awsConfig = getEnvConfig();

export default awsConfig;
