import React, {useEffect, useState} from "react";
import axios from 'axios';
import { ResponsiveBar } from '@nivo/bar';
import Loader from "../Loader";
import configData from "../../config/index.json";
import {useCookies} from "react-cookie";

const BarChart = ({ chartData }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cookies, removeCookie, setCookie] = useCookies(['clientPlatformID', 'clientChartState']);
    let expressURL = configData.express_url;

    useEffect(() => {
        if(chartData.monthly_stats.length > 0 && (chartData.overall_match_percentage > 0 || chartData.overall_mismatch_percentage > 0)){
            const transformedData = chartData.monthly_stats.map(item => ({
                month: item.month,
                Match: parseFloat(item.match_percentage),
                Mismatch: parseFloat(item.mismatch_percentage)
            }));
            setData(transformedData);
        }
        // if (cookies.clientPlatformID) {
        //     //const loggedPlatformID = cookies.clientPlatformID;
        //     const loggedPlatformID = {"platform_id": cookies.clientPlatformID};
        //
        //     // Make API call using axios with then/catch
        //     axios.post(expressURL + "api/dashboard/matchmismatchstats", loggedPlatformID)
        //         .then(response => {
        //             console.log("loggedPlatformID", loggedPlatformID);
        //             console.log("/matchmismatchstats response", response.data.monthly_stats);
        //             // Transform the data if needed
        //             console.log("if MONTHLY ")
        //             if (response.data.monthly_stats.length > 0) {
        //                 console.log("if MONTHLY >")
        //                 const transformedData = response.data.monthly_stats.map(item => ({
        //                     month: item.month,
        //                     Match: parseFloat(item.match_percentage),     // Convert match percentage to number
        //                     Mismatch: parseFloat(item.mismatch_percentage) // Convert mismatch percentage to number
        //                 }));
        //                 setCookie('clientChartState', true, { path: '/' });
        //                 setData(transformedData);
        //
        //             } else {
        //                 console.log("if MONTHLY <")
        //                 setCookie('clientChartState', false, { path: '/' });
        //             }
        //
        //             //setLoading(false);
        //         })
        //         .catch(err => {
        //             setError(err);
        //             //setLoading(false);
        //         });
        // }
    }, []);

    if (data.length === 0) {
        return <div>No Data Available</div>;
    }

    return (
        <div style={{ height: 300 }}>
            <ResponsiveBar
                data={data}
                keys={['Match', 'Mismatch']}
                indexBy="month"
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={0.7} // Space between each group of bars (increases spacing between groups)
                innerPadding={0} // Space between bars within the same group (increases spacing between bars)
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={({ id, data }) => id === 'Match' ? '#47AA45' : '#E7A343'} // Custom colors for bars
                borderRadius={5}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Month',
                    legendPosition: 'middle',
                    legendOffset: 32,
                    tickColor: '#fff', // Custom color for the bottom axis
                    format: (value) => value.slice(0, 3),
                    tickValues: (tick) => Math.abs(tick),
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Percentage',
                    legendPosition: 'middle',
                    legendOffset: -40,
                    tickColor: '#fff', // Custom color for the left axis
                    format: (value) => Math.abs(value), // Remove negative sign from tick values
                }}
                gridYValues={[]}  // Disable grid lines on the y-axis
                labelSkipWidth={999} // Effectively hide the labels by setting a large value
                labelSkipHeight={999}
                labelTextColor="#fff" // Custom color for the labels
                label={(d) => `${d.value}`} // Custom label positioning
                /*legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'top-left',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: -40,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 20,
                        symbolShape: ({ x, y, width, height, fill, borderColor }) => (
                            <rect
                                x={x}
                                y={y}
                                width={24}
                                height={20}
                                fill={fill}
                                stroke={borderColor}
                                rx={5} // Border radius of 5
                                ry={5} // Border radius of 5
                            />
                        ),
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ],
                        textColor: '#fff', // Color for legend text
                    }
                ]}*/
                theme={{
                    axis: {
                        ticks: {
                            text: {
                                fill: '#fff', // Color for axis tick labels (x and y axis)
                            }
                        },
                        legend: {
                            text: {
                                fill: '#fff', // Color for axis legends (Month and Percentage)
                            }
                        }
                    },
                    labels: {
                        text: {
                            fill: '#fff', // Color for bar labels
                        }
                    },
                    legends: {
                        text: {
                            fill: '#fff', // Color for legend text
                        }
                    },
                    grid: {
                        line: {
                            stroke: 'none', // This will ensure that no grid lines are drawn
                        }
                    }
                }}
                tooltip={({ id, value, indexValue, color }) => (
                    <div className="bar-tooltip-cvr">
                        <div className="bar-tooltip-in"
                            style={{
                                backgroundColor: color,
                            }}
                        />
                        <span className="f-12">{`${id} in ${indexValue}: ${value}`}</span>
                    </div>
                )}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
            />
            {/*<ResponsiveBar
                data={data}
                keys={['Match', 'Mismatch']}
                indexBy="month"
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'nivo' }}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Month',
                    legendPosition: 'middle',
                    legendOffset: 32
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Percentage',
                    legendPosition: 'middle',
                    legendOffset: -40
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
            />*/}
        </div>
    );
};

export default BarChart;
