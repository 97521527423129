import React, {useEffect, useState} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Slider,
    Checkbox,
    Typography,
    IconButton,
    Snackbar,
    TextField
} from '@mui/material';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import axios from "axios";
import configData from "../config/index.json";
import MuiAlert from "@mui/material/Alert";
import { useCookies } from 'react-cookie';
import swal from "sweetalert";
import Footer from "./Footer";
import Skeleton from '@mui/material/Skeleton';
import Pagination from "rc-pagination";

function Configuration() {

    const [clients, setClients] = useState([]);
    const [subClientDetails, setSubClientDetails] = useState([]);
    const [successOpen, setSuccessOpen] = useState(false);
    const [failedOpen, setFailedOpen] = useState(false);
    const [failedCatchOpen, setFailedCatchOpen] = useState(false);
    const [cookies] = useCookies(['clientPlatformID', 'clientClientID']);
    const [loading, setLoading] = useState(true);
    const [pageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    let expressURL = configData.express_url;

    useEffect(()=>{
        if (cookies.clientPlatformID) {
            getSubClientDetails()
        }
    },[cookies]);

    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));

    async function getSubClientDetails() {
        //let platform = {"platform_id" : 18};
        setLoading(true);
        // const loggedPlatformID = {"platform_id":cookies.clientPlatformID};
        // console.log("logedemail", loggedPlatformID)
        const payload = {
            platform_id: cookies.clientPlatformID,
            limit: pageSize,  // Page size from state
            offset: (currentPage - 1) * pageSize  // Calculate the offset based on the current page
        };
        await axios.post(expressURL + "icatch/subclients/getsubclientdetails", payload)
            .then(response => {
                console.log("sub client", response);
                if (response.data.status === 200) {
                    setLoading(false);
                    setClients(response.data.data.data);
                    setTotalRecords(response.data.data.totalRecords);
                } else {
                    // console.error('Failed to fetch sub client details');
                }
            }).catch(error => {
                setLoading(false);
                console.error('Error fetching sub client details:', error);
                swal({
                    title: 'Error!',
                    text: 'Something went wrong.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            })
    }

    const handleInputChange = (id, type, value) => {
        const numericValue = Math.max(0, Math.min(100, Number(value))); // Ensure the value stays within bounds (0 to 100)
        const updatedClients = clients.map(client =>
            client.client_id === id ? { ...client, [type]: numericValue } : client
        );
        setClients(updatedClients);
    };

    const handleSliderChange = (id, type, newValue) => {
        const updatedClients = clients.map(client => client.client_id === id ? { ...client, [type]: newValue } : client);
        setClients(updatedClients);
    };

    const handleCheckboxChange = (id, checked) => {
        const updatedClients = clients.map(client => client.client_id === id ? { ...client, enable_log: checked ? 'Y' : 'N' } : client);
        setClients(updatedClients);
    };

    async function handleSave(id)  {
        const client = clients.find(client => client.client_id === id);
        const loggedPlatformID = cookies.clientPlatformID;

        const payload = {
            client_id: client.client_id,
            enable_log: client.enable_log,
            voice_threshold: client.voice_threshold,
            face_threshold: client.face_threshold,
            id_threshold: client.id_threshold,
            platform_id: loggedPlatformID
        };

        await axios.post(expressURL + "icatch/subclients/updatesubclientconfig", payload)
            .then(response => {
                console.log("sub client", response);
                if (response.data.status === 200) {
                    console.log('Update successful:', response.data.data);
                    setSuccessOpen(true);
                } else {
                    console.error('Update failed:', response.data.message);
                    setFailedOpen(true);
                }
            }).catch(error => {
                console.error('Error updating configuration:', error);
                setFailedCatchOpen(true);
            })

        /*try {
            const response = await axios.post('/icatch/subclients/updatesubclientconfig', payload);
            if (response.data.status === 200) {
                console.log('Update successful:', response.data.data);
                // Optionally, show a success message to the user
            } else {
                console.error('Update failed:', response.data.message);
                // Optionally, show an error message to the user
            }
        } catch (error) {
            console.error('Error updating configuration:', error);
            // Optionally, show an error message to the user
        }
        console.log("Saved settings for client:", client);*/
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessOpen(false);
    };

    const handleFailedClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFailedOpen(false);
    };

    const handleFailedCatchClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFailedCatchOpen(false);
    };

    const PrevNextArrow = (current, type, originalElement) => {
        const handleClick = (e) => {
            e.preventDefault();
        };
        if (type === 'prev') {
            return <button onClick={handleClick}><i className="mdi mdi-chevron-left"/></button>;
        }
        if (type === 'next') {
            return <button onClick={handleClick}><i className="mdi mdi-chevron-right"/></button>;
        }
        return originalElement;
    };

    const paginatedData = clients;

    return (
        <>
            <div className="outer-main">
                <div className="main-container">
                    <div className="col-md-12 top-header py-3">
                        <div className="col-md-12 icon-wrap">
                            <TuneOutlinedIcon className="head-icon"/>
                        </div>
                        <div className="col-md-12 head-wrap">
                            Configuration
                        </div>
                    </div>
                    <div className="col-md-12 py-3">
                        <div className=" col-md-12 py-3 inside-content-wrapper">
                            <div className="inner-gray-content-wrap configuration p-3">
                                <TableContainer component={Paper} className="loadbalancer-table-container">
                                    <Table className="loadbalancer-table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="no-wrap">Client ID</TableCell>
                                                <TableCell className="no-wrap">Client Name</TableCell>
                                                <TableCell className="no-wrap">Voice Threshold</TableCell>
                                                <TableCell className="no-wrap">Face Threshold</TableCell>
                                                <TableCell className="no-wrap">ID Threshold</TableCell>
                                                <TableCell className="no-wrap">Log</TableCell>
                                                <TableCell className="no-wrap">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {loading ? (
                                                Array.from(new Array(5)).map((_, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell><Skeleton height={30}/></TableCell>
                                                        <TableCell><Skeleton height={30}/></TableCell>
                                                        <TableCell><Skeleton height={30}/></TableCell>
                                                        <TableCell><Skeleton height={30}/></TableCell>
                                                        <TableCell><Skeleton height={30}/></TableCell>
                                                        <TableCell><Skeleton height={30}/></TableCell>
                                                        <TableCell><Skeleton height={30}/></TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                paginatedData.length > 0 ? (
                                                    paginatedData.map((client, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell className="no-wrap">{client.client_id}</TableCell>
                                                            <TableCell className="no-wrap">{client.client_name}</TableCell>
                                                            {/*<TableCell className="no-wrap">*/}
                                                            {/*    {client.client_name ? client.client_name : "root"}*/}
                                                            {/*</TableCell>*/}
                                                            <TableCell className="no-wrap">
                                                                {/*<Typography variant="body2" align="center">{client.voice_threshold}</Typography>*/}
                                                                <TextField
                                                                    variant="outlined"
                                                                    value={client.voice_threshold}
                                                                    onChange={(e) => handleInputChange(client.client_id, 'voice_threshold', e.target.value)}
                                                                    // onBlur={() => handleSliderChange(client.client_id, 'voice_threshold', client.voice_threshold)}
                                                                    inputProps={{
                                                                        style: { textAlign: 'center' },
                                                                        min: 0,
                                                                        max: 100,
                                                                        type: 'number'
                                                                    }}
                                                                    size="small"
                                                                />
                                                                {/*<Slider
                                                                value={client.voice_threshold}
                                                                onChange={(e, newValue) => handleSliderChange(client.client_id, 'voice_threshold', newValue)}
                                                                min={0}
                                                                max={100}
                                                            />*/}
                                                            </TableCell>
                                                            <TableCell className="no-wrap">
                                                                {/*<Typography variant="body2" align="center">{client.face_threshold}</Typography>*/}
                                                                <TextField
                                                                    variant="outlined"
                                                                    value={client.face_threshold}
                                                                    onChange={(e) => handleInputChange(client.client_id, 'face_threshold', e.target.value)}
                                                                    // onBlur={() => handleSliderChange(client.client_id, 'face_threshold', client.voice_threshold)}
                                                                    inputProps={{
                                                                        style: { textAlign: 'center' },
                                                                        min: 0,
                                                                        max: 100,
                                                                        type: 'number'
                                                                    }}
                                                                    size="small"
                                                                />
                                                                {/*<Slider
                                                                value={client.face_threshold}
                                                                onChange={(e, newValue) => handleSliderChange(client.client_id, 'face_threshold', newValue)}
                                                                min={0}
                                                                max={100}
                                                            />*/}
                                                            </TableCell>
                                                            <TableCell className="no-wrap">
                                                                {/*<Typography variant="body2" align="center">{client.id_threshold}</Typography>*/}
                                                                <TextField
                                                                    variant="outlined"
                                                                    value={client.id_threshold}
                                                                    onChange={(e) => handleInputChange(client.client_id, 'id_threshold', e.target.value)}
                                                                    // onBlur={() => handleSliderChange(client.client_id, 'id_threshold', client.voice_threshold)}
                                                                    inputProps={{
                                                                        style: { textAlign: 'center' },
                                                                        min: 0,
                                                                        max: 100,
                                                                        type: 'number'
                                                                    }}
                                                                    size="small"
                                                                />
                                                                {/*<Slider
                                                                value={client.id_threshold}
                                                                onChange={(e, newValue) => handleSliderChange(client.client_id, 'id_threshold', newValue)}
                                                                min={0}
                                                                max={100}
                                                            />*/}
                                                            </TableCell>
                                                            <TableCell className="no-wrap">
                                                                <BootstrapTooltip title="Enable/Disable">
                                                                    <Checkbox
                                                                        checked={client.enable_log === 'Y'}
                                                                        onChange={(e) => handleCheckboxChange(client.client_id, e.target.checked)}
                                                                        sx={{
                                                                            color: client.enable_log === 'Y' ? 'rgba(0, 0, 0, 0.54)' : 'default',
                                                                            '&.Mui-checked': {
                                                                                color: 'rgba(0, 0, 0, 0.54)', // Customize the checked color here
                                                                            },
                                                                        }}
                                                                    />
                                                                </BootstrapTooltip>
                                                            </TableCell>
                                                            <TableCell className="no-wrap">
                                                                <BootstrapTooltip title="Save Change">
                                                                    <IconButton onClick={() => handleSave(client.client_id)}>
                                                                        <SaveIcon />
                                                                    </IconButton>
                                                                </BootstrapTooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={7} className="text-center f-18 font-bold py-3">
                                                            <p className="mb-0"><img src="/images/no-data.png" alt="No Data Found" style={{ width: '100px', height: '100px' }} /></p>
                                                            <p className="mb-0">No record available!</p>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className="col-md-12 d-flex justify-content-end p-0">
                                <div className="pagination-container shadow-sm">
                                    <Pagination className="pagination-data border-top f-12 pt-3 pb-2"
                                                current={currentPage}
                                                pageSize={pageSize}
                                                total={totalRecords}
                                                onChange={(page) => {
                                                    setCurrentPage(page);
                                                    getSubClientDetails();
                                                }}
                                                showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${totalRecords}`}
                                                showLessItems
                                                showSizeChanger={false}
                                                itemRender={PrevNextArrow}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
            {/* Snackbar*/}
            <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleClose}>
                <MuiAlert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>
                    Successfully updated!
                </MuiAlert>
            </Snackbar>

            <Snackbar open={failedOpen} autoHideDuration={3000} onClose={handleFailedClose}>
                <MuiAlert onClose={handleFailedClose} severity="error" variant="filled" sx={{ width: '100%' }}>
                    Update failed!
                </MuiAlert>
            </Snackbar>

            <Snackbar open={failedCatchOpen} autoHideDuration={3000} onClose={handleFailedCatchClose}>
                <MuiAlert onClose={handleFailedCatchClose} severity="error" variant="filled" sx={{ width: '100%' }}>
                    Error updating configuration!
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default Configuration;
