import React, {useEffect, useState, useRef} from "react";
import "../style.css";
import { Auth } from 'aws-amplify';
import swal from "sweetalert";
import { useNavigate } from 'react-router-dom';
import configData from "../config/index.json";
import axios from "axios";
import { useCookies } from 'react-cookie';
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {Modal} from "react-bootstrap";


function LogIn({ setSpinnerLoading, setIsAuthenticated  }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [session, setSession] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [newPassword, setnewPassword] = useState('');
    const [resetPasswordChallengeModalShow, setresetPasswordChallengeModalShow] = useState(false);
    const [requestNewPasswordVisible, setRequestNewPasswordVisible] = useState(false);
    const [requestConfNewPasswordVisible, setRequestConfNewPasswordVisible] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['clientEmail', 'clientName', 'clientPlatformID', 'readCount', 'clientAccessToken']);
    let expressURL = configData.express_url;

    const navigate = useNavigate();

    const validate = () => {
        let formErrors = {};
        if (!email) {
            formErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            formErrors.email = "Email address is invalid";
        }
        if (!password) {
            formErrors.password = "Password is required";
        }
        return formErrors;
    }

    const handleInputChange = (field, value) => {
        if (field === "email") setEmail(value);
        if (field === "password") setPassword(value);
        if (field === "firstName") setFirstName(value);
        if (field === "newPassword") setnewPassword(value);

        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: ""
        }));
    };

    const isFormValid = () => {
        return firstName && password && newPassword;
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    /*const handleLogin = function(event) {
        event.preventDefault();
        const formErrors = validate();
        if (Object.keys(formErrors).length === 0) {
            Auth.signIn(email, password)
                .then(response => {
                    console.log('User logged in:', response);
                    // API call to store the user details
                    console.log("EMAILL LOGIN", email)
                    axios.post(expressURL + "icatch/profile/getprofiledetails", { email: email })
                        .then(apiResponse => {
                            console.log("IN GET PROFILE")
                            if (apiResponse.data != null) {
                                console.log("IN if GET PROFILE")
                                console.log("apiResponse.data.data[0].first_name", apiResponse.data.data.first_name)
                                let clientName = apiResponse.data.data.first_name + ' ' + apiResponse.data.data.last_name;
                                setCookie('clientEmail', apiResponse.data.data.email, { path: '/' });
                                setCookie('clientName', apiResponse.data.data.full_name, { path: '/' });
                                setCookie('clientPlatformID', apiResponse.data.data.platform_id, { path: '/' });
                                setCookie('clientClientID', apiResponse.data.data.client_id, { path: '/' });
                                navigate('/dashboard');
                            }

                            console.log("apiResponse getprofiledetails", apiResponse.data);
                            // Navigate to dashboard after successful login and API call
                        })
                        .catch(error => {
                            console.error("Error fetching profile details catch1:", error);
                            swal({
                                title: 'Error!',
                                text: 'Something went wrong. Please login again.',
                                icon: 'error',
                                button: "OK",
                                closeOnClickOutside: false,
                            }).then((willReload) => {
                                if (willReload) {
                                    signOut()
                                }
                            });
                        });
                })
                .catch(error => {
                    console.error('Error logging in:', error);
                    //setErrors({ auth: 'Invalid email or password' });
                });
        } else {
            setErrors(formErrors);
        }
        console.log("Submit button is pressed");
    };*/

    const handleLogin = async (event) => {
        event.preventDefault();
        const formErrors = validate();

        if (Object.keys(formErrors).length === 0) {
            setSpinnerLoading(true);
            try {
                // Call the backend API for login
                const response = await axios.post(expressURL + "icatch/cognito/login", { email, password });
                console.log("response.data.data", response.data.data);
                if (response.data.status === 200) {
                    const userData = response.data.data;

                    setCookie('clientEmail', userData.email, { path: '/' });
                    setCookie('clientName', userData.full_name, { path: '/' });
                    setCookie('clientPlatformID', userData.platform_id, { path: '/' });
                    setCookie('clientClientID', userData.client_id, { path: '/' });
                    setCookie('clientAccessToken', userData.accessToken, { path: '/' });
                    setIsAuthenticated(true);
                    navigate('/dashboard');
                } else {
                    setSpinnerLoading(false);
                    swal({
                        title: 'Error!',
                        text: response.data.message || 'Login failed. Please try again.',
                        icon: 'error',
                        button: 'OK',
                        closeOnClickOutside: false,
                    });
                }
            } catch (error) {
                setSpinnerLoading(false);
                console.log("session", error)
                console.log("session", error.response?.data?.session)
                const errorMessage = error.response?.data?.message;
                const errorChallenge = error.response?.data?.challenge;

                if (errorMessage === 'Please set a new password to complete your sign-in' && errorChallenge === 'NEW_PASSWORD_REQUIRED') {
                    setSession(error.response?.data?.session);
                    handlePasswordOpenModal();
                    /*swal({
                        text: error.response?.data?.message,
                        icon: 'info',
                        button: 'OK',
                        closeOnClickOutside: false,
                    }).then(() => {
                        handlePasswordOpenModal();
                    });*/
                } else {
                    swal({
                        title: 'Error!',
                        text: errorMessage || error.message,
                        icon: 'error',
                        button: 'OK',
                        closeOnClickOutside: false,
                    });
                }
            } finally {
                setSpinnerLoading(false);
            }
        } else {
            setErrors(formErrors);
        }
        console.log("Submit button is pressed");
    };

    const newpasswordchallenge = async (event) => {
        event.preventDefault();
        handlePasswordCloseModal();
        try {
            const response = await axios.post(expressURL + "icatch/cognito/newpaaswordchallenge", {
                email,
                password,
                newPassword,
                session,
                family_name: firstName,
                given_name: firstName
            });

            if (response.status === 200) {
                swal({
                    title: 'Success!',
                    text: 'Password has been successfully updated. Please log in with your new password.',
                    icon: 'success',
                    button: 'OK',
                    closeOnClickOutside: false,
                    className: 'swal-text-center'
                });
            } else {
                swal({
                    title: 'Error!',
                    text: response.data.message || 'Failed to update password. Please try again.',
                    icon: 'error',
                    button: 'OK',
                    closeOnClickOutside: false,
                });
            }
        } catch (error) {
            console.log("newpasswordchallenge error", error.response?.data?.message)
            swal({
                title: 'Error!',
                text: error.response?.data?.message || 'An error occurred while updating your password.',
                icon: 'error',
                button: 'OK',
                closeOnClickOutside: false,
            });
        }
    };

    /*const signOut = () => {
        Auth.signOut()
            .then(() => {
                removeCookie('clientName');
                removeCookie("clientEmail");
                removeCookie("clientPlatformID");
                removeCookie("clientClientID");
                removeCookie("readCount");
                removeCookie("clientAccessToken");
                window.location.href = '/';
            })
            .catch(error => {
                console.error('Sign out error:', error);
                swal('Failed to sign out. Please try again.');
            });
    }*/

    /*const handleLogin = async function(event) {
        event.preventDefault();
        const formErrors = validate();
        if (Object.keys(formErrors).length === 0) {
            try {
                const response = await Auth.signIn(email, password);
                console.log('User logged in:', response);

                // API call to store the user details
                try {
                    const apiResponse = await axios.post(expressURL + "icatch/profile/getprofiledetails", {
                        email: email
                    });
                    if (apiResponse.data != null) {
                        let clientName = apiResponse.data.data[0].first_name + ' ' + apiResponse.data.data[0].last_name;
                        setCookie('clientEmail', apiResponse.data.data[0].email, {path: '/'});
                        setCookie('clientName', clientName, {path: '/'});
                        setCookie('clientPlatformID', apiResponse.data.data[0].platform_id, {path: '/'});
                    }

                    console.log("apiResponse getprofiledetails", apiResponse.data);
                } catch (error) {
                    console.error("Error fetching profile details:", error);
                }
                navigate('/dashboard');
            } catch (error) {
                console.error('Error logging in:', error);
                setErrors({ auth: 'Invalid email or password' });
            }
        }  else {
            setErrors(formErrors);
        }
        console.log("Submit button is pressed")
    }*/

    const handlePasswordCloseModal = () => setresetPasswordChallengeModalShow(false);

    const handlePasswordOpenModal = () => setresetPasswordChallengeModalShow(true);

    const toggleRequestNewPasswordVisibility = () => {
        setRequestNewPasswordVisible(!requestNewPasswordVisible);
    };

    const toggleRequestconfNewPasswordVisibility = () => {
        setRequestConfNewPasswordVisible(!requestConfNewPasswordVisible);
    };

    return (
        <>
            <Modal show={resetPasswordChallengeModalShow} onHide={handlePasswordCloseModal} backdrop="static">
                {/*<Modal.Header closeButton>
                    <Modal.Title className=" ">Reset Your Password</Modal.Title>
                </Modal.Header>*/}
                <Modal.Body>
                    <form onSubmit={newpasswordchallenge}>
                        <div className="form-group text-center">
                            <ReportProblemIcon className="prompt-icon"/>
                            <label>Please complete this form to finish signing in.</label>
                        </div>
                        <div className="form-group">
                            <label>Company Name*</label>
                            <input type="name"
                                   value={firstName}
                                   onChange={(e) => handleInputChange("firstName", e.target.value)}
                                   //onChange={event => setFirstName(event.target.value)}
                                   className="form-control on-select"
                                   placeholder="Enter company name" />
                            {errors.firstName && <div className="error-message c-red f-12">{errors.firstName}</div>}
                        </div>
                        <div className="form-group">
                            <label>Password*</label>
                            <div className="input-group">
                                <input
                                    type={requestNewPasswordVisible ? "text" : "password"}
                                    value={password}
                                    onChange={(event) => {
                                        setPassword(event.target.value);
                                        if (errors.password) {
                                            setErrors((prevErrors) => ({ ...prevErrors, password: null }));
                                        }
                                    }}
                                    className="form-control on-select"
                                    placeholder="Enter password"
                                />
                                <div className="input-group-append cursor-pointer">
                                    <span className="input-group-text" onClick={toggleRequestNewPasswordVisibility}>
                                        {requestNewPasswordVisible ? <RemoveRedEyeOutlinedIcon className="pswrd-view-icon"/> : <VisibilityOffOutlinedIcon className="pswrd-view-icon"/>}
                                    </span>
                                </div>
                            </div>
                            {errors.password && <div className="error-message c-red f-12">{errors.password}</div>}
                        </div>
                        <div className="form-group">
                            <label>New Password*</label>
                            <div className="input-group">
                                <input type={requestConfNewPasswordVisible ? "text" : "password"}
                                       value={newPassword}
                                       onChange={(event) => {
                                           setnewPassword(event.target.value);
                                           if (errors.newPassword) {
                                               setErrors((prevErrors) => ({ ...prevErrors, newPassword: null }));
                                           }
                                       }}
                                       className="form-control on-select"
                                       placeholder="Enter new password" />
                                <div className="input-group-append cursor-pointer">
                                   <span className="input-group-text" onClick={toggleRequestconfNewPasswordVisibility}>
                                      {requestConfNewPasswordVisible ? <RemoveRedEyeOutlinedIcon className="pswrd-view-icon"/> : <VisibilityOffOutlinedIcon className="pswrd-view-icon"/>}
                                   </span>
                                </div>
                            </div>
                            {errors.newPassword && <div className="error-message c-red f-12">{errors.newPassword}</div>}
                        </div>
                        <div className="form-group">
                            <button type="submit"
                                    className="col-md-12 btn continue-btn shadow-sm"
                                    disabled={!isFormValid()}>
                                Save
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <div className="row no-gutters">
                <div className="col-md-6 p-0 login-left-container d-flex flex-column justify-content-between">
                    <div className="logo-container d-flex justify-content-center align-items-center">
                        <img src="/images/icatch-logo.png" alt="iCatch Logo" className="login-icatch" />
                    </div>
                    <div className="eval-logo-container d-flex justify-content-start align-items-center">
                        <img src="/images/logo.png" alt="Evaluationz Logo" className="eval-logo" />
                    </div>
                </div>
                <div className="col-md-6 p-0 login-right-container">
                    {/* <div className="d-flex justify-content-end visit-link">
                        <a href="https://www.evaluationz.com" target="_blank">Visit Evaluationz.com</a>
                    </div>*/}
                    <div className="form-container">
                        <form className="login-form" onSubmit={handleLogin}>
                            <div className="head-container">
                                <h2 className="create-account-hd">Login</h2>
                            </div>
                            <div className="form-group">
                                <label>Email*</label>
                                <input type="email"
                                       value={email}
                                       onChange={(e) => handleInputChange("email", e.target.value)}
                                    //onChange={event => setEmail(event.target.value)}
                                       className="form-control on-select"
                                       placeholder="Enter your work email" />
                                {errors.email && <div className="error-message c-red f-12">{errors.email}</div>}
                            </div>
                            <div className="form-group form-group-password">
                                <label>Password*</label>
                                <div className="input-group">
                                    <input type={passwordVisible ? "text" : "password"}
                                           value={password}
                                           onChange={(e) => handleInputChange("password", e.target.value)}
                                        //onChange={event => setPassword(event.target.value)}
                                           className="form-control on-select"
                                           placeholder="Enter the password" />
                                    <div className="input-group-append cursor-pointer">
                                   <span className="input-group-text" onClick={togglePasswordVisibility}>
                                      {passwordVisible ? <RemoveRedEyeOutlinedIcon className="pswrd-view-icon" /> : <VisibilityOffOutlinedIcon className="pswrd-view-icon"/>}
                                   </span>
                                    </div>
                                </div>
                                {errors.password && <div className="error-message c-red f-12">{errors.password}</div>}
                            </div>
                            {errors.auth && <div className="error-message text-center c-red f-12">{errors.auth}</div>}
                            <div className="form-group text-right">
                                <a href="/forgot-password" className="forgot-password-link">Forgot password?</a>
                            </div>
                            <div className="form-group">
                                <button type="submit" value="Login" className="col-md-12 btn continue-btn shadow-sm">Login</button>
                            </div>
                            {/*<div className="loin-divider">
                                <span>OR</span>
                            </div>*/}
                        </form>
                        {/*<div className="social-login d-flex justify-content-center">
                            <button className="social-icon"><img src="/images/icons/google.png" alt="Google" className="social-logo" /></button>
                            <button className="social-icon"><img src="/images/icons/facebook.png" alt="Facebook" className="social-logo" /></button>
                            <button className="social-icon"><img src="/images/icons/linkedin.png" alt="LinkedIn" className="social-logo" /></button>
                        </div>*/}
                        <div className="signup-link">
                            Do not have an account?
                        </div>
                        <div className="text-center"><a href="/signup" className="forgot-password-link">Sign Up</a></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LogIn;
