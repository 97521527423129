import React, {useEffect, useState, useRef} from "react";
import "../style.css";

import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import configData from "../config/index.json";
import axios from "axios";
import swal from "sweetalert";
import MuiAlert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";

function ForgotPassword() {
    const [email, setEmail] = useState('');
    //const [code, setCode] = useState('');
    const [code, setCode] = useState(Array(6).fill(''));
    const [newPassword, setNewPassword] = useState('');
    const [step, setStep] = useState(1);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [open, setOpen] = useState(false);
    let expressURL = configData.express_url;

    const validateEmail = () => {
        let formErrors = {};
        if (!email) {
            formErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            formErrors.email = "Email address is invalid";
        }
        return formErrors;
    };

    const validatePassword = () => {
        let formErrors = {};
        if (!newPassword) {
            formErrors.newPassword = "Password is required";
        } else if (newPassword.length < 8) {
            formErrors.newPassword = "Password must be at least 8 characters";
        } else if (!/(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])/.test(newPassword)) {
            formErrors.newPassword = "Password must contain at least one number, one uppercase letter, and one special character";
        }
        return formErrors;
    };

    /*const handleResetRequest = async (event) => {
        event.preventDefault();
        const formErrors = validateEmail();
        if (Object.keys(formErrors).length === 0) {
            try {
                await Auth.forgotPassword(email);
                setStep(2);
            } catch (error) {
                console.error('Error requesting password reset:', error);
            }
        } else {
            setErrors(formErrors);
        }
    };*/

    const handleResetRequest = async (event) => {
        event.preventDefault();
        const formErrors = validateEmail();

        if (Object.keys(formErrors).length === 0) {
            try {
                const response = await axios.post(expressURL + "icatch/cognito/requestpasswordreset", {
                    email
                });

                if (response.data.success) {
                    setOpen(true);
                    setStep(2);
                } else {
                    console.error('Error requesting password reset:', response.data.message);
                }
            } catch (error) {
                console.error('Error requesting password reset:', error.response?.data?.message);
                console.error('Error requesting password reset:', error.message);
                swal({
                    text: `${error.response?.data?.message || error.message}`,
                    icon: 'error',
                    button: 'OK',
                });
            }
        } else {
            setErrors(formErrors); // Set form validation errors if any
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    /*const handleResetConfirm = async (event) => {
        event.preventDefault();
        const formErrors = validatePassword();
        if (Object.keys(formErrors).length === 0) {
            try {
                await Auth.forgotPasswordSubmit(email, code, newPassword);
                navigate('/login');
            } catch (error) {
                console.error('Error resetting password:', error);
            }
        } else {
            setErrors(formErrors);
        }
    };*/

    const handleResetConfirm = async (event) => {
        event.preventDefault();
        const formErrors = validatePassword();
        const confirmationCode = code.join('');

        if (Object.keys(formErrors).length === 0) {
            try {
                const response = await axios.post(expressURL + "icatch/cognito/confirmpasswordreset", {
                    email,
                    code: confirmationCode,
                    newPassword
                });

                if (response.data.success) {
                    swal({
                        text: 'Password changed successfully!',
                        icon: 'success',
                        button: 'OK',
                    });
                    setTimeout(() => {
                        navigate('/login');
                    }, 2000);
                    //navigate('/login');
                } else {
                    console.error('Error resetting password:', response.data.message);
                }
            } catch (error) {
                console.error('Error resetting password:', error.response?.data?.message || error.message);
                console.error('Error requesting password reset:', error.response?.data?.message);
                console.error('Error requesting password reset:', error.message);
                swal({
                    text: `${error.response?.data?.message || error.message}`,
                    icon: 'error',
                    button: 'OK',
                });
            }
        } else {
            setErrors(formErrors);
        }
    };

    const handleChange = (index, event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);
            if (value && index < 5) {
                document.getElementById(`code-input-${index + 1}`).focus();
            }
        }
    };

    const handleBackspace = (index, event) => {
        if (event.key === 'Backspace' && code[index] === '' && index > 0) {
            document.getElementById(`code-input-${index - 1}`).focus();
            const newCode = [...code];
            newCode[index - 1] = '';
            setCode(newCode);
            event.preventDefault();
        }
    };

    const handlePaste = (event) => {
        const pasteData = event.clipboardData.getData('Text');

        if (/^\d{6}$/.test(pasteData)) {
            const newCode = pasteData.split('');
            setCode(newCode);
            document.getElementById(`code-input-5`).focus();
            event.preventDefault();
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <>
            <div className="row no-gutters">
                <div className="col-md-6 p-0 login-left-container d-flex flex-column justify-content-between">
                    <div className="logo-container d-flex justify-content-center align-items-center">
                        <img src="/images/icatch-logo.png" alt="iCatch Logo" className="login-icatch" />
                    </div>
                    <div className="eval-logo-container d-flex justify-content-start align-items-center">
                        <img src="/images/logo.png" alt="Evaluationz Logo" className="eval-logo" />
                    </div>
                </div>
                <div className="col-md-6 p-0 login-right-container">
                    {/*<div className="d-flex justify-content-end visit-link">
                        <a href="https://www.evaluationz.com" target="_blank">Visit Evaluationz.com</a>
                    </div>*/}

                    <div className="form-container">
                        <div className="head-container">
                            <h2 className="create-account-hd">Reset Password</h2>
                        </div>
                        {step === 1 && (
                            <form onSubmit={handleResetRequest}>
                                <div className="form-group">
                                    <label>Email*</label>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(event) => {
                                            setEmail(event.target.value);
                                            if (errors.email) {
                                                setErrors((prevErrors) => ({ ...prevErrors, email: null }));
                                            }
                                        }}
                                        className="form-control on-select"
                                        placeholder="Enter your email"
                                    />
                                    {errors.email && <div className="error-message c-red f-12">{errors.email}</div>}
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="col-md-12 btn continue-btn shadow-sm">Request Reset</button>
                                </div>
                                <p className="login-link">Back to  <a href="/login" className="login-link forgot-password-link">Login</a></p>
                            </form>
                        )}
                        {step === 2 && (
                            <form onSubmit={handleResetConfirm}>
                                <div className="row mt-5 px-3">
                                    <label className="auth-code-label">Verification Code*</label>
                                    {code.map((digit, index) => (
                                        <div className="col input-loop" key={index}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    id={`code-input-${index}`}
                                                    value={digit}
                                                    onChange={event => handleChange(index, event)}
                                                    onKeyDown={(event) => handleBackspace(index, event)}
                                                    onPaste={handlePaste}
                                                    className="form-control on-select"
                                                    maxLength="1"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    {/*<input
                                        type="text"
                                        value={code}
                                        onChange={(event) => setCode(event.target.value)}
                                        className="form-control on-select"
                                        placeholder="Enter the code sent to your email"
                                    />*/}
                                </div>
                                <div className="form-group">
                                    <label>New Password*</label>
                                    <div className="input-group">
                                        <input
                                            type={passwordVisible ? "text" : "password"}
                                            value={newPassword}
                                            onChange={(event) => {
                                                setNewPassword(event.target.value);
                                                if (errors.newPassword) {
                                                    setErrors((prevErrors) => ({ ...prevErrors, newPassword: null }));
                                                }
                                            }}
                                            className="form-control on-select"
                                            placeholder="Enter your new password"
                                        />
                                        <div className="input-group-append cursor-pointer">
                                            <span className="input-group-text" onClick={togglePasswordVisibility}>
                                                {passwordVisible ? <RemoveRedEyeOutlinedIcon className="pswrd-view-icon"/> : <VisibilityOffOutlinedIcon className="pswrd-view-icon"/>}
                                            </span>
                                        </div>
                                    </div>
                                    {errors.newPassword && <div className="error-message c-red f-12">{errors.newPassword}</div>}
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="col-md-12 btn continue-btn shadow-sm">Reset Password</button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
            <Snackbar open={open}
                      autoHideDuration={3000}
                      onClose={handleClose}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <MuiAlert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Check your email for the verification code.
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default ForgotPassword;
