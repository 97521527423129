import React, {useEffect, useState} from "react";
import axios from 'axios';
import swal from "sweetalert";
import {
    Checkbox, IconButton,
    Paper,
    Slider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DatePicker from "react-datepicker";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FilterListIcon from '@mui/icons-material/FilterList';
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import Pagination from "rc-pagination";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import {useCookies} from "react-cookie";
import configData from "../config/index.json";
import * as XLSX from 'xlsx';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import Footer from "./Footer";
import Skeleton from '@mui/material/Skeleton';

function BillingConsole() {
    const [licenceBalance, setLicenceBalance] = useState([]);
    const [consumptionCount, setConsumptionCount] = useState([]);
    const [cookies, removeCookie] = useCookies(['clientPlatformID']);
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 7)));
    const [endDate, setEndDate] = useState(new Date());
    const [apiData, setApiData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);  // Total number of records
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [filterInput, setFilterInput] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [paymentHistory, setPaymentHistory] = useState([]);

    let expressURL = configData.express_url;

    const toggleDropdown = () => setIsOpen(prev => !prev);

    // Function to format date to "yyyy-MM-dd"
    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const formatPaymentDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    // const fetchApiData = async (startDate, endDate) => {
    //     const loggedPlatformID = cookies.clientPlatformID;
    //     const requestData = {
    //         platform_id: loggedPlatformID,
    //         billing_period_start: formatDate(startDate),
    //         billing_period_end: formatDate(endDate),
    //     };
    //
    //     try {
    //         const response = await axios.post(expressURL + "api/billing/apiusage", requestData);
    //         console.log("API DATA ", response);
    //         setApiData(response.data);
    //     } catch (error) {
    //         console.error('Error fetching API data:', error);
    //         swal({
    //             title: 'Error!',
    //             text: 'Something went wrong.',
    //             icon: 'error',
    //             button: "OK",
    //             closeOnClickOutside: false,
    //         });
    //     }
    // };
    const fetchApiData = async (startDate, endDate, currentPage, pageSize) => {
        setLoading(true);
        const loggedPlatformID = cookies.clientPlatformID;
        const requestData = {
            platform_id: loggedPlatformID,
            billing_period_start: formatDate(startDate),
            billing_period_end: formatDate(endDate),
            limit: pageSize,  // Pass the page size
            offset: (currentPage - 1) * pageSize // Calculate the offset for the API
        };
        try {
            const response = await axios.post(expressURL + "api/billing/apiusage", requestData);
            console.log("API DATA ", response);
            const { data, totalRecords } = response.data;  // Destructure data and total records from the API response
            console.log("Total Records:", totalRecords);
            setLoading(false);
            setApiData(data);
            setTotalRecords(totalRecords);  // Set the total record count for pagination
        } catch (error) {
            setLoading(false);
            console.error('Error fetching API data:', error);
            swal({
                title: 'Error!',
                text: 'Something went wrong.',
                icon: 'error',
                button: "OK",
                closeOnClickOutside: false,
            });
        }
    };

    useEffect(() => {
        if (cookies.clientPlatformID) {
            getLicenceBalance();
            getTotalConsumptionCount();
            fetchApiData(startDate, endDate);
            getPaymentHistory();
        }
    }, [cookies]);

    useEffect(() => {
        setFilteredData(apiData); // Set filtered data to full data set initially
    }, [apiData]);

    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));

    const handleDateChange = (date, isStartDate) => {
        if (isStartDate) {
            setStartDate(date);
        } else {
            setEndDate(date);
        }
        // Refetch API data with new date range
        fetchApiData(isStartDate ? date : startDate, isStartDate ? endDate : date);
    };

    function getTotalConsumptionCount() {
        setLoading(true);
        const loggedPlatformID = {"platform_id": cookies.clientPlatformID};
        console.log("loggedPlatformID", loggedPlatformID);

        axios.post(expressURL + "api/billing/gettotalconsumption", loggedPlatformID)
            .then(response => {
                console.log("response", response);

                if (response.status === 200) {
                    setLoading(false);
                    console.log("TOTAL consumption inside getLicenceBalance ", response);
                    console.log("response data licence balance", response.data)
                    setConsumptionCount(response.data);
                } else {
                    // console.error('Failed to fetch total consumption count');
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error fetching verification history:', error);
                swal({
                    title: 'Error!',
                    text: 'Something went wrong.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            });
    }

    function getLicenceBalance() {
        setLoading(true);
        const loggedPlatformID = {"platform_id": cookies.clientPlatformID};
        console.log("loggedPlatformID", loggedPlatformID);

        axios.post(expressURL + "api/dashboard/licensebalance", loggedPlatformID)
            .then(response => {
                console.log("response", response);
                if (response.status === 200) {
                    setLoading(false);
                    console.log("BILLING CONSOLE inside getLicenceBalance ", response);
                    console.log("response data licence balance", response.data)
                    setLicenceBalance(response.data);
                } else {
                    // console.error('Failed to fetch verification history');
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error fetching verification history:', error);
                swal({
                    title: 'Error!',
                    text: 'Something went wrong.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            });
    }

    function getPaymentHistory() {
        setLoading(true);
        const loggedPlatformID = {"platform_id": cookies.clientPlatformID};
        console.log("loggedPlatformID", loggedPlatformID);

        axios.post(expressURL + "api/billing/getpaymenthistory", loggedPlatformID)
            .then(response => {
                console.log("getPaymentHistory", response.data);

                if (response.status === 200) {
                    setLoading(false);
                    console.log("TOTAL consumption inside getLicenceBalance ", response);
                    console.log("response data licence balance", response.data)
                    setPaymentHistory(response.data.payment_history);
                } else {
                    console.error('Failed to fetch payment history');
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error fetching verification history:', error);
                swal({
                    title: 'Error!',
                    text: 'Something went wrong.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            });
    }

    const PrevNextArrow = (current, type, originalElement) => {
        const handleClick = (e) => {
            e.preventDefault();
        };
        if (type === 'prev') {
            return <button onClick={handleClick}><i className="mdi mdi-chevron-left"/></button>;
        }
        if (type === 'next') {
            return <button onClick={handleClick}><i className="mdi mdi-chevron-right"/></button>;
        }
        return originalElement;
    };

    const handleFilterChange = (e) => {
        const value = e.target.value;
        setFilterInput(value);

        if (value === "") {
            setFilteredData(apiData); // Reset to original data if filter is cleared
        } else {
            const filtered = apiData.filter((row) =>
                row.Client_id.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredData(filtered);
            setCurrentPage(1); // Reset pagination to the first page after filtering
        }
    };

    // const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    const paginatedData = apiData;  // Already paginated data from API, no need to slice

    const exportToExcel = () => {
        const dataForExport = filteredData.map(row => ({
            'Client ID': row.Client_id,
            'Month': row.Month,
            'Number of API Calls': row.No_of_Api_Calls,
            'ID Call': row.ID_Call,
            'Scan Call': row.Scan_Call,
        }));

        const ws = XLSX.utils.json_to_sheet(dataForExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Table Data');

        XLSX.writeFile(wb, `table_data_${new Date().toISOString().split('T')[0]}.xlsx`);
    };

    return (
        <>
            <div className="outer-main">
                <div className="main-container">
                    <div className="col-md-12 top-header d-flex py-3">
                        <div className="col-md-8 billing-top-header">
                            <div className="col-md-12 icon-wrap">
                                <ReceiptOutlinedIcon className="head-icon"/>
                            </div>
                            <div className="col-md-12 head-wrap">
                                Billing Console
                            </div>
                        </div>
                        <div className="billing-top-summary col-md-4 col-lg-4">
                            <div className="row gx-2">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-2 py-0 pl-0 pr-2">
                                    <div className="col-md-12 box d-flex py-3 ">
                                        <div className="col-md-6 total-consumption-title">Total Consumption</div>
                                        {loading ? (
                                            <div className="col-md-6 total-consumption-count">
                                                <Skeleton className="summary-count no-wrap" variant="text" width={100} height={60}/>
                                            </div>
                                        ) : (
                                            <div className="col-md-6 total-consumption-count">{consumptionCount.total_consumption_count}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 py-3">
                        <div className=" col-md-12 py-3 inside-content-wrapper">
                            <div className="heading-dashboard mb-3">
                                <span className="header-dashboard-sub">API Usage Details</span>
                            </div>
                            <p className="f-12">Your monthly usage and billing details</p>
                            <div>
                                <div className="col-md-12 p-0 d-flex align-item-end justify-content-end mb-4 responsive-container">
                                    <BootstrapTooltip title="Export the table">
                                        <div className="line-date-picker-container d-flex shadow-sm mr-3 px-2 f-13" onClick={exportToExcel}>
                                            <span class="material-symbols-outlined mr-2">export_notes</span>
                                            Export
                                        </div>
                                    </BootstrapTooltip>
                                    <BootstrapTooltip title="Filter Client ID">
                                        <div className="line-date-picker-container d-flex shadow-sm mr-3 px-2" onClick={toggleDropdown}>
                                            <FilterListIcon></FilterListIcon>
                                        </div>
                                    </BootstrapTooltip>
                                    {isOpen && (
                                        <div className="dropdown-menu show">
                                            <input type="text"
                                                   className="filter-search-input"
                                                   placeholder="Search Client ID"
                                                   value={filterInput}
                                                   onChange={handleFilterChange}/>
                                        </div>
                                    )}
                                    <div className="line-date-picker-container d-flex shadow-sm">
                                        <div className="f-13 time-period-cvr no-wrap">Billing Period</div>
                                        <div className="line-datepicker-inside d-flex align-items-center p-0">
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date) => handleDateChange(date, true)}
                                                selectsStart
                                                startDate={startDate}
                                                endDate={endDate}
                                                dateFormat="dd MMM yyyy"
                                                className="custom-datepicker"
                                            />
                                            {/*<CalendarMonthOutlinedIcon className="calendar-icon"/>*/}
                                            <span className="usage-datepicker-to">to</span>
                                            <DatePicker
                                                selected={endDate}
                                                onChange={(date) => handleDateChange(date, false)}
                                                selectsEnd
                                                startDate={startDate}
                                                endDate={endDate}
                                                dateFormat="dd MMM yyyy"
                                                className="custom-datepicker"
                                            />
                                            <CalendarMonthOutlinedIcon className="calendar-icon"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="verification-history-table">
                                    <TableContainer component={Paper} className="verification-history-table-in">
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Client ID</TableCell>
                                                    <TableCell>Month</TableCell>
                                                    <TableCell>Number of API Call</TableCell>
                                                    <TableCell>ID Call</TableCell>
                                                    <TableCell>Scan Call</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {loading ? (
                                                    Array.from(new Array(5)).map((_, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell><Skeleton height={30}/></TableCell>
                                                            <TableCell><Skeleton height={30}/></TableCell>
                                                            <TableCell><Skeleton height={30}/></TableCell>
                                                            <TableCell><Skeleton height={30}/></TableCell>
                                                            <TableCell><Skeleton height={30}/></TableCell>
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    paginatedData.length > 0 ? (
                                                        paginatedData.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell>{row.Client_id}</TableCell>
                                                                <TableCell>{row.Month}</TableCell>
                                                                <TableCell>{row.No_of_Api_Calls}</TableCell>
                                                                <TableCell>{row.ID_Call}</TableCell>
                                                                <TableCell>{row.Scan_Call}</TableCell>
                                                            </TableRow>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={5} className="text-center f-18 font-bold p-5">
                                                                <p className="mb-0"><img src="/images/no-data.png" alt="No Data Found" style={{ width: '80px', height: '80px' }} /></p>
                                                                <p className="mb-0">No record available!</p>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>

                                <div className="col-md-12 d-flex justify-content-end p-0 mb-4">
                                    <div className="pagination-container shadow-sm">
                                        <Pagination className="pagination-data border-top f-12 pt-3 pb-2"
                                                    current={currentPage}
                                                    pageSize={pageSize}
                                            // total={apiData.length}
                                                    total={totalRecords}
                                                    onChange={(page) => {
                                                        setCurrentPage(page);
                                                        fetchApiData(startDate, endDate, page, pageSize);  // Fetch the new page data
                                                    }}
                                            // onChange={(page) => setCurrentPage(page)}
                                                    showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${totalRecords}`}
                                                    showLessItems
                                                    showSizeChanger={false}
                                                    itemRender={PrevNextArrow}
                                        />
                                    </div>
                                </div>

                                {/*Plan Details*/}
                                <div className="plan-details-container mb-4">
                                    <div className="heading-dashboard mb-3">
                                        <span className="header-dashboard-sub">Plan Details</span>
                                    </div>
                                    <div className="row plan-details-inner">
                                        {/*<div className="col-md-3">
                                            <div className="plan-sub-heading">Current Plan:</div>
                                            <div className="plan-details">Plan Name</div>
                                        </div>*/}
                                        <div className="col-md-4">
                                            <div className="plan-sub-heading">Validity:</div>
                                            {loading ? (
                                                <div className="plan-details">
                                                    <Skeleton variant="text" width={100} height={40}/>
                                                </div>
                                            ) : (
                                                <div className="plan-details">{licenceBalance.valid_till}</div>
                                            )}

                                        </div>
                                        <div className="col-md-6">
                                            <div className="plan-sub-heading">Available Balance:</div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    {loading ? (
                                                        <div className="plan-sub-heading d-flex justify-content-around align-items-center">ID Scan Left
                                                            <span><Skeleton className="plan-details pl-2" variant="text" width={100} height={40}/></span>
                                                        </div>
                                                    ) : (
                                                    <div className="plan-sub-heading">ID Scan Left
                                                        <span className="plan-details pl-2">{licenceBalance.id_scan_balance}</span>
                                                    </div>
                                                    )}
                                                </div>
                                                <div className="col-md-6">
                                                    {loading ? (
                                                        <div className="plan-sub-heading d-flex justify-content-around align-items-center">Verification Scan Left
                                                            <span><Skeleton className="plan-details pl-2" variant="text" width={80} height={40}/></span>
                                                        </div>
                                                    ) : (
                                                    <div className="plan-sub-heading">Verification Scan Left
                                                        <span className="plan-details pl-2">{licenceBalance.scan_balance}</span>
                                                    </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*Payment/Perchase History*/}
                                <div className="row">
                                    <div className="col-md-6 payment-history-container">
                                        <div className="verification-history-table">
                                            <TableContainer component={Paper} className="verification-history-table-in">
                                                <div className="heading-dashboard payment-purchase-heading">
                                                    <span className="header-dashboard-sub">Payment History</span>
                                                </div>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Transaction ID</TableCell>
                                                            <TableCell>Date</TableCell>
                                                            <TableCell>Amount <CurrencyRupeeIcon className="f-15"></CurrencyRupeeIcon></TableCell>
                                                            <TableCell>Mode</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {loading ? (
                                                            Array.from(new Array(5)).map((_, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell><Skeleton height={30}/></TableCell>
                                                                    <TableCell><Skeleton height={30}/></TableCell>
                                                                    <TableCell><Skeleton height={30}/></TableCell>
                                                                    <TableCell><Skeleton height={30}/></TableCell>
                                                                </TableRow>
                                                            ))
                                                        ) : (
                                                            Array.isArray(paymentHistory) && paymentHistory.length > 0 ? (
                                                                paymentHistory.map((payment, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell>{payment.transaction_id}</TableCell>
                                                                        <TableCell>{formatPaymentDate(payment.transaction_date)}</TableCell>
                                                                        <TableCell>{payment.amount}</TableCell>
                                                                        <TableCell>{payment.mode}</TableCell>
                                                                    </TableRow>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={4} className="text-center f-18 font-bold p-5">
                                                                        <p className="mb-0"><img src="/images/no-data.png" alt="No Data Found" style={{ width: '80px', height: '80px' }} /></p>
                                                                        <p className="mb-0">No record available!</p>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="verification-history-table">
                                            <TableContainer component={Paper} className="verification-history-table-in">
                                                <div className="heading-dashboard payment-purchase-heading">
                                                    <span className="header-dashboard-sub">Purchase History</span>
                                                </div>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>ID Count</TableCell>
                                                            <TableCell>Scan Count</TableCell>
                                                            <TableCell>Valid from</TableCell>
                                                            <TableCell>Valid Till</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {loading ? (
                                                            Array.from(new Array(5)).map((_, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell><Skeleton height={30}/></TableCell>
                                                                    <TableCell><Skeleton height={30}/></TableCell>
                                                                    <TableCell><Skeleton height={30}/></TableCell>
                                                                    <TableCell><Skeleton height={30}/></TableCell>
                                                                </TableRow>
                                                            ))
                                                        ) : (
                                                            Array.isArray(paymentHistory) && paymentHistory.length > 0 ? (
                                                                paymentHistory.map((payment, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell>{payment.ID_Count}</TableCell>
                                                                        <TableCell>{payment.Scan_Count}</TableCell>
                                                                        <TableCell>{formatPaymentDate(payment.Valid_From)}</TableCell>
                                                                        <TableCell>{formatPaymentDate(payment.Valid_Till)}</TableCell>
                                                                    </TableRow>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={4} className="text-center f-18 font-bold p-5">
                                                                        <p className="mb-0"><img src="/images/no-data.png" alt="No Data Found" style={{ width: '80px', height: '80px' }} /></p>
                                                                        <p className="mb-0">No record available!</p>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>

        </>
    );
}

export default BillingConsole;
