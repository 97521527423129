import React, {useEffect, useState, useRef} from "react";
import "../style.css";
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import swal from "sweetalert";
import configData from "../config/index.json";
import axios from "axios";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import MuiAlert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";

const countryStateData = {
    "India": ["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal"],
    /*"United States": ["California", "Texas", "New York", "Florida", "Illinois"],
    "Canada": ["Ontario", "Quebec", "British Columbia", "Alberta", "Manitoba"],*/
};

function SignUp(props) {
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [reenterPassword, setReenterPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [fullAddress, setFullAddress] = useState('');
    const [country, setCountry] = useState("India");
    const [state, setState] = useState("");
    const [states, setStates] = useState(countryStateData["India"]);
    const [errors, setErrors] = useState({});
    const [code, setCode] = useState(Array(6).fill(''));
    const [step, setStep] = useState(1);
    const [open, setOpen] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('http://localhost:3000/validate');
    let expressURL = configData.express_url;

    const navigate = useNavigate();
    const personalEmailDomains = ["gmail.com", "yahoo.com", "outlook.com", "hotmail.com", "aol.com", "icloud.com"];
    const addressRef = useRef(null);


    useEffect(() => {
        const loadGoogleMapsScript = () => {
            const script = document.createElement("script");
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC8nhmzEz7qEVz-zxi__3P4JYpqX3PjV1w&libraries=places`;
            script.async = true;
            script.defer = true;
            script.onload = () => {
                const autocomplete = new window.google.maps.places.Autocomplete(addressRef.current, {
                    types: ['address'],
                });

                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    if (place.formatted_address) {
                        setFullAddress(place.formatted_address); // Update state with the full address
                    }
                });
            };
            document.body.appendChild(script);
        };

        loadGoogleMapsScript();
    }, []);

    const validate = () => {
        let formErrors = {};
        if (!firstName) formErrors.firstName = "Company name is required";
        if (!email) formErrors.email = "Email is required";
        if (!password) formErrors.password = "Password is required";
        if (password !== reenterPassword) formErrors.reenterPassword = "Passwords do not match";
        return formErrors;
    };

    const validateStep1 = () => {
        let formErrors = {};
        if (!firstName) {
            formErrors.firstName = "Company name is required";
        }
        if (!fullAddress) {
            formErrors.fullAddress = "Full address is required";
        }
        /*if (!lastName) {
            formErrors.lastName = "Last name is required";
        }*/
        if (!email) {
            formErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            formErrors.email = "Email address is invalid";
        } else {
            // Extract the domain from the email
            console.log("Email value before split:", email);
            console.log(typeof email, email);
            const emailDomain = email.split('@')[1];
            // Check if the domain is in the list of personal email domains
            if (personalEmailDomains.includes(emailDomain)) {
                formErrors.email = "Please use your business email address";
            }
        }
        if (!password) {
            formErrors.password = "Password is required";
        } else if (password.length < 8) {
            formErrors.password = "Password must be at least 8 characters";
        } else if (!/(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])/.test(password)) {
            formErrors.password = "Password must contain at least one number, one uppercase letter, and one special character";
        }

        const passwordErrors = validatePasswords();
        formErrors = { ...formErrors, ...passwordErrors };

        /*if (!country) {
            formErrors.country = "Country is required";
        }
        if (!state) {
            formErrors.state = "State is required";
        }*/
        return formErrors;
    };

    const validatePasswords = () => {
        let formErrors = {};
        if (!reenterPassword) {
            formErrors.reenterPassword = "Reentering the password is required";
        } else if (password !== reenterPassword) {
            formErrors.reenterPassword = "Passwords do not match";
        }
        return formErrors;
    };

    const handleInputChange = (field, value) => {
        // Update the field value
        if (field === "firstName") setFirstName(value);
        if (field === "email") setEmail(value);
        if (field === "password") setPassword(value);
        if (field === "reenterPassword") setReenterPassword(value);
        if (field === "fullAddress") setFullAddress(value);

        // Clear the error for the current field if there's an error
        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: "" // Clear the error for the specific field
        }));
    };

    // const handleRegistration = async (event) => {
    //     event.preventDefault();
    //     const formErrors = validateStep1();
    //     if (Object.keys(formErrors).length === 0) {
    //         try {
    //             // Sign up with Amplify Cognito
    //             await Auth.signUp({
    //                 username: email,
    //                 password,
    //                 attributes: {
    //                     email,
    //                     given_name: firstName,
    //                     family_name: firstName,
    //                     'custom:redirectUrl': redirectUrl,
    //                 }
    //             });
    //
    //             await axios.post(expressURL + "icatch/profile/addnewprofile", {
    //                 email,
    //                 full_name: firstName,
    //                 full_address: fullAddress
    //             });
    //             setStep(2); // Move to validation step
    //         } catch (error) {
    //             swal({
    //                 text: 'Error during registration.',
    //                 icon: 'error',
    //                 button: 'OK',
    //                 closeOnClickOutside: false,
    //             });
    //         }
    //     // } else {
    //     //     setErrors(formErrors);
    //     //     swal({
    //     //         title: 'Error!',
    //     //         text: formErrors || 'Something went wrong.',
    //     //         icon: 'error',
    //     //         button: "OK",
    //     //     });
    //     // }
    //     } else {
    //         setErrors(formErrors);
    //         let errorMessage = Object.values(formErrors).join('\n');
    //         /*swal({
    //             title: 'Error!',
    //             text: errorMessage || 'Something went wrong.',
    //             icon: 'error',
    //             button: "OK",
    //         });*/
    //     }
    //
    // };
    const handleRegistration = async (event) => {
        event.preventDefault();
        const formErrors = validateStep1();

        if (Object.keys(formErrors).length === 0) {
            try {
                // Call the backend API to register the user with Cognito
                const response = await axios.post(expressURL + "icatch/profile/addnewprofile", {
                    email,
                    full_name: firstName,
                    full_address: fullAddress,
                    password
                });

                if (response.status === 200) {
                    setStep(2); // Move to the next step (e.g., confirmation code input)
                    setOpen(true);
                } else {
                    throw new Error('Registration failed. Please try again.');
                }
            } catch (error) {
                swal({
                    text: error.response?.data?.message || error.message,
                    icon: 'info',
                    button: 'OK',
                    closeOnClickOutside: false,
                });
            }
        } else {
            setErrors(formErrors);
            let errorMessage = Object.values(formErrors).join('\n');
            swal({
                title: 'Error!',
                text: errorMessage || 'Something went wrong.',
                icon: 'error',
                button: "OK",
            });
        }
    };


    // const handleRegistrationConfirmation = async (event) => {
    //     event.preventDefault();
    //     try {
    //         await Auth.confirmSignUp(email, code);
    //         swal({
    //             text: "Your account has been successfully confirmed.",
    //             icon: "success",
    //             button: "OK",
    //             closeOnClickOutside: false,
    //         }).then(() => {
    //             navigate('/login');
    //         });
    //     } catch (error) {
    //         swal({
    //             title: 'Error!',
    //             text: 'Enter a valid authentication code and try again.',
    //             icon: 'error',
    //             button: "OK",
    //             closeOnClickOutside: false,
    //         });
    //     }
    // };
    const handleRegistrationConfirmation = async (event) => {
        event.preventDefault();
        const confirmationCode = code.join('');
        try {
            // Call the backend API to confirm user registration with Cognito
            const response = await axios.post(expressURL + "icatch/confirm/confirmregistration", {
                email,
                code: confirmationCode
            });

            if (response.status === 200) {
                swal({
                    text: "Your account has been successfully confirmed.",
                    icon: "success",
                    button: "OK",
                    closeOnClickOutside: false,
                }).then(() => {
                    navigate('/login');
                });
            }
        } catch (error) {
            swal({
                text: error.response?.data?.error,
                icon: 'error',
                button: 'OK',
            });
            setCode(Array(6).fill(''));
        }
    };

    const handleChange = (index, event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);
            if (value && index < 5) {
                document.getElementById(`code-input-${index + 1}`).focus();
            }
        }
    };

    const handleBackspace = (index, event) => {
        if (event.key === 'Backspace' && code[index] === '' && index > 0) {
            document.getElementById(`code-input-${index - 1}`).focus();
            const newCode = [...code];
            newCode[index - 1] = '';
            setCode(newCode);
            event.preventDefault();
        }
    };

    const handlePaste = (event) => {
        const pasteData = event.clipboardData.getData('Text');

        if (/^\d{6}$/.test(pasteData)) {
            const newCode = pasteData.split('');
            setCode(newCode);
            document.getElementById(`code-input-5`).focus();
            event.preventDefault();
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    /*const handleRegistration = function(event) {
        event.preventDefault();
        const formErrors = validate();
        if (Object.keys(formErrors).length === 0) {
            Auth.signUp({
                username: email,
                password: password,
                attributes: {
                    email: email,
                    given_name: firstName,
                    family_name: firstName,
                    /!*'custom:country': country,
                    'custom:city': state,*!/
                }
            })
                .then(response => {
                    // API call to store the user details
                    axios.post(expressURL + "icatch/profile/addnewprofile", {
                        email: email,
                        full_name: firstName,
                        full_address: fullAddress,
                        last_name: firstName,
                        // last_name: lastName,
                        // middle_name: '',
                        // city: state,
                        // country: country,
                    })
                        .then(apiResponse => {
                            console.log("apiResponse:", apiResponse);
                            props.onRegister(); // Call the onRegister prop function
                            navigate('/validate'); // Redirect to the validation page
                        })
                        .catch(error => {
                            console.error('Failed to store user details:', error);
                            swal({
                                title: 'Error!',
                                text: 'Failed to store user details. Please try again',
                                icon: 'error',
                                button: "OK",
                            });
                        });
                })
                .catch(error => {
                    console.error('Error during registration or API call:', error);
                    swal({
                        text: 'Error during registration or User already exists.',
                        icon: 'info',
                        button: 'Got it',
                    });
                });
        } else {
            setErrors(formErrors);
            console.log("else error", formErrors)
            swal({
                title: 'Error!',
                text: 'Something went wrong.',
                icon: 'error',
                button: "OK",
            });
        }
        console.log("Submit button is pressed");
    };*/

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleNewPasswordVisibility = () => {
        setNewPasswordVisible(!newPasswordVisible);
    };

    function googleSignUp(){
        Auth.federatedSignIn({ provider: 'Google' });
    }

    // Handle country change
    const handleCountryChange = (event) => {
        const selectedCountry = event.target.value;
        setCountry(selectedCountry);
        setStates(countryStateData[selectedCountry] || []);
        setState('');
    };

    return (
        <>
            <div className="row no-gutters">
                <div className="col-md-6 p-0 login-left-container d-flex flex-column justify-content-between">
                    <div className="logo-container d-flex justify-content-center align-items-center">
                        <img src="/images/icatch-logo.png" alt="iCatch Logo" className="login-icatch" />
                    </div>
                    <div className="eval-logo-container d-flex justify-content-start align-items-center">
                        <img src="/images/logo.png" alt="Evaluationz Logo" className="eval-logo" />
                    </div>
                </div>
                <div className="col-md-6 p-0 login-right-container">
                    {/*<div className="d-flex justify-content-end visit-link">
                        <a href="https://www.evaluationz.com" target="_blank">Visit Evaluationz.com</a>
                    </div>*/}
                    <div className="form-container-signup">
                        {step === 1 && (
                        <form className="login-form" onSubmit={handleRegistration}>
                            <div className="head-container">
                                <h2 className="create-account-hd">Create an Account</h2>
                                <p className="login-link">Already have an account?  <a href="/login" className="login-link">Login</a></p>
                            </div>
                            {/*<p className="accountinfo-hd">Account Information</p>*/}
                            <div className="row mt-4">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Business Email*</label>
                                        <input type="email"
                                               value={email}
                                               onChange={(e) => handleInputChange("email", e.target.value)}
                                               //onChange={event => setEmail(event.target.value)}
                                               className="form-control on-select"
                                               placeholder="Enter your work email" />
                                        {errors.email && <div className="error-message c-red f-12">{errors.email}</div>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Company Name*</label>
                                        <input type="name"
                                               value={firstName}
                                               onChange={(e) => handleInputChange("firstName", e.target.value)}
                                               //onChange={event => setFirstName(event.target.value)}
                                               className="form-control on-select"
                                               placeholder="Enter company name" />
                                        {errors.firstName && <div className="error-message c-red f-12">{errors.firstName}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Password*</label>
                                        <div className="input-group">
                                            <input type={passwordVisible ? "text" : "password"}
                                                   value={password}
                                                   onChange={(e) => handleInputChange("password", e.target.value)}
                                                   //onChange={event => setPassword(event.target.value)}
                                                   className="form-control on-select"
                                                   placeholder="Enter a password" />
                                            <div className="input-group-append cursor-pointer">
                                            <span className="input-group-text" onClick={togglePasswordVisibility}>
                                                {passwordVisible ? <RemoveRedEyeOutlinedIcon className="pswrd-view-icon"/> : <VisibilityOffOutlinedIcon className="pswrd-view-icon"/>}
                                            </span>
                                            </div>
                                        </div>
                                        {errors.password && <div className="error-message c-red f-12">{errors.password}</div>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Reenter Password*</label>
                                        <div className="input-group">
                                            <input type={newPasswordVisible ? "text" : "password"}
                                                   value={reenterPassword}
                                                   onChange={(e) => handleInputChange("reenterPassword", e.target.value)}
                                                   //onChange={event => setReenterPassword(event.target.value)}
                                                   className="form-control on-select"
                                                   placeholder="Reenter password" />
                                            <div className="input-group-append cursor-pointer">
                                            <span className="input-group-text" onClick={toggleNewPasswordVisibility}>
                                                {newPasswordVisible ? <RemoveRedEyeOutlinedIcon className="pswrd-view-icon"/> : <VisibilityOffOutlinedIcon className="pswrd-view-icon"/>}
                                            </span>
                                            </div>
                                        </div>
                                        {errors.reenterPassword && <div className="error-message c-red f-12">{errors.reenterPassword}</div>}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Address</label>
                                        <input
                                            value={fullAddress}
                                            ref={addressRef}
                                            onChange={(e) => handleInputChange("fullAddress", e.target.value)}
                                            //onChange={event => setFullAddress(event.target.value)}
                                            className="form-control on-select"
                                            rows="4"
                                            placeholder="Enter your address"></input>
                                        {errors.fullAddress && <div className="error-message c-red f-12">{errors.fullAddress}</div>}
                                    </div>
                                </div>
                            </div>

                            {/*<div className="row mb-4">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Country or region</label>
                                        <select className="form-select form-select-sm"
                                                value={country}
                                                onChange={handleCountryChange}
                                                aria-label="Country select">
                                            <option disabled value="">Select country</option>
                                            {Object.keys(countryStateData).map((country, index) => (
                                                <option key={index} value={country}>{country}</option>
                                            ))}
                                        </select>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            value={country}
                                            readOnly
                                        />
                                        {errors.country && <div className="error-message c-red f-12">{errors.country}</div>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>State or Province</label>
                                        <select className="form-select form-select-sm"
                                                value={state}
                                                onChange={event => setState(event.target.value)}
                                                aria-label="State select"
                                                // disabled={!country} // Disable state dropdown if no country is selected
                                            >
                                            <option disabled value="">Select state</option>
                                            {states.map((state, index) => (
                                                <option key={index} value={state}>{state}</option>
                                            ))}
                                        </select>
                                        {errors.state && <div className="error-message c-red f-12">{errors.state}</div>}
                                    </div>
                                </div>
                            </div>*/}

                            <div className="form-group mb-4 text-center mt-3">
                                <button type="submit" value="Register" className="col-md-8 btn continue-btn shadow-sm">Create Account</button>
                            </div>
                            {/*<div className="loin-divider">
                                <span>OR</span>
                            </div>*/}
                        </form>
                        /*<div className="social-login d-flex justify-content-center">
                            <button className="social-icon" onClick={() => Auth.federatedSignIn({ provider: 'Google'})}><img src="/images/icons/google.png" alt="Google" className="social-logo" /></button>
                            <button className="social-icon" onClick={() => Auth.federatedSignIn({ provider: 'Facebook'})}><img src="/images/icons/facebook.png" alt="Facebook" className="social-logo" /></button>
                            <button className="social-icon"><img src="/images/icons/linkedin.png" alt="LinkedIn" className="social-logo" /></button>
                        </div>*/
                        )}
                        {step === 2 && (
                            <form className="login-form" onSubmit={handleRegistrationConfirmation}>
                                <div className="head-container">
                                    <h2 className="create-account-hd">Verify your Account</h2>
                                </div>
                                <div className="row mt-5 px-3">
                                    {/*<div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email*</label>
                                            <input type="email"
                                                   readOnly
                                                   value={email}
                                                   onChange={event => setEmail(event.target.value)}
                                                   className="form-control on-select"
                                                   placeholder="Enter registered email" />
                                        </div>
                                    </div>*/}
                                    {/*<div className="col-md-12 d-flex p-0">*/}
                                       {/* <div className="form-group">
                                            <label>Authentication Code*</label>
                                            <input type="text"
                                                   value={code}
                                                   onChange={event => setCode(event.target.value)}
                                                   className="form-control on-select"
                                                   placeholder="Enter a authentication code" />
                                        </div>*/}
                                        <label className="auth-code-label">Authentication Code*</label>
                                        {code.map((digit, index) => (
                                            <div className="col input-loop" key={index}>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        id={`code-input-${index}`}
                                                        value={digit}
                                                        onChange={event => handleChange(index, event)}
                                                        onKeyDown={(event) => handleBackspace(index, event)}
                                                        onPaste={handlePaste}
                                                        className="form-control on-select"
                                                        maxLength="1"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    {/*</div>*/}
                                </div>

                                <div className="form-group mb-5 text-center">
                                    <button type="submit" value="Register" className="col-md-8 btn continue-btn shadow-sm on-select">Validate</button>
                                </div>
                                {/*<div className="text-center"><a href="/login" className="forgot-password-link">Return to Login</a></div>*/}
                            </form>
                        )}
                    </div>
                </div>
            </div>
            <Snackbar open={open}
                      autoHideDuration={3000}
                      onClose={handleClose}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <MuiAlert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Check your email for the verification code.
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default SignUp;
