import React from "react";
import Container from "@material-ui/core/Container";

function Loader({ isLoading }) {
    if (!isLoading) return null;

    return (
        <>
            <Container fluid className="">
                <div class="invictus-splash-screen" align="center">
                    <div class="text-center">
                        <div className="spinner-block">
                            <div className="spinner">
                                <div className="spinner-item"> </div>
                                <div className="spinner-item"> </div>
                                <div className="spinner-item"> </div>
                                <div className="spinner-item"> </div>
                                <div className="spinner-item"> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default Loader;
