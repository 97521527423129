import React, {useEffect, useState} from "react";
import axios from 'axios';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import swal from "sweetalert";
import configData from "../config/index.json";
import {Button, Modal} from "react-bootstrap";
import {useCookies} from "react-cookie";
import Footer from "./Footer";
import Skeleton from '@mui/material/Skeleton';

function Notification() {
    const [notifications, setNotifications] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [modalIcon, setModalIcon] = useState(null);
    const [modalTitle, setModalTitle] = useState('');
    // const [unreadCount, setUnreadCount] = useState(0);
    const [cookies, setCookie] = useCookies(['clientPlatformID', 'readCount']);
    const [loading, setLoading] = useState(true);

    let expressURL = configData.express_url;

    useEffect(() => {
        setLoading(true);
        const loggedPlatformID = {"platform_id": cookies.clientPlatformID};
        axios.post(expressURL + "api/notification/getnotifications", loggedPlatformID)
            .then(response => {
                setLoading(false);
                console.log("NOTIFICATION response", response.data)
                //const sortedNotifications = response.data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
                setNotifications(response.data);

                /*const unreadNotifications = response.data.filter(notification => notification.notification_read === "No");
                setUnreadCount(unreadNotifications.length);*/
            })
            .catch(error => {
                setLoading(false);
                console.error("Error fetching notifications:", error);
                swal({
                    title: 'Error!',
                    text: 'Something went wrong.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            });
    }, []);

    const handleCardClick = (notification) => {
        console.log("notification", notification);
        if (notification.notification_read === 'No') {
            markAsRead(notification.notification_id);
        }
        setSelectedNotification(notification);
        setModalIcon(getIcon(notification.notification_type));
        setModalTitle(getTitle(notification.notification_type));
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedNotification(null);
    };

    const markAsRead = (notificationId) => {
        const platform_id = cookies.clientPlatformID;
        axios.post(expressURL + "api/notification/markasread", {
            notification_id: notificationId,
            platform_id
        })
            .then(response => {
                console.log("mark as read", response.data);

                // Update the unread count from the response
                const updatedNotifications = notifications.map(notification =>
                    notification.notification_id === notificationId
                        ? { ...notification, notification_read: "Yes" } // Mark as read
                        : notification
                );
                console.log("response.data.unread_count", response.data.unread_count);
                setNotifications(updatedNotifications);
                //setUnreadCount(response.data.unread_count);
                setCookie('readCount', !cookies.readCount, { path: '/' });

                // Optionally, if you are storing unread count separately
                //setUnreadCount(response.data.unread_count);
                //setUnreadCount(prevCount => Math.max(prevCount - 1, 0));
            })
            .catch(error => {
                console.error("Error marking notification as read:", error);
                if (error.response && error.response.data.error === "Notification already marked as read") {
                    return;
                }
                swal({
                    title: 'Error!',
                    text: 'Failed to mark notification as read.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            });
    };

    const getIcon = (type) => {
        switch (type) {
            case 'THRESHOLD':
                return <InfoOutlinedIcon className="info-icon notification-modal-icon" />;
            case 'SUCCESS':
                return <DoneOutlinedIcon className="success-icon notification-modal-icon" />;
            case 'EXPIRY':
                return <WarningAmberOutlinedIcon className="warning-icon notification-modal-icon" />;
            default:
                return <InfoOutlinedIcon className="info-icon notification-modal-icon" />;
        }
    };

    const getTitle = (type) => {
        switch (type) {
            case 'THRESHOLD':
                return <span>Insufficient available balance</span>;
            case 'SUCCESS':
                return <span></span>;
            case 'EXPIRY':
                return <span>License Expire</span>;
            default:
                return <span></span>;
        }
    };

    const groupNotificationsByDate = (notifications) => {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        const todayDateString = today.toISOString().split('T')[0];
        const yesterdayDateString = yesterday.toISOString().split('T')[0];

        const groupedNotifications = {
            "Today": [],
            "Yesterday": [],
            "Other": {}
        };

        notifications.forEach(notification => {
            const date = new Date(notification.updated_at).toISOString().split('T')[0];

            if (date === todayDateString) {
                groupedNotifications["Today"].push(notification);
            } else if (date === yesterdayDateString) {
                groupedNotifications["Yesterday"].push(notification);
            } else {
                if (!groupedNotifications["Other"][date]) {
                    groupedNotifications["Other"][date] = [];
                }
                groupedNotifications["Other"][date].push(notification);
            }
        });

        return groupedNotifications;
    };

    const renderNotification = (notification) => {
        let typeClass = '';
        let icon = null;
        let heading = '';
        let subheading = '';

        const date = new Date(notification.updated_at).toISOString().split('T')[0];

        switch (notification.notification_type) {
            case 'THRESHOLD':
                typeClass = 'info';
                icon = <InfoOutlinedIcon className="info-icon" />;
                heading = 'INFO';
                subheading = 'Insufficient available balance';
                break;
            case 'SUCCESS':
                typeClass = 'success';
                icon = <DoneOutlinedIcon className="success-icon" />;
                heading = 'SUCCESS';
                break;
            case 'EXPIRY':
                typeClass = 'warning';
                icon = <WarningAmberOutlinedIcon className="warning-icon" />;
                heading = 'WARNING';
                subheading = 'License Expire';
                break;
            default:
                typeClass = 'info';
                icon = <InfoOutlinedIcon className="info-icon" />;
                heading = 'INFO';
        }

        const truncatedMessage = notification.notification_message.substring(0, 50) + '...';

        return (
            <div key={notification.notification_id}
                 className={`cursor-pointer col-md-12 py-3 notification-card ${notification.notification_read === 'Yes' ? 'read' : 'unread'} ${typeClass} mb-3 shadow-sm`}
                 onClick={() => handleCardClick(notification)}
            >
                <div className={`${typeClass}-hd`}>
                    {heading}
                </div>
                <div className={`success-sub-hd`}>
                    {icon} {subheading}
                </div>
                {/*<div className={`success-description pt-1`}>{notification.notification_message}</div>*/}
                <div className={`success-description pt-1`}>{truncatedMessage}</div>
                <div className="date">{date}</div>
            </div>
        );
    };

    const renderGroupedNotifications = () => {
        if (notifications.length === 0) {
            return (
                <div className="no-notifications text-center">
                    <p className="mb-0">
                        <img src="/images/no-data.png" alt="No Notifications Found" style={{ width: '80px', height: '80px' }} />
                    </p>
                    <p className="mb-0 f-12">No notifications available!</p>
                </div>
            );
        }

        const groupedNotifications = groupNotificationsByDate(notifications);
        console.log("Grouped Notifications:", groupedNotifications);

        return (
            <>
                {groupedNotifications["Today"].length > 0 && (
                    <>
                        <div className="col-md-12">
                            <p className="day">Today</p>
                        </div>
                        {groupedNotifications["Today"].map(notification => renderNotification(notification))}
                    </>
                )}

                {groupedNotifications["Yesterday"].length > 0 && (
                    <>
                        <div className="col-md-12">
                            <p className="day">Yesterday</p>
                        </div>
                        {groupedNotifications["Yesterday"].map(notification => renderNotification(notification))}
                    </>
                )}

                {Object.keys(groupedNotifications["Other"]).map(date => (
                    <div key={date}>
                        <div className="col-md-12">
                            <p className="day">{date}</p>
                        </div>
                        {groupedNotifications["Other"][date].map(notification => renderNotification(notification))}
                    </div>
                ))}
            </>
        );
    };

    return (
        <>
            {/* Modal to display full notification message */}
            <Modal
                show={modalOpen}
                onHide={handleCloseModal}
            >
                <Modal.Body>
                    {selectedNotification && (
                        <div>
                            <Modal.Title className="text-center">
                                {modalIcon}
                            </Modal.Title>
                            <Modal.Title className="f-18 text-center">{modalTitle}</Modal.Title>
                            <div className="col-lg-12 d-flex align-items-center justify-content-center mt-2">
                                <p class="notification-modal-description">{selectedNotification.notification_message}</p>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            <div className="outer-main">
                <div className="main-container">
                    <div className="col-md-12 top-header py-3">
                        <div className="col-md-12 icon-wrap">
                            <span className="head-icon material-symbols-outlined">notifications</span>
                        </div>
                        <div className="col-md-12 head-wrap">
                            Notifications
                        </div>
                    </div>
                    <div className="col-md-12 py-3">
                        <div className=" col-md-12 py-3 inside-content-wrapper">
                            <div className="inner-gray-content-wrap p-3">
                                {/*{notifications.map(notification => renderNotification(notification))}*/}
                                {renderGroupedNotifications()}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
}

export default Notification;
