import React, {useEffect, useState} from "react";
import axios from 'axios';
import swal from "sweetalert";
import configData from "../config/index.json";
import { TextField } from "@mui/material";
import {useCookies} from "react-cookie";

function Support() {
    const [cookies] = useCookies(['clientEmail', 'clientName']);
    const [formData, setFormData] = useState({
        full_name: cookies.clientName,
        email: cookies.clientEmail,
        message: ''
    });

    let expressURL = configData.express_url;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post(expressURL + "api/support/sendemail", formData)
            .then(response => {
                if (response.status === 200) {
                    console.log('Response mail send:', response.data);
                    swal({
                        title: 'Success!',
                        text: 'Email sent successfully!',
                        icon: 'success',
                        button: "OK",
                        closeOnClickOutside: false,
                    }).then((willReload) => {
                        if (willReload) {
                            window.location.reload();
                        }
                    });
                } else {
                    console.error('Mail not sent');
                }
            }).catch(error => {
            console.error('Error:', error);
            swal({
                title: 'Error!',
                text: 'Something went wrong. Please try again later.',
                icon: 'error',
                button: "OK",
                closeOnClickOutside: false,
            });
        });
    };


    return (
        <>
            <div className="outer-main">
                <div className="main-container">
                    <div className="col-md-12 top-header py-3">
                        <div className="col-md-12 icon-wrap">
                            <span class="material-symbols-outlined head-icon">support_agent</span>
                        </div>
                        <div className="col-md-12 head-wrap">
                            Support
                        </div>
                    </div>
                    <div className="col-md-12 py-3">
                        <div className=" col-md-12 py-3 inside-content-wrapper">
                            <div className="p-3 profile-settings">
                                <p className="f-12">Had any questions? Get in touch with us.</p>
                                <form className="login-form profile-settings support-form mt-5" onSubmit={handleSubmit}>
                                    <div className="col-md-12 mb-3">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <TextField
                                                    fullWidth
                                                    placeholder="Message*"
                                                    variant="standard"
                                                    className="mb-3 profile-input"
                                                    name="message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <div className="col-md-6">
                                            <p className="f-12 mb-0">Mail us at: <a href="mailto:sales@evaluationz.com">sales@evaluationz.com</a></p>
                                        </div>
                                        <div className="col-md-6">
                                            <button type="submit" className="btn continue-btn float-end shadow-sm">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Support;
