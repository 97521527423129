import React from 'react';
import { Link } from 'react-router-dom';

function LandingHeader() {

        return (
            <nav className="navbar navbar-expand-lg navbar-light header-landing">
                <div className="container-fluid p-0">
                    <div className="header-landing-left col-md-4 p-0 d-flex align-items-center">
                        <Link to="/" className="cursor-pointer">
                            <img src="/images/logo.png" alt="First Logo" className="header-logo-landing"/>
                        </Link>
                    </div>
                    <div id="navbarText" className="header-landing-right col-md-8 p-0 d-flex justify-content-end">
                        <div className="logo">
                            <img src="/images/icatch-group-logo.png" alt="Second Logo" className="header-logo-right"/>
                        </div>
                    </div>
                </div>
            </nav>
        );
};

export default LandingHeader;
