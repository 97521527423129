import React, {useEffect, useState} from "react";
import axios from 'axios';
import {useCookies} from "react-cookie";
import { ResponsiveLine } from '@nivo/line';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import configData from "../../config/index.json";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined"; // Calendar icon

const LineChart = () => {
    // States for start and end dates
    /*const [startDate, setStartDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
    const [endDate, setEndDate] = useState(new Date());*/
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(endDate.getDate() - 7)));

    // State for chart data
    const [chartData, setChartData] = useState([]);
    const [noData, setNoData] = useState(false); // State to handle no data condition
    const [cookies] = useCookies(['clientPlatformID']);
    let expressURL = configData.express_url;

    // Format the dates for the API call
    /*const formatDate = (date) => {
        return date.toISOString().split('T')[0];
    };*/

    const formatDate = (date) => {
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
            .toISOString()
            .split('T')[0];
    };

    // Calculate the difference in months between two dates
    const calculateMonthDifference = (startDate, endDate) => {
        return (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
    };

    const fetchData = () => {
        const loggedPlatformID = cookies.clientPlatformID;
        const start = formatDate(startDate);
        const end = formatDate(endDate);
        console.log('API Request Dates:', start, end); // Debugging log

        axios.post(expressURL + "api/dashboard/usagetrends", {
            startDate: start,
            endDate: end,
            platform_id: loggedPlatformID
        })
            .then((response) => {
                console.log("linechart response", response.data);

                if (response.data.usageTrends && response.data.usageTrends.length > 0) {
                    // Data is available
                    setNoData(false); // Reset no data state
                    const monthDifference = calculateMonthDifference(startDate, endDate);
                    const formattedData = [
                        {
                            id: 'Total Usages',
                            data: response.data.usageTrends.map((item) => ({
                                x: monthDifference > 1 ? item.period : item.period.split('-').pop(), // Use day or month for the x-axis
                                y: item.totalUsages
                            }))
                        }
                    ];
                    setChartData(formattedData);
                } else {
                    // No data found
                    setNoData(true);
                    setChartData([]); // Clear previous data
                }
            })
            .catch((error) => {
                console.error('Error fetching the usage trends:', error);
                setNoData(true); // Set no data state if there's an error
            });
    };

    /*const fetchData = () => {
        const loggedPlatformID = cookies.clientPlatformID;
        axios.post(expressURL +  "api/dashboard/usage-trends", {
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            platform_id: loggedPlatformID
        })
            .then((response) => {
                console.log("linechart response", response)
                // Format data for Nivo Line Chart
                const formattedData = [
                    {
                        id: 'Total Usages',
                        data: response.data.usageTrends.map((item) => ({
                            x: item.month,
                            y: item.totalUsages
                        }))
                    }
                ];
                setChartData(formattedData);
            })
            .catch((error) => {
                console.error('Error fetching the usage trends:', error);
            });
    };*/
    // Fetch data on component load
    useEffect(() => {
        if (cookies.clientPlatformID !== 'undefined' && cookies.clientPlatformID !== undefined) {
            fetchData();
        }
    }, [startDate, endDate, cookies.clientPlatformID]);

    return (
    <>
        <div>
            <div className="col-md-12 p-0 d-flex align-item-end justify-content-end">
                <div className="line-date-picker-container d-flex shadow-sm ">
                    <div className="f-13 time-period-cvr no-wrap">Time Period</div>
                    <div className="line-datepicker-inside d-flex align-items-center p-0">
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="dd MMM yyyy"
                            className="custom-datepicker"
                        />
                        {/*<CalendarMonthOutlinedIcon className="calendar-icon"/>*/}
                        <span className="usage-datepicker-to">to</span>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="dd MMM yyyy"
                            className="custom-datepicker"
                        />
                        <CalendarMonthOutlinedIcon className="calendar-icon"/>
                    </div>
                </div>
            </div>


            <div style={{ height: '300px' }}>
                {noData ? (
                    <div className="line-no-data">
                        <p className="mb-0"><img src="/images/no-data.png" alt="No Data Found" style={{ width: '80px', height: '80px' }} /></p>
                        <p className="f-12">No data found for the selected period.</p>
                    </div>
                ) : (
                <ResponsiveLine
                    data={chartData}
                    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{
                        type: 'linear',
                        min: 'auto',
                        max: 'auto',
                        stacked: true,
                        reverse: false
                    }}
                    curve="natural"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Period',
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Total Usages',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    pointSize={10}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    enableArea={true}
                    areaBaselineValue={0}
                    areaOpacity={0.3}
                    colors={{ scheme: 'nivo' }}
                    defs={[
                        {
                            id: 'gradientA',
                            type: 'linearGradient',
                            colors: [
                                { offset: 0, color: 'inherit' },
                                { offset: 100, color: 'inherit', opacity: 0 }
                            ]
                        }
                    ]}
                    fill={[
                        { match: '*', id: 'gradientA' }
                    ]}
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    tooltip={({ point }) => (
                        <div className="line-chart-tooltip">
                            <strong>Count:</strong> {point.data.yFormatted}
                        </div>
                    )}
                />
                )}
                {/*<ResponsiveLine
                    data={chartData}
                    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{
                        type: 'linear',
                        min: 'auto',
                        max: 'auto',
                        stacked: true,
                        reverse: false
                    }}
                    curve="natural" // Set the curve type to "monotoneX" for a smooth curve
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Month',
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Total Usages',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    pointSize={10}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    enableArea={true}
                    areaBaselineValue={0}
                    areaOpacity={0.3}
                    colors={{ scheme: 'nivo' }}
                    defs={[
                        {
                            id: 'gradientA',
                            type: 'linearGradient',
                            colors: [
                                { offset: 0, color: 'inherit' },
                                { offset: 100, color: 'inherit', opacity: 0 }
                            ]
                        }
                    ]}
                    fill={[
                        { match: '*', id: 'gradientA' }
                    ]}
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                />*/}
            </div>
        </div>
        {/*<div className="">
            <div className="d-flex justify-content-end">
                <div className="col-md-5 shadow-sm bg-white calender-cover d-flex">
                    <div className="f-13 time-period-cvr">Time Period</div>
                    <div className="calendar-inner d-flex">
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            placeholderText="Start Date"
                        />
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            placeholderText="End Date"
                            minDate={startDate}
                        />
                    </div>
                </div>

            </div>
            <div className="">

            </div>
        </div>*/}
    </>
    );
};

export default LineChart;
