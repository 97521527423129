import React, {useEffect, useState,useRef} from "react";
import Banner from './Banner';
import LandingHeader from "./LandingHeader";
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";

function Landing() {
    const scrollableRef = useRef(null);

    const [showScrollButton, setShowScrollButton] = useState(false);

    const scrollToTop = () => {
        if (scrollableRef.current) {
            scrollableRef.current.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrolled = scrollableRef.current.scrollTop;
            setShowScrollButton(scrolled > 100);
        };

        const currentRef = scrollableRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));

    const scrollToTopStyle = {
        position: 'fixed',
        bottom: '40px',
        right: '40px',
        display: showScrollButton ? 'block' : 'none',
        backgroundColor: '#fff',
        color: '#000',
        padding: '10px',
        borderRadius: '50%',
        cursor: 'pointer',
        zIndex: 99999,
        boxShadow: 'inset 0px 2px 5px rgba(0, 0, 0, 0.5)',
    };

    return (
        <>
            <div ref={scrollableRef} className="landing-container">
                <LandingHeader></LandingHeader>
                <Banner />
                <div className="container-fluid main-content text-center">
                    <h2>DETECT AND TERMINATE IMPERSONATION IN REAL-TIME</h2>
                    <p>Instances of impersonation during interviews, especially during remote hiring, is skyrocketing. Take the right steps to detect and red-flag impersonation.</p>
                    <p>Evaluationz’ on-demand API-integrable interview impersonation detection tool is powered by AI and has the capability to provide upto 99% match of the genuineness of the candidate (using face, voice, audio/video and government ID) across the various stages of the interview process on a real time basis right up to the onboarding stage.</p>
                </div>
                <div className="point-authentication-container text-center shadow-sm">
                    <h2>3 - POINT AUTHENTICATION</h2>
                    <div className="col-md-12 row p-0">
                        <div className="col-md-4">
                            <div className="">
                                <VideoCameraFrontOutlinedIcon className="auth-icon"/>
                            </div>
                            <div className="auth-hd f-30 c-white">
                                FACE
                            </div>
                            <div className="auth-description text-center">
                                <p>Verify the candidate's identity by matching their facial features with the provided video.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="">
                                <RecordVoiceOverOutlinedIcon className="auth-icon"/>
                            </div>
                            <div className="auth-hd f-30 c-white">
                                VOICE
                            </div>
                            <div className="auth-description text-center">
                                <p>Authenticate candidates by analyzing their voice patterns to ensure the speaker's identity.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="">
                                <BadgeOutlinedIcon className="auth-icon"/>
                            </div>
                            <div className="auth-hd f-30 c-white">
                                GOVERNMENT ID
                            </div>
                            <div className="auth-description text-center">
                                <p>Verify the candidate's identity by matching their facial features with the provided video.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid main-content text-center shadow-sm">
                    <h2>MULTI-PLATFORM COMPATIBILITY</h2>
                    <div className="col-md-12 row p-0 multiplatform-container">
                        <div className="col-md-4 pb-3">
                            <img src="/images/google-meet-icon.png" alt="Google Meet" className="platform-icon"/>
                            <span>Google Meet</span>
                        </div>
                        <div className="col-md-4 pb-3">
                            <img src="/images/teams-ico.png" alt="Google Meet" className="platform-icon"/>
                            <span>Microsoft Teams</span>
                        </div>
                        <div className="col-md-4">
                            <img src="/images/zoom-ico.png" alt="Google Meet" className="platform-icon"/>
                            <span>Zoom</span>
                        </div>
                    </div>
                </div>

                <div className="container-fluid text-center real-time-container">
                    <h2>REAL-TIME DETECTIONS OF FACE VOICE AND IDENTITY</h2>
                    <div className="col-md-12 row p-0">
                        <div className="col-md-4">
                            <div className="">
                                <img src="/images/security.png" alt="Google Meet" className="real-time-icon"/>
                            </div>
                            <div className="auth-hd f-22 c-black pt-2 fw-light">
                                Reject unethical candidates
                            </div>
                            <div className="auth-description text-center">
                                <p>Identify and eliminate fraudulent candidates efficiently.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="">
                                <img src="/images/clock.png" alt="Google Meet" className="real-time-icon"/>
                            </div>
                            <div className="auth-hd f-22 c-black pt-2 fw-light">
                                Save time
                            </div>
                            <div className="auth-description text-center">
                                <p>Automate verification processes to streamline workflows.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="">
                                <img src="/images/padlock.png" alt="Google Meet" className="real-time-icon"/>
                            </div>
                            <div className="auth-hd f-22 c-black pt-2 fw-light">
                                Reduce risk
                            </div>
                            <div className="auth-description text-center">
                                <p>Minimize the chances of hiring fraud with advanced detection.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="integrates-section">
                    <h2>INTEGRATES</h2>
                    <p className="integrates-section-sub-hd">Seamlessly with HRMS and ATS</p>

                    <div className="row integration-blocks">
                        <div className="col-md-6 hrms-container">
                            <img src="/images/hrms.png" alt="HRMS Icon" className="integration-icon"/>
                            <h3 className="f-25">HRMS</h3>
                            <p className="c-black">Streamline your HR processes with seamless integration into leading HRMS systems.</p>
                        </div>
                        <div className="col-md-6 ats-container">
                            <img src="/images/ats.png" alt="ATS Icon" className="integration-icon"/>
                            <h3 className="f-25">ATS</h3>
                            <p className="c-black">Enhance your recruitment workflow by integrating with popular ATS platforms.</p>
                        </div>
                    </div>
                </div>

                <div className="landing-footer">
                    <div className="row">
                        <div className="col-md-8">
                            <img src="/images/logo.png" alt="Evaluationz" className="header-logo mb-3"/>
                            <div className="col-md-12 p-0">
                                <span className="f-14 mr-5">Follow Us On</span>
                                <span className="footer-social">
                                    <a className="c-white mx-3" target="_blank" href="https://www.facebook.com/people/Evaluationz/100067203251989/?mibextid=LQQJ4d&rdid=5YzvPHDGxhbig3Ot&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2Fq9n8XovCm3zRxVz6%2F%3Fmibextid%3DLQQJ4d">
                                        <FacebookIcon/>
                                    </a>
                                </span>
                                <span className="footer-social">
                                    <a className="c-white" target="_blank" href="https://www.linkedin.com/company/evaluationz-india-pvt-ltd/?viewAsMember=true">
                                        <img src="/images/linkedin-logo.png" alt="LinkedIn" className="linkedin-icon"/>
                                    </a>
                                </span>
                            </div>
                            <div className="term-privacy-container">
                                <ul className="footer-links">
                                    <li><a href="https://evaluationz.com/terms-and-conditions/">Terms of use</a></li>
                                    <li><a href="https://evaluationz.com/privacy-policy-2/">Privacy Policy</a></li>
                                </ul>
                            </div>
                            {/*<p className="f-14">Evaluationz India Private Limited</p>
                            <p className="f-12">10th Floor, Vayudhoot Chambers,<br/>MG Rd, Bengaluru,<br/>Karnataka 560001</p>*/}
                        </div>
                        {/*<div className="col-md-4">
                            <p className="f-14">Get Help</p>
                            <ul class="footer-links">
                                <li><a href="https://evaluationz.com/terms-and-conditions/">Terms of use</a></li>
                                <li><a href="https://evaluationz.com/privacy-policy-2/">Privacy Policy</a></li>
                                <li><a href="#">FAQ’s</a></li>
                            </ul>
                        </div>*/}
                        <div className="col-md-4 footer-social text-left pt-4">
                            <p className="f-16 fw-bold">Evaluationz India Private Limited</p>
                            <p className="f-12">10th Floor, Vayudhoot Chambers,<br/>MG Rd, Bengaluru,<br/>Karnataka 560001</p>
                        </div>
                    </div>
                </div>

                <BootstrapTooltip title="Scroll to top">
                <div
                    className="scroll-to-top"
                    style={scrollToTopStyle}
                    onClick={scrollToTop}
                >
                    <KeyboardDoubleArrowUpOutlinedIcon style={{ fontSize: '24px' }} />
                </div>
                </BootstrapTooltip>

            </div>
        </>
    );
}

export default Landing;
