import React from 'react';

const Banner = () => {
    return (
        <section className="banner">
            <div className="intro-text">
                <h1>ENSURE GENUINE HIRES <br/>WITH REAL-TIME AI DETECTION</h1>
                <p>Detect and prevent impersonation <br/>during remote interviews with high accuracy</p>
                <div className="banner-buttons">
                    <button className="login-btn"
                            onClick={() => window.location.href = '/login'}>
                        Login
                    </button>
                    <button className="signup-btn"
                            onClick={() => window.location.href = '/signup'}>
                        Sign Up
                    </button>
                </div>
            </div>
            <div className="face-image">
                <img src="/images/banner-face.png" alt="Face" className="face-image-face" />
            </div>
        </section>
    );
};

export default Banner;
