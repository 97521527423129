import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        const contentElement = document.querySelector('.content'); // Scroll the .content div instead of window
        if (contentElement) {
            contentElement.scrollTo({
                top: 0,
                behavior: 'smooth', // optional smooth scrolling
            });
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }, [pathname]);

    return null;
};

export default ScrollToTop;
