import React, {useEffect, useState, useRef } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
//import 'react-pro-sidebar/dist/css/style.css';
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import axios from "axios";
import {
    Modal
} from "react-bootstrap";
import { Auth } from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Avatar, Box, IconButton, Typography, useTheme} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import "../style.css";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useCookies } from 'react-cookie';
import swal from "sweetalert";
import configData from "../config/index.json";
import { useLocation, useNavigate } from 'react-router-dom';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';

function SideNav({ setIsAuthenticated, setSpinnerLoading }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [BasicModalShow, setShowModal] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState(() => {
        const path = location.pathname;
        if (path === '/manage-sub-client') return 'Manage Sub Client';
        if (path === '/billing-console') return 'Billing Console';
        if (path === '/configuration') return 'General';
        if (path === '/loadbalancer') return 'Loadbalancer';
        if (path === '/support') return 'Support';
        if (path === '/icatch') return 'iCatch';
        if (path === '/candidate-list') return 'Candidate List';
        return 'Dashboard';  // Default to 'Dashboard' if no match
    });
    const isNotificationsActive = location.pathname === '/notification';
    const isProfileSettingsActive = location.pathname === '/profile-settings';
    const [cookies, removeCookie] = useCookies(['clientEmail', 'clientName', 'clientPlatformID', 'readCount', 'clientAccessToken']);
    const [name, updateName] = useState('');
    const [email, updateEmail] = useState('');
    const [platformID, updatePlatformID] = useState('');
    const [imagePreviewUrl, setImagePreviewUrl] = useState('/images/client-default.png');
    const [unreadCount, setUnreadCount] = useState(0);
    const [isTrialCenterActive, setIsTrialCenterActive] = useState(false);
    const [isConfigurationActive, setConfigurationActive] = useState(false);
    let expressURL = configData.express_url;

    const sidebarRef = useRef(null);

    const trialCenterIcon = isTrialCenterActive
        ? '/images/trial-center-active.png'
        : '/images/trial-center.png';

    const configurationIcon = isTrialCenterActive
        ? '/images/settings-sliders-active.png'
        : '/images/settings-sliders.png';

    useEffect(() => {
        const path = location.pathname;
        if (path === '/manage-sub-client') setSelected('Manage Sub Client');
        else if (path === '/billing-console') setSelected('Billing Console');
        else if (path === '/configuration') setSelected('General');
        else if (path === '/loadbalancer') setSelected('Loadbalancer');
        else if (path === '/support') setSelected('Support');
        else if (path === '/icatch') setSelected('iCatch');
        else if (path === '/candidate-list') setSelected('Candidate List');
        else setSelected('Dashboard');
    }, [location]);

    useEffect(() => {
        // updateName(cookies.clientName);
        // updateEmail(cookies.clientEmail);
        updatePlatformID(cookies.clientPlatformID);
        if (cookies.clientEmail !== undefined) {
            getprofiledetails()
        }
    }, [cookies.clientEmail]);

    useEffect(() => {
        if(cookies.clientPlatformID !== undefined && cookies.clientPlatformID !== "undefined") {
            getunreadcount()
        }
    }, [cookies.clientPlatformID, cookies.readCount]);

    useEffect(() => {
        // Function to check the screen size
        const handleResize = () => {
            if (window.innerWidth < 1240) {
                setIsCollapsed(true);
            } else {
                setIsCollapsed(false);
            }
        };

        // Initial check
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));

    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));

    const handleSubmenuToggle = () => {
        setTimeout(() => {
            sidebarRef.current?.scrollTo({
                top: sidebarRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }, 200);
    };

    async function getprofiledetails() {
        console.log("INSIDE")
        const loggedInEmail = {"email":cookies.clientEmail};
        console.log("logedemail", loggedInEmail)
        await axios.post(expressURL + "icatch/profile/getprofiledetails", loggedInEmail)
            .then(response => {
                console.log("client", response.data);
                if (response.data.status === 200) {
                    console.log("client get profile", response.data.data);
                    //setClientDetails(() => ({...clntData, first_name: firstName}));
                    const clientData = response.data.data;
                    console.log("client", clientData);
                    if (clientData) {
                        // const fullName = `${clientData.first_name || ''} ${clientData.last_name || ''}`.trim();
                        updateName(clientData.full_name);
                        updateEmail(clientData.email || '');
                        setImagePreviewUrl(clientData.logo || '');
                        /*setImagePreviewUrl(clientData.logo || '/images/client-default.png');*/
                    }
                    // setImagePreviewUrl(clientData.logo);
                } else {
                    console.error('Failed to fetch client details');
                    swal({
                        title: 'Error!',
                        text: 'Failed to fetch client details',
                        icon: 'error',
                        button: "OK",
                        closeOnClickOutside: false,
                    });
                }
            }).catch(error => {
                console.error('Error fetching client details:', error);
                swal({
                    title: 'Error!',
                    text: 'Something went wrong.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            })
    }

    async function getunreadcount() {
        console.log("INSIDE unread count")
        const clientPlatformID = {"platform_id":cookies.clientPlatformID};
        console.log("clientPlatformID", clientPlatformID)
        await axios.post(expressURL + "api/notification/getunreadcount", clientPlatformID)
            .then(response => {
                console.log("unread count", response.data.unread_count);
                setUnreadCount(response.data.unread_count);
            }).catch(error => {
                console.error('Failed to fetch notification unread count:', error);
                swal({
                    title: 'Error!',
                    text: 'Something went wrong.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            })
    }

    const handleToggleSidebar = (event) => {
        event.preventDefault();
        setIsCollapsed(!isCollapsed);
    };

    const handleCloseModal = () => setShowModal(false);

    const handleMenuItemClick = (menuItem) => {
        setSelected(menuItem);
    };

    function LogOut() {
        setShowModal(true)
    }

    /*const signOut = () => {
        Auth.signOut()
            .then(() => {
                removeCookie('clientName');
                removeCookie("clientEmail");
                removeCookie("clientPlatformID");
                removeCookie("clientClientID");
                removeCookie("readCount");
                window.location.href = '/';
            })
            .catch(error => {
                console.error('Sign out error:', error);
                swal('Failed to sign out. Please try again.');
            });
    };*/

    const signOut = () => {
        const accessToken = cookies.clientAccessToken;
        setSpinnerLoading(true);
        axios.post(expressURL + "icatch/cognito/logout", {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                setSpinnerLoading(false);
                console.log("Logout successful:", response.data);

                removeCookie("clientName");
                removeCookie("clientEmail");
                removeCookie("clientPlatformID");
                removeCookie("clientClientID");
                removeCookie("clientAccessToken");
                removeCookie("readCount");

                setIsAuthenticated(false);
                window.location.href = "/";
            }).catch((error) => {
            setSpinnerLoading(false);
            if (error.response?.data?.message === "Access Token has expired" && error.response?.data?.status === 401) {
                console.log("Access Token expired, logging out...");
                removeCookie("clientName");
                removeCookie("clientEmail");
                removeCookie("clientPlatformID");
                removeCookie("clientClientID");
                removeCookie("clientAccessToken");
                removeCookie("readCount");

                // Set authenticated state and redirect
                setIsAuthenticated(false);
                window.location.href = "/";
            } else {
                // For any other error, show the popup
                console.log("Sign out error1:", error.response?.data?.message);
                console.log("Sign out error2:", error.response?.data?.status);
                console.log("Sign out error3:", error.message);
                swal({
                    text: `${error.response?.data?.message || error.message}`,
                    icon: 'error',
                    button: 'OK',
                });
            }
        });
    };

    function Cancel() {
        setShowModal(false)
    }

    let loggedInEmail = cookies.clientEmail;

    return (
        <>


            <Modal show={BasicModalShow} onHide={handleCloseModal} backdrop="static">
                <Modal.Body>
                    <Modal.Title className="f-50 text-center">
                        <i className="mdi mdi-alert-circle-outline c-black"> </i>
                    </Modal.Title>
                    <Modal.Title className="f-18 text-center">Are you sure want to Logout?</Modal.Title>
                    <div className="col-lg-12 d-flex align-items-center justify-content-center mt-2">
                        <button className='btn btn-cancel shadow-sm' onClick={Cancel}>NO</button>
                        <button className='btn ml-3 continue-btn shadow-sm' onClick={signOut} >YES</button>
                    </div>
                </Modal.Body>
            </Modal>
            <div id="sidebar-container"
                 className={`has-sidebar fixed-sidebar fixed-header ${isCollapsed ? 'sidebar-collapsed' : 'sidebar-expanded'}`}>
                <Sidebar collapsed={isCollapsed} submenuOpenDirection="right">
                    <a href="" id="btn-collapse">
                        <LightTooltip title="Collapse">
                            <IconButton onClick={handleToggleSidebar}>
                                <MenuOpenOutlinedIcon style={{ color: '#fff' }} />
                            </IconButton>
                        </LightTooltip>
                    </a>
                    <div className="sidebar-layout" ref={sidebarRef}>
                        <div className="sidebar-header d-flex justify-content-center align-item-center">
                            <div className="user-profile c-white">
                                <div className="col-md-12 c-white user-container d-flex justify-content-center align-item-center">
                                    <div className="col-md-12 d-flex justify-content-center align-item-center pb-3">
                                        {!isCollapsed ? (
                                            <div className="gradient-border">
                                                {imagePreviewUrl ? (
                                                    <img src={imagePreviewUrl}  alt="Client Image" className="client-img" />
                                                ) : (
                                                    <div className="profile-initial-sidenav">
                                                        {name.charAt(0).toUpperCase()}
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="gradient-border-collapse">
                                                {imagePreviewUrl ? (
                                                    <img src={imagePreviewUrl} alt="Client Image" className="client-img-collapse" />
                                                ) : (
                                                    <div className="profile-initial-sidenav-collapse">
                                                        {name.charAt(0).toUpperCase()}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {!isCollapsed && (
                                    <div className="">
                                        <div className="col-md-12 d-flex justify-content-center align-item-center">
                                            <span className="f-18 name-text">{name}</span>
                                        </div>

                                        <div className="col-md-12 d-flex justify-content-center align-item-center pb-4">
                                            <span className="f-10 name-text">{email}</span>
                                        </div>

                                        <div className="col-md-12 d-flex justify-content-center align-item-center">
                                            <Link to="/notification"
                                                  className={`notification-settings-icon d-flex justify-content-center align-item-center cursor-pointer text-decoration-none ${isNotificationsActive ? 'active-icon' : ''}`}>
                                                <div className="col-md-4 d-flex justify-content-center align-item-center cursor-pointer">
                                                    <LightTooltip title="Notifications">
                                                        <NotificationsNoneOutlinedIcon className="set-not-power-icon"/>
                                                    </LightTooltip>
                                                    {unreadCount > 0 && (
                                                        <span className="badge">{unreadCount}</span>
                                                    )}
                                                </div>
                                            </Link>
                                            <Link to="/profile-settings"
                                                  className={`notification-settings-icon d-flex justify-content-center align-item-center cursor-pointer text-decoration-none ${isProfileSettingsActive ? 'active-icon' : ''}`}>
                                                <div className="col-md-4 d-flex justify-content-center align-item-center cursor-pointer">
                                                    <LightTooltip title="Profile Settings">
                                                        <ManageAccountsIcon className="set-not-power-icon"/>
                                                    </LightTooltip>
                                                </div>
                                            </Link>
                                            <div className="notification-settings-icon col-md-4 d-flex justify-content-center align-item-center cursor-pointer" onClick={LogOut}>
                                                <LightTooltip title="Logout">
                                                    <PowerSettingsNewOutlinedIcon className="set-not-power-icon"/>
                                                </LightTooltip>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="sidebar-content">
                            <Menu iconShape="circle">
                                <Link to="/dashboard" className="ps-menu-label">
                                    <MenuItem
                                        icon={<HomeOutlinedIcon />}
                                        style={selected === 'Dashboard' ? { color: '#fff' } : {}}
                                        className={`ps-menu-button ${selected === 'Dashboard' ? 'ps-menu-button-active' : ''}`}
                                        onClick={() => handleMenuItemClick('Dashboard')}>
                                        <Link to="/dashboard" className="ps-menu-label">Dashboard</Link>
                                    </MenuItem>
                                </Link>
                                <Link to="/manage-sub-client" className="ps-menu-label">
                                    <MenuItem icon={<VpnKeyOutlinedIcon />}
                                              style={selected === 'Manage Sub Client' ? { color: '#fff' } : {}}
                                              className={`ps-menu-button ${selected === 'Manage Sub Client' ? 'ps-menu-button-active' : ''}`}
                                              onClick={() => handleMenuItemClick('Manage Sub Client')}>
                                        <Link to="/manage-sub-client" className="ps-menu-label">Manage Sub Client</Link>
                                    </MenuItem>
                                </Link>
                                <SubMenu label="Trial Center"
                                         className="submenu-right"
                                         onClick={handleSubmenuToggle}
                                         icon={<img src={trialCenterIcon} alt="Trial Center" style={{ width: '20px', height: '20px' }} />}>
                                    <Link to="/icatch" className="ps-menu-label submenu-content-link">
                                        <MenuItem style={selected === 'iCatch' ? { color: '#fff' } : {}}
                                                  className={`ps-menu-button ${selected === 'iCatch' ? 'ps-menu-button-active' : ''}`}
                                                  onClick={() => handleMenuItemClick('iCatch')}>
                                            <Link to="/icatch" className="ps-menu-label">iCatch</Link>
                                        </MenuItem>
                                    </Link>
                                    <Link to="/candidate-list" className="ps-menu-label submenu-content-link">
                                        <MenuItem style={selected === 'Candidate List' ? { color: '#fff' } : {}}
                                                  className={`ps-menu-button ${selected === 'Candidate List' ? 'ps-menu-button-active' : ''}`}
                                                  onClick={() => handleMenuItemClick('Candidate List')}>
                                            <Link to="/candidate-list" className="ps-menu-label">Candidate List</Link>
                                        </MenuItem>
                                    </Link>
                                </SubMenu>

                                <Link to="/billing-console" className="ps-menu-label">
                                    <MenuItem icon={<ReceiptOutlinedIcon />}
                                              style={selected === 'Billing Console' ? { color: '#fff'} : {}}
                                              className={`ps-menu-button ${selected === 'Billing Console' ? 'ps-menu-button-active' : ''}`}
                                              onClick={() => handleMenuItemClick('Billing Console')}>
                                        <Link to="/billing-console" className="ps-menu-label">Billing Console</Link>
                                    </MenuItem>
                                </Link>

                                <SubMenu label="Configuration"
                                         className="submenu-right"
                                         onClick={handleSubmenuToggle}
                                         icon={<img src={configurationIcon} alt="Configuration" style={{ width: '20px', height: '20px' }} />}>
                                    <Link to="/configuration" className="ps-menu-label submenu-content-link">
                                        <MenuItem style={selected === 'General' ? { color: '#fff'} : {}}
                                                  className={`ps-menu-button ${selected === 'General' ? 'ps-menu-button-active' : ''}`}
                                                  onClick={() => handleMenuItemClick('General')}>
                                            <Link to="/configuration" className="ps-menu-label">General</Link>
                                        </MenuItem>
                                    </Link>
                                    <Link to="/loadbalancer" className="ps-menu-label submenu-content-link">
                                        <MenuItem style={selected === 'Loadbalancer' ? { color: '#fff' } : {}}
                                                  className={`ps-menu-button ${selected === 'Loadbalancer' ? 'ps-menu-button-active' : ''}`}
                                                  onClick={() => handleMenuItemClick('Loadbalancer')}>
                                            <Link to="/loadbalancer" className="ps-menu-label">Loadbalancer</Link>
                                        </MenuItem>
                                    </Link>
                                </SubMenu>
                            </Menu>
                        </div>
                    </div>
                    {/*<div className="sidebar-header d-flex align-items-center justify-content-between">
                        <LightTooltip title="Collapse">
                            <IconButton onClick={handleToggleSidebar}>
                                <MenuOpenOutlinedIcon style={{ color: '#fff' }} />
                            </IconButton>
                        </LightTooltip>
                    </div>*/}

                    {/*<div className="user-profile c-white">
                        <div className="col-md-12 c-white user-container d-flex justify-content-center align-item-center">
                            <div className="col-md-12 d-flex justify-content-center align-item-center pb-3">
                                {!isCollapsed ? (
                                    <div className="gradient-border">
                                        {imagePreviewUrl ? (
                                            <img src={imagePreviewUrl}  alt="Client Image" className="client-img" />
                                        ) : (
                                            <div className="profile-initial-sidenav">
                                                {email.charAt(0).toUpperCase()}
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="gradient-border-collapse">
                                        {imagePreviewUrl ? (
                                            <img src={imagePreviewUrl} alt="Client Image" className="client-img-collapse" />
                                        ) : (
                                            <div className="profile-initial-sidenav-collapse">
                                                {email.charAt(0).toUpperCase()}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        {!isCollapsed && (
                            <div className="">
                                <div className="col-md-12 d-flex justify-content-center align-item-center">
                                    <span className="f-18">{name}</span>
                                </div>

                                <div className="col-md-12 d-flex justify-content-center align-item-center pb-4">
                                    <span className="f-10">{email}</span>
                                </div>

                                <div className="col-md-12 d-flex justify-content-center align-item-center">
                                    <Link to="/notification"
                                          className={`notification-settings-icon d-flex justify-content-center align-item-center cursor-pointer text-decoration-none ${isNotificationsActive ? 'active-icon' : ''}`}>
                                        <div className="col-md-4 d-flex justify-content-center align-item-center cursor-pointer">
                                            <LightTooltip title="Notifications">
                                                <NotificationsNoneOutlinedIcon className="set-not-power-icon"/>
                                            </LightTooltip>
                                            {unreadCount > 0 && (
                                                <span className="badge">{unreadCount}</span>
                                            )}
                                        </div>
                                    </Link>
                                    <Link to="/profile-settings"
                                          className={`notification-settings-icon d-flex justify-content-center align-item-center cursor-pointer text-decoration-none ${isProfileSettingsActive ? 'active-icon' : ''}`}>
                                        <div className="col-md-4 d-flex justify-content-center align-item-center cursor-pointer">
                                            <LightTooltip title="Profile Settings">
                                                <ManageAccountsIcon className="set-not-power-icon"/>
                                            </LightTooltip>
                                        </div>
                                    </Link>
                                    <div className="notification-settings-icon col-md-4 d-flex justify-content-center align-item-center cursor-pointer" onClick={LogOut}>
                                        <LightTooltip title="Logout">
                                            <PowerSettingsNewOutlinedIcon className="set-not-power-icon"/>
                                        </LightTooltip>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>*/}

                    {/*<Menu iconShape="circle">
                        <Link to="/dashboard" className="ps-menu-label">
                            <MenuItem
                                icon={<HomeOutlinedIcon />}
                                activeStyle={{ color: '#fff', borderRight: '2px solid #0075F3' }}
                                className={`ps-menu-button ${selected === 'Dashboard' ? 'ps-menu-button-active' : ''}`}
                                onClick={() => handleMenuItemClick('Dashboard')}>
                                <Link to="/dashboard" className="ps-menu-label">Dashboard</Link>
                            </MenuItem>
                        </Link>
                        <Link to="/manage-sub-client" className="ps-menu-label">
                            <MenuItem icon={<VpnKeyOutlinedIcon />}
                                      activeStyle={{ color: '#fff', borderRight: '2px solid #0075F3' }}
                                      className={`ps-menu-button ${selected === 'Manage Sub Client' ? 'ps-menu-button-active' : ''}`}
                                      onClick={() => handleMenuItemClick('Manage Sub Client')}>
                                <Link to="/manage-sub-client" className="ps-menu-label">Manage Sub Client</Link>
                            </MenuItem>
                        </Link>
                        <SubMenu label="Trial Center"
                                 className="submenu-right"
                                 icon={<img src={trialCenterIcon} alt="Trial Center" style={{ width: '20px', height: '20px' }} />}>
                            <Link to="/icatch" className="ps-menu-label submenu-content-link">
                                <MenuItem activeStyle={{ color: '#fff', borderRight: '2px solid #0075F3' }}
                                          className={`ps-menu-button ${selected === 'iCatch' ? 'ps-menu-button-active' : ''}`}
                                          onClick={() => handleMenuItemClick('iCatch')}>
                                    <Link to="/icatch" className="ps-menu-label">iCatch</Link>
                                </MenuItem>
                            </Link>
                            <Link to="/candidate-list" className="ps-menu-label submenu-content-link">
                                <MenuItem activeStyle={{ color: '#fff', borderRight: '2px solid #0075F3' }}
                                          className={`ps-menu-button ${selected === 'Candidate List' ? 'ps-menu-button-active' : ''}`}
                                          onClick={() => handleMenuItemClick('Candidate List')}>
                                    <Link to="/candidate-list" className="ps-menu-label">Candidate List</Link>
                                </MenuItem>
                            </Link>
                        </SubMenu>

                        <Link to="/billing-console" className="ps-menu-label">
                            <MenuItem icon={<ReceiptOutlinedIcon />}
                                      activeStyle={{ color: '#fff', borderRight: '2px solid #0075F3' }}
                                      className={`ps-menu-button ${selected === 'Billing Console' ? 'ps-menu-button-active' : ''}`}
                                      onClick={() => handleMenuItemClick('Billing Console')}>
                                <Link to="/billing-console" className="ps-menu-label">Billing Console</Link>
                            </MenuItem>
                        </Link>
                        <SubMenu label="Configuration"
                                 className="submenu-right"
                                 icon={<img src={configurationIcon} alt="Configuration" style={{ width: '20px', height: '20px' }} />}>
                            <Link to="/configuration" className="ps-menu-label submenu-content-link">
                                <MenuItem activeStyle={{ color: '#fff', borderRight: '2px solid #0075F3' }}
                                          className={`ps-menu-button ${selected === 'iCatch' ? 'ps-menu-button-active' : ''}`}
                                          onClick={() => handleMenuItemClick('iCatch')}>
                                    <Link to="/configuration" className="ps-menu-label">iCatch</Link>
                                </MenuItem>
                            </Link>
                            <Link to="/loadbalancer" className="ps-menu-label submenu-content-link">
                                <MenuItem activeStyle={{ color: '#fff', borderRight: '2px solid #0075F3' }}
                                          className={`ps-menu-button ${selected === 'Loadbalancer' ? 'ps-menu-button-active' : ''}`}
                                          onClick={() => handleMenuItemClick('Loadbalancer')}>
                                    <Link to="/loadbalancer" className="ps-menu-label">Loadbalancer</Link>
                                </MenuItem>
                            </Link>
                        </SubMenu>
                    </Menu>*/}
                </Sidebar>
            </div>
        </>
    );
}

export default SideNav;
