import React, {useEffect, useState} from "react";
import "../style.css";
import { Auth } from 'aws-amplify';
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useLocation } from 'react-router-dom';
import configData from "../config/index.json";
import MuiAlert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";

function Validate(props) {
    //const [email, setEmail] = useState('');
    // const [code, setCode] = useState('');
    const [code, setCode] = useState(Array(6).fill(''));
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const query = new URLSearchParams(useLocation().search);
    const email = query.get('email');
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    let expressURL = configData.express_url;

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const userEmail = params.get('email');
        const userCode = params.get('code');

        if (userEmail && userCode) {
            //setEmail(userEmail);
            setCode(userCode);

            // Auto-confirm if both email and code are available in the URL
            confirmUserEmail(userEmail, userCode);
        }
    }, []);

    // const confirmUserEmail = async (email, code) => {
    //     setLoading(true);
    //     try {
    //         await Auth.confirmSignUp(email, code);
    //         setMessage('Your email has been successfully verified!');
    //         swal({
    //             text: 'Your email has been successfully verified!',
    //             icon: 'success',
    //             button: 'OK',
    //         });
    //         setTimeout(() => {
    //             navigate('/login');
    //         }, 2000);
    //     } catch (error) {
    //         setMessage('Verification failed. Please try again or enter the code manually.');
    //         swal({
    //             text: `Verification failed: ${error.message}. Please try again.`,
    //             icon: 'error',
    //             button: 'OK',
    //         });
    //     } finally {
    //         setLoading(false);
    //     }
    // };
    const confirmUserEmail = async (email, code) => {
        setLoading(true);
        const confirmationCode = code.join('');
        try {
            const response = await axios.post(expressURL + "icatch/confirm/confirmregistration", {
                email,
                code: confirmationCode
            });
            console.log("confirmRegistration response", response)

            if (response.status === 200) {
                setMessage('Your email has been successfully verified!');
                swal({
                    text: 'Your email has been successfully verified!',
                    icon: 'success',
                    button: 'OK',
                });
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            } /*else {
                throw new Error('Email confirmation failed.');
            }*/
        } catch (error) {
            console.log("confirmRegistration error", error)
            //setMessage('Verification failed. Please try again or enter the code manually.');
            swal({
                text: error.response?.data?.error,
                icon: 'error',
                button: 'OK',
            });
        } finally {
            setLoading(false);
        }
    };


    const handleRegistrationConfirmation = async function(event) {
        event.preventDefault();

        if (!email || !code) {
            swal({
                text: 'Please enter both email and authentication code.',
                icon: 'warning',
                button: 'OK',
            });
            return;
        }

        // Manual confirmation using the form inputs
        confirmUserEmail(email, code);
    }

    const handleChange = (index, event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);
            if (value && index < 5) {
                document.getElementById(`code-input-${index + 1}`).focus();
            }
        }
    };

    const handleBackspace = (index, event) => {
        if (event.key === 'Backspace' && code[index] === '' && index > 0) {
            document.getElementById(`code-input-${index - 1}`).focus();
            const newCode = [...code];
            newCode[index - 1] = '';
            setCode(newCode);
            event.preventDefault();
        }
    };

    const handlePaste = (event) => {
        const pasteData = event.clipboardData.getData('Text');

        if (/^\d{6}$/.test(pasteData)) {
            const newCode = pasteData.split('');
            setCode(newCode);
            document.getElementById(`code-input-5`).focus();
            event.preventDefault();
        }
    };

    /*const handleResendVerification = async () => {
        setLoading(true);
        setMessage('');

        try {
            await Auth.resendSignUp(email); // Resend the verification code
            setMessage('Verification code resent successfully!');
        } catch (error) {
            // setMessage(Error: ${error.message});
        } finally {
            setLoading(false);
        }
    };*/

    const handleResendVerification = async () => {
        //setLoading(true);
        setMessage('');

        try {
            const response = await axios.post(expressURL + "icatch/cognito/resendverification", {
                email
            });
            console.log("handleResendVerification response", response.data.success);
            if (response.data.success) {
                setOpen(true);
                setMessage('Verification code resent successfully!');
            } else {
                console.log("responce.data.message");
                // setMessage('Failed to resend verification code.');
            }
        } catch (error) {
            console.log("handleResendVerification error", error.response?.data?.message);
            console.log("handleResendVerification error", error.message);
            // setMessage(`Error: ${error.response?.data?.message || error.message}`);
            swal({
                text: error.response?.data?.message || error.message,
                icon: 'error',
                button: 'OK',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <div className="row no-gutters">
                <div className="col-md-6 p-0 login-left-container d-flex flex-column justify-content-between">
                    <div className="logo-container d-flex justify-content-center align-items-center">
                        <img src="/images/icatch-logo.png" alt="iCatch Logo" className="login-icatch" />
                    </div>
                    <div className="eval-logo-container d-flex justify-content-start align-items-center">
                        <img src="/images/logo.png" alt="Evaluationz Logo" className="eval-logo" />
                    </div>
                </div>
                <div className="col-md-6 p-0 login-right-container">
                    <div className="form-container-signup">
                        <form className="login-form" onSubmit={handleRegistrationConfirmation}>
                            <div className="head-container">
                                <h2 className="create-account-hd">Verify your Account</h2>
                            </div>
                            <div className="row mt-5 px-3">
                                {/*<div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email*</label>
                                        <input type="email"
                                               value={email}
                                               onChange={event => setEmail(event.target.value)}
                                               className="form-control on-select"
                                               placeholder="Enter registered email"
                                               disabled={!!email} />
                                    </div>
                                </div>*/}
                                {/*<div className="col-md-12">*/}
                                    {/*<div className="form-group">
                                        <label>Authentication Code*</label>
                                        <input type="text"
                                               value={code}
                                               onChange={event => setCode(event.target.value)}
                                               className="form-control on-select"
                                               placeholder="Enter a authentication code"/>
                                    </div>*/}
                                    <label className="auth-code-label">Authentication Code*</label>
                                    {code.map((digit, index) => (
                                        <div className="col input-loop" key={index}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    id={`code-input-${index}`}
                                                    value={digit}
                                                    onChange={event => handleChange(index, event)}
                                                    onKeyDown={(event) => handleBackspace(index, event)}
                                                    onPaste={handlePaste}
                                                    className="form-control on-select"
                                                    maxLength="1"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                {/*</div>*/}
                            </div>
                            <div className="form-group text-right">
                                <a href="" onClick={(e) => {e.preventDefault(); handleResendVerification()}}
                                   className="forgot-password-link">Resend the verification code</a>
                            </div>

                            <div className="form-group mb-5 text-center">
                                <button
                                    type="submit"
                                    className="col-md-8 btn continue-btn shadow-sm on-select"
                                    disabled={loading}>
                                    {loading ? 'Validating...' : 'Validate'}
                                </button>
                            </div>
                            {/*<div className="text-center">
                                <a href="/login" className="forgot-password-link">Return to Login</a>
                            </div>*/}
                        </form>
                    </div>
                </div>
            </div>
            <Snackbar open={open}
                      autoHideDuration={3000}
                      onClose={handleClose}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <MuiAlert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Verification code resent successfully
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default Validate;
