import React, {useEffect, useMemo, useState} from "react";

import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, InputAdornment, Button
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import configData from "../config/index.json";
import axios from "axios";
import moment from 'moment';
import { useCookies } from 'react-cookie';
import swal from "sweetalert";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "rc-pagination";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import "../assets/css/candidatelist.css"
import {Modal} from "react-bootstrap";
import Footer from "./Footer";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import Skeleton from '@mui/material/Skeleton';

function CandidateList() {
    const [candidates, setCandidates] = useState([]);
    const [current, setCurrent] = useState(1); // Current page
    const [size, setSize] = useState(10); // Page size
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchTerm, setSearchTerm] = useState(""); // Search term
    const [addOpen, setAddOpen] = useState(false);
    const [candidateData, setCandidateData] = useState(null);
    const [cookies] = useCookies(['clientPlatformID']);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isIntervalRefreshing, setIsIntervalRefreshing] = useState(false);
    const [countdown, setCountdown] = useState(20);
    let expressURL = configData.express_url;

    useEffect(() => {
        if (cookies.clientPlatformID) {
            fetchCandidates()
        }
    }, [cookies,current]);

   /* useEffect(() => {
        const interval = setInterval(() => {
            fetchInProgressData(candidates);
        }, 20000);
        return () => clearInterval(interval);
    }, [candidates]);*/

    useEffect(() => {
        // Set the initial countdown value
        setCountdown(20); // Reset countdown to 20 seconds when candidates change

        const interval = setInterval(() => {
            fetchInProgressData(candidates);
            setCountdown(20);
        }, 20000);

        const countdownTimer = setInterval(() => {
            setCountdown((prev) => {
                if (prev <= 1) {
                    return 20;
                }
                return prev - 1;
            });
        }, 1000);

        return () => {
            clearInterval(interval);
            clearInterval(countdownTimer);
        };
    }, [candidates]); // Dependency array for when candidates change

    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));

    const fetchInProgressData = async (fetchedCandidates) => {
        const currentTime = moment();
        const cutoffTime = currentTime.subtract(60, 'minutes');

        const inProgressCandidates = fetchedCandidates.filter(candidate => {
            const initiatedDate = moment(candidate.initiated, 'DD-MM-YYYY hh:mm:ss A');
            return candidate.finalStatus === "In-Progress" && initiatedDate.isAfter(cutoffTime);
        });

        if (inProgressCandidates.length > 0) {
            console.log('In-Progress candidates found:', inProgressCandidates);
            // await fetchCandidates(); // Fetch the updated candidates
            for (const candidate of inProgressCandidates) {
                await handleRefresh(candidate.case_id); // Pass the case_id of each candidate
            }
        } else {
            console.log('No In-Progress candidates left in the last 60 minutes.');
        }
    };

    const handleRefresh = async (caseId) => {
        try {
            setIsRefreshing((prev) => ({ ...prev, [caseId]: true }));
            console.log(`Refreshing data for case ID: ${caseId}`);
            await fetchCandidates();
        } catch (error) {
            swal({
                title: 'Error!',
                text: 'Something went wrong.',
                icon: 'error',
                button: "OK",
                closeOnClickOutside: false,
            });
        } finally {
            setTimeout(() => setIsRefreshing((prev) => ({ ...prev, [caseId]: false })), 1000);
        }
    };

    const downloadFile = async (candidate) => {
        const response = await fetch(candidate.reportUrl);
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${candidate.case_id}_report.pdf`; // or whatever you want to name the file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const fetchCandidates = () => {
        //setLoading(true);
        const loggedPlatformID = cookies.clientPlatformID;
        const payload = {
            platform_id: loggedPlatformID,
            limit: size,  // Page size from state
            offset: (current - 1) * size  // Calculate the offset based on current page
        };

        axios
            .post(expressURL + "api/list/candidatelist", payload)
            .then((response) => {
                console.log("CANDIDATE LISTresponse", response)
                if (response.data.status === "success") {
                    setLoading(false);
                    setCandidates(response.data.data);
                    setTotalRecords(response.data.totalRecords);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error fetching candidate list", error);
                console.error('Error fetching verification history:', error);
                swal({
                    title: 'Error!',
                    text: 'Something went wrong.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            });
    };

    const filteredCandidates = candidates.filter(candidate =>
        candidate.client_id.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // const paginatedCandidates = filteredCandidates.slice((current - 1) * size, current * size);
    // const paginatedCandidates = candidates;
    const paginatedCandidates = searchTerm
        ? filteredCandidates.slice((current - 1) * size, current * size)
        : candidates;
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrent(1); // Reset to the first page when search term changes
    };

// Handle page change
    const PaginationChange = (page) => {
        setCurrent(page);
    };

    const handleAddOpen = (candidate) => {
        // event.preventDefault();
        console.log('candidate',candidate)
        setCandidateData(candidate);
        setAddOpen(true);
    };

    const handleAddClose = () => {
        setCandidateData(null);
        setAddOpen(false);
    };

    const renderReport = (candidate) => {
        if (candidate.reportUrl) {
            return ( <a href="#" onClick={() => { downloadFile(candidate)}}>
                <FileDownloadOutlinedIcon /> Download Report
            </a>)
        } else if (candidate.finalStatus === 'Error') {
            return (
                <a href="" onClick={(e) => {e.preventDefault(); handleAddOpen(candidate)}}>
                    <VisibilityIcon /> View Log
                </a>
            );
        } else if (candidate.finalStatus === 'In-Progress' && candidate.duration) {
            return `Estimated Duration: ${candidate.duration}`
        } else {
            return " _ "
        }
    };

    const PrevNextArrow = (current, type, originalElement) => {
        const handleClick = (e) => {
            e.preventDefault();
        };
        if (type === 'prev') {
            return <button onClick={handleClick}><i className="mdi mdi-chevron-left"/></button>;
        }
        if (type === 'next') {
            return <button onClick={handleClick}><i className="mdi mdi-chevron-right"/></button>;
        }
        return originalElement;
    };

    return (
        <>
            <div className="outer-main">
                <div className="main-container">
                    <div className="col-md-12 top-header py-3">
                        <div className="col-md-12 icon-wrap">
                            <span><img src="/images/iCatch-trial-center.png" className="trial-icatch-icon" alt="Trial Center" /></span>
                        </div>
                        <div className="col-md-12 head-wrap">
                            Trial Center
                        </div>
                    </div>
                    <div className="col-md-12 py-3">
                        <div className=" col-md-12 py-3 inside-content-wrapper">
                            <div className="inner-gray-content-wrap p-3">
                                <div className="">
                                    <div className="col-md-12 p-0">
                                        <div className="row p-0 mr-1 search-calendar-container">
                                            <div className="col d-flex justify-content-start mr-3">
                                                <TextField
                                                    placeholder="Search Candidate Name"
                                                    variant="outlined"
                                                    value={searchTerm}
                                                    onChange={handleSearchChange}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon/>
                                                            </InputAdornment>
                                                        ),
                                                        sx: {
                                                            backgroundColor: '#fff',
                                                            borderRadius: '20px',
                                                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                                        },
                                                    }}
                                                    sx={{ marginBottom: '20px', width: '300px'  }}
                                                />
                                            </div>
                                        </div>

                                        <div className="verification-history-table candidate-list-table">
                                            <TableContainer component={Paper} className="verification-history-table-in">
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className="no-wrap">Case ID</TableCell>
                                                            <TableCell className="no-wrap">Client Code</TableCell>
                                                            <TableCell className="no-wrap">Initiated</TableCell>
                                                            <TableCell className="no-wrap">Status</TableCell>
                                                            <TableCell className="no-wrap"></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {loading ? (
                                                            Array.from(new Array(5)).map((_, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell><Skeleton height={30}/></TableCell>
                                                                    <TableCell><Skeleton height={30}/></TableCell>
                                                                    <TableCell><Skeleton height={30}/></TableCell>
                                                                    <TableCell><Skeleton height={30}/></TableCell>
                                                                    <TableCell><Skeleton height={30}/></TableCell>
                                                                </TableRow>
                                                            ))
                                                        ) : (
                                                            paginatedCandidates.length > 0 ? (
                                                            paginatedCandidates.map((candidate, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell className="no-wrap">{candidate.case_id}</TableCell>
                                                                    <TableCell className="no-wrap">{candidate.client_id}</TableCell>
                                                                    <TableCell className="no-wrap">
                                                                        {candidate.finalStatus === "In-Progress" ? (
                                                                            <Tooltip title={`Verification process initiated at ${candidate.initiated}. 
The estimated completion time is over 20 minutes. 
Please note that actual time may vary based on current system load.`}>
                                                                                <span style={{ cursor: 'pointer' }}>{candidate.initiated}</span>
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <span>{candidate.initiated}</span>
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell className="no-wrap">
                                                                        {/*<span className="success-bg">Success</span>*/}
                                                                        {(candidate.finalStatus === "In-Progress") &&
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <span className="inprogress-bg">In-Progress</span>
                                                                                {/*<BootstrapTooltip title="Refresh">*/}
                                                                                <span>
                                                                                <button
                                                                                    onClick={(e) => {e.preventDefault(); handleRefresh(candidate.case_id)}}
                                                                                    className={`refresh-button ${isRefreshing[candidate.case_id] ? 'rotating' : ''}`}
                                                                                    // className={`refresh-button ${isRefreshing ? 'rotating' : ''}`}
                                                                                    aria-label="Refresh"
                                                                                    style={{ marginLeft: '5px' }} // Optional: adds space between text and icon
                                                                                >
                                                                                    <RefreshIcon/>
                                                                                </button>
                                                                                </span>
                                                                                <span style={{ marginLeft: '10px' }} className="success-description f-10">Auto refresh in {countdown}s.</span>
                                                                                {/*</BootstrapTooltip>*/}
                                                                            </div>
                                                                        }
                                                                        {(candidate.finalStatus === "Match Found") &&
                                                                            <span className="success-bg">Match Found</span>
                                                                        }
                                                                        {(candidate.finalStatus === "Match Not Found") &&
                                                                            <span className="error-bg">Match Not Found</span>
                                                                        }
                                                                        {(candidate.finalStatus === "Investigation Underway") &&
                                                                            <span className="inverstigation-bg">Investigation Underway</span>
                                                                        }
                                                                        {(candidate.finalStatus === "Error") &&
                                                                            <span className="error-error-bg">Error</span>
                                                                        }
                                                                        {(candidate.finalStatus === null) &&
                                                                            <span className="">N/A</span>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell className="no-wrap">
                                                                        {renderReport(candidate)}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan={5} className="text-center f-18 font-bold p-5">
                                                                    <p className="mb-0"><img src="/images/no-data.png" alt="No Data Found" style={{ width: '80px', height: '80px' }} /></p>
                                                                    <p className="mb-0">No record available!</p>
                                                                </td>
                                                            </tr>
                                                            )
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>

                                        <div className="col-md-12 d-flex justify-content-end p-0">
                                            <div className="pagination-container shadow-sm">
                                                <Pagination
                                                    className="pagination-data border-top f-12 pt-3 pb-2"
                                                    showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${totalRecords}`}
                                                    showLessItems
                                                    // onChange={PaginationChange}
                                                    onChange={(page) => {
                                                        setCurrent(page);  // Set the current page
                                                        // fetchCandidates();  // Fetch data for the new page
                                                    }}
                                                    // total={filteredCandidates.length} //for total count
                                                    total={totalRecords}
                                                    current={current}
                                                    pageSize={size}
                                                    showSizeChanger={false}
                                                    itemRender={PrevNextArrow}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
            <Modal show={addOpen} onHide={handleAddClose} backdrop="static">
                {candidateData ? (
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title className="f-20">Error Log for Case ID: {candidateData.case_id}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="col-lg-12 align-items-center justify-content-center mt-2">
                                <Modal.Title className="f-15 ">
                                    <div className="mb-3">
                                        <>
                                            {/*<p><strong>Case ID:</strong> {candidateData.case_id}</p>*/}
                                            <p><strong>Client ID:</strong> <span className="f-12">{candidateData.client_id}</span></p>
                                            <p><strong>Initiated:</strong> <span className="f-12">{candidateData.initiated}</span></p>
                                            <p><strong>Status:</strong> <span className="f-12">{candidateData.finalStatus}</span></p>

                                            {/* Display errors if they exist */}
                                            {candidateData.generalError && (
                                                <p><strong>Error:</strong> <span className="f-12">{candidateData.generalError}</span></p>
                                            )}
                                            {candidateData.faceVerificationError && (
                                                <p><strong>Face Verification Error:</strong> <span className="f-12">{candidateData.faceVerificationError}</span></p>
                                            )}
                                            {candidateData.voiceVerificationError && (
                                                <p><strong>Voice Verification Error:</strong> <span className="f-12">{candidateData.voiceVerificationError}</span></p>
                                            )}
                                        </>

                                    </div>
                                </Modal.Title>
                            </div>
                        </Modal.Body>
                    </>
                ) : (
                    <p>Loading candidate data...</p>
                )}
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleAddClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CandidateList;
