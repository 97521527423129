import React, {useEffect, useMemo, useState} from "react";
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import {DataGrid} from '@mui/x-data-grid';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, InputAdornment, IconButton,
    Avatar, Box, Snackbar
} from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import MuiAlert from '@mui/material/Alert';
import configData from "../config/index.json";
import axios from "axios";
import moment from 'moment';
import { useCookies } from 'react-cookie';
import swal from "sweetalert";
import Pagination from "rc-pagination";
import Footer from "./Footer";
import Skeleton from '@mui/material/Skeleton';

function ManageSubClient() {
    const [open, setOpen] = useState(false);
    const [subClientDetails, setSubClientDetails] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSubClientDetails, setFilteredSubClientDetails] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [cookies] = useCookies(['clientPlatformID']);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [pageSize] = useState(10);
    let expressURL = configData.express_url;

    useEffect(()=>{
        if (cookies.clientPlatformID) {
            getSubClientDetails()
        }
    },[cookies]);

    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));

    async function getSubClientDetails() {
        setLoading(true);
        //let platform = {"platform_id" : 18};
        // const loggedPlatformID = {"platform_id":cookies.clientPlatformID};
        const payload = {
            platform_id: cookies.clientPlatformID,
            limit: pageSize,  // Page size from state
            offset: (currentPage - 1) * pageSize  // Calculate the offset based on the current page
        };
        // console.log("logedemail", loggedPlatformID)
        await axios.post(expressURL + "icatch/subclients/getsubclientdetails", payload)
            .then(response => {
                // console.log("sub client", response);
                if (response.data.status === 200) {
                    setLoading(false);
                    setSubClientDetails(response.data.data.data);
                    setFilteredSubClientDetails(response.data.data.data);
                    setTotalRecords(response.data.data.totalRecords);
                } else {
                    // console.error('Failed to fetch sub client details');
                }
            }).catch(error => {
                // console.error('Error fetching sub client details:', error);
                setLoading(false);
                swal({
                    title: 'Error!',
                    text: 'Something went wrong.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            })
    }

    const handleSearch = (query) => {
        const filtered = subClientDetails.filter((row) =>
            row.client_id.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredSubClientDetails(filtered);
    };

    // Trigger the search whenever the query changes
    const handleSearchQueryChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        handleSearch(query);
    };

    const handleCopy = (client_id) => {
        navigator.clipboard.writeText(client_id).then(() => {
            setOpen(true);
        });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const PrevNextArrow = (current, type, originalElement) => {
        const handleClick = (e) => {
            e.preventDefault();
        };
        if (type === 'prev') {
            return <button onClick={handleClick}><i className="mdi mdi-chevron-left"/></button>;
        }
        if (type === 'next') {
            return <button onClick={handleClick}><i className="mdi mdi-chevron-right"/></button>;
        }
        return originalElement;
    };

    // const paginatedData = filteredSubClientDetails.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    // const paginatedData = subClientDetails;  // Data already paginated by backend
    const paginatedData = searchQuery ? filteredSubClientDetails : subClientDetails;

    return (
        <>
            {/*<div className="main-content d-flex justify-content-center align-items-center flex-grow-1">*/}
            <div className="outer-main">
                <div className="main-container">
                    <div className="col-md-12 top-header py-3">
                        <div className="col-md-12 icon-wrap">
                            <VpnKeyOutlinedIcon className="head-icon"/>
                        </div>
                        <div className="col-md-12 head-wrap">
                            Manage Sub Client
                        </div>
                    </div>
                    <div className="col-md-12 py-3">
                        <div className=" col-md-12 py-3 inside-content-wrapper">
                            <div className="inner-gray-content-wrap p-3">
                                <div className="inner-inner-content pb-0 pt-2">
                                    {/* Search Bar */}
                                    <TextField
                                        placeholder="Search API Key"
                                        variant="outlined"
                                        value={searchQuery}
                                        onChange={handleSearchQueryChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon/>
                                                </InputAdornment>
                                            ),
                                            sx: {
                                                backgroundColor: '#fff',
                                                borderRadius: '20px',
                                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                            },
                                        }}
                                        sx={{ marginBottom: '20px', width: '300px'  }}
                                    />

                                    {/* Table */}
                                    <TableContainer className="manage-subclient-table-container" component={Box}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className="no-wrap">Logo</TableCell>
                                                    <TableCell className="no-wrap">API Key</TableCell>
                                                    <TableCell className="no-wrap">Client Name</TableCell>
                                                    <TableCell className="no-wrap">Scan Count</TableCell>
                                                    <TableCell className="no-wrap">ID Count</TableCell>
                                                    <TableCell className="no-wrap">Valid From</TableCell>
                                                    <TableCell className="no-wrap">Valid Till</TableCell>
                                                    <TableCell className="no-wrap">Type
                                                        {/*<BootstrapTooltip title={<span>S - Segmented Mode<br />C - Comprehensive Mode</span>}>
                                                            <InfoOutlinedIcon fontSize="small" />
                                                        </BootstrapTooltip>*/}
                                                    </TableCell>
                                                    {/*<TableCell className="no-wrap"></TableCell>*/}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {loading ? (
                                                        Array.from(new Array(5)).map((_, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell><Skeleton height={30}/></TableCell>
                                                                <TableCell><Skeleton height={30}/></TableCell>
                                                                <TableCell><Skeleton height={30}/></TableCell>
                                                                <TableCell><Skeleton height={30}/></TableCell>
                                                                <TableCell><Skeleton height={30}/></TableCell>
                                                                <TableCell><Skeleton height={30}/></TableCell>
                                                                <TableCell><Skeleton height={30}/></TableCell>
                                                                <TableCell><Skeleton height={30}/></TableCell>
                                                            </TableRow>
                                                        ))
                                                    ) : (
                                                    paginatedData.length > 0 ? (
                                                    paginatedData.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell className="no-wrap">
                                                            <Avatar src={row.logo} alt="logo" sx={{ width: 30, height: 30 }} />
                                                        </TableCell>
                                                        <TableCell className="no-wrap d-flex align-items-center justify-content-between">
                                                            {row.client_id}
                                                            <BootstrapTooltip title="Copy API Key">
                                                                <IconButton onClick={() => handleCopy(row.client_id)}>
                                                                    <ContentCopyOutlinedIcon fontSize="small" />
                                                                </IconButton>
                                                            </BootstrapTooltip>
                                                        </TableCell>
                                                        <TableCell className="no-wrap">{row.client_name}</TableCell>
                                                        {/*<TableCell className="no-wrap">*/}
                                                        {/*    {row.client_name ? row.client_name : "root"}*/}
                                                        {/*</TableCell>*/}
                                                        <TableCell className="no-wrap">{row.scan_licenses}</TableCell>
                                                        <TableCell className="no-wrap">{row.id_licenses}</TableCell>
                                                        <TableCell className="no-wrap">{moment.utc(row['valid_from']).format('DD-MM-YYYY')}</TableCell>
                                                        <TableCell className="no-wrap">{moment.utc(row['valid_till']).format('DD-MM-YYYY')}</TableCell>
                                                        {/*<TableCell className="no-wrap">{row.voice_type}</TableCell>*/}
                                                        <TableCell className="no-wrap">
                                                            {row.voice_type === 'C' ? 'Comprehensive' : row.voice_type === 'S' ? 'Segmented' : ''}
                                                        </TableCell>
                                                        {/*<TableCell className="no-wrap">
                                                            <BootstrapTooltip title="Delete Sub Client">
                                                                <IconButton>
                                                                    <span className="material-symbols-outlined">delete</span>
                                                                </IconButton>
                                                            </BootstrapTooltip>
                                                        </TableCell>*/}
                                                    </TableRow>
                                                ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={8} className="text-center f-18 font-bold">
                                                            <p className="mb-0"><img src="/images/no-data.png" alt="No Data Found" style={{ width: '100px', height: '100px' }} /></p>
                                                            <p className="mb-0">No record available!</p>
                                                        </td>
                                                    </tr>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                <div className="col-md-12 d-flex justify-content-end p-0">
                                    <div className="pagination-container shadow-sm">
                                        <Pagination className="pagination-data border-top f-12 pt-3 pb-2"
                                                    current={currentPage}
                                                    pageSize={pageSize}
                                                    // total={filteredSubClientDetails.length}
                                                    total={totalRecords}
                                                    // onChange={(page) => setCurrentPage(page)}
                                                    onChange={(page) => {
                                                        setCurrentPage(page);  // Update the current page
                                                        getSubClientDetails();  // Fetch the data for the new page
                                                    }}
                                                    showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${totalRecords}`}
                                                    showLessItems
                                                    showSizeChanger={false}
                                                    itemRender={PrevNextArrow}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
            {/*</div>*/}
            {/* Snackbar*/}
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <MuiAlert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    API Key Copied!
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default ManageSubClient;
