import React, {useEffect, useMemo, useState} from "react";
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, InputAdornment, IconButton,
    Avatar, Box, Snackbar, Button
} from '@mui/material';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import MuiAlert from '@mui/material/Alert';
import configData from "../config/index.json";
import axios from "axios";
import { useCookies } from 'react-cookie';
import swal from "sweetalert";
import {Modal} from "react-bootstrap";
import { Checkbox, FormControlLabel } from '@mui/material';
import Pagination from "rc-pagination";
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import Footer from "./Footer";
import Skeleton from '@mui/material/Skeleton';

function LoadbalancerConfiguration() {
    const [open, setOpen] = useState(false);
    const [subClientDetails, setSubClientDetails] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSubClientDetails, setFilteredSubClientDetails] = useState(subClientDetails);
    const [addOpen, setAddOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [editedRow, setEditedRow] = useState(null);

    const [instanceEndpoint, setInstanceEndpoint] = useState("");
    const [instanceStatus, setInstanceStatus] = useState("");
    const [status, setStatus] = useState("");
    const initialQueue = { queue_url: "", queue_type: [] };
    const [queues, setQueues] = useState([initialQueue]);  // Array of queues
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [isQueueAdded, setIsQueueAdded] = useState(false); // Tracks if a new queue is added
    const [fieldErrors, setFieldErrors] = useState({
        instanceStatus: "",
        instanceEndpoint: "",
        status: ""
    });
    const [loading, setLoading] = useState(true);


    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const [cookies] = useCookies(['clientPlatformID', 'clientClientID']);
    let expressURL = configData.express_url;

    useEffect(()=>{
        if (cookies.clientPlatformID) {
            getloadbalancerConfig()
        }
    },[cookies,currentPage]);

    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));

    const formatDateTime = (timestamp) => {
        const date = new Date(timestamp);

        // Extract values
        const day = date.getDate();
        const month = date.getMonth() + 1; // getMonth is zero-based
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        // Format as "MM-DD-YYYY HH:mm"
        return `${month}-${day}-${year} ${hours}:${minutes}`;
    };

    async function getloadbalancerConfig() {
        setLoading(true);
        const loggedPlatformID = {"platform_id":cookies.clientPlatformID,
            limit: pageSize,
            offset: (currentPage - 1) * pageSize,

        };
        await axios.post(expressURL + "api/loadbalancer/loadbalancerconfig", loggedPlatformID)
            .then(response => {
                console.log("getloadbalancerConfig response", response.data);
                if (response.status === 200) {
                    setLoading(false);
                    setSubClientDetails(response.data.data);
                    setFilteredSubClientDetails(response.data);
                    setTotalRecords(response.data.totalRecords);
                } else {
                    setLoading(false);
                    swal({
                        title: 'Error!',
                        text: 'Failed to fetch sub client details.',
                        icon: 'error',
                        button: "OK",
                        closeOnClickOutside: false,
                    });
                }
            }).catch(error => {
                setLoading(false);
                swal({
                    title: 'Error!',
                    text: 'Something went wrong.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            })
    }

    /* const handleSearch = (query) => {
         const filtered = subClientDetails.filter((row) =>
             row.deployment_id.toLowerCase().includes(query.toLowerCase())
         );
         setFilteredSubClientDetails(filtered);
         setCurrentPage(1);
     };
     // Handle search
     // const handleSearch = (query) => {
     //     const lowerCaseQuery = query.toLowerCase();
     //     const filtered = subClientDetails.filter((row) =>
     //         row.deployment_id.toLowerCase().includes(lowerCaseQuery)
     //     );
     //
     //     setFilteredSubClientDetails(filtered);
     //     setTotalRecords(filtered.length); // Update total records based on filtered results
     //     setCurrentPage(1); // Reset to first page when searching
     // };

     // Trigger the search whenever the query changes
     const handleSearchQueryChange = (e) => {
         const query = e.target.value;
         setSearchQuery(query);
         handleSearch(query);
     };

     const handleCopy = (client_id) => {
         navigator.clipboard.writeText(client_id).then(() => {
             setOpen(true);
         });
     };

     const handleClose = (event, reason) => {
         if (reason === 'clickaway') {
             return;
         }
         setOpen(false);
     };*/

    /*const handleEditOpen = (row) => {
        console.log("clicked", row);
        setEditedRow(row);
        setEditOpen(true);
    };*/

    const handleEditOpen = (row) => {
        setEditedRow(row);
        // setQueues(row.queues.map((queue) => ({ ...queue })));
        setQueues(row.queues.map(queue => ({ ...queue })));
        setEditOpen(true);
    };

    /*    const handleEditClose = () => setEditOpen(false);*/
    const handleEditClose = () => {
        setEditOpen(false);
        setEditedRow(null);
        setQueues([initialQueue]);
    };

    const handleAddOpen = () => {
        console.log("clicked");
        setAddOpen(true);
    };

    const handleAddClose = () => setAddOpen(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedRow({ ...editedRow, [name]: value });
    };

    // const handleQueueChange = (index, field, value) => {
    //     const updatedQueues = [...queues];
    //     console.log('value',value);
    //     if (field === "queue_url") {
    //         updatedQueues[index].queue_url = value;
    //     } else if (field === "queue_type") {
    //         console.log('updatedQueues',updatedQueues[index]);
    //         if (updatedQueues[index].queue_type.includes(value)) {
    //             updatedQueues[index].queue_type = updatedQueues[index].queue_type.filter((type) => type !== value);
    //         } else {
    //             updatedQueues[index].queue_type.push(value);
    //         }
    //     }
    //     setQueues(updatedQueues);
    // };

    // const handleQueueChange = (index, field, value) => {
    //     const updatedQueues = [...queues];
    //
    //     if (field === "queue_url") {
    //         updatedQueues[index].queue_url = value;
    //     } else if (field === "queue_type") {
    //         if (typeof updatedQueues[index].queue_type !== "string") {
    //             updatedQueues[index].queue_type = "";
    //         }
    //         let queueTypes = updatedQueues[index].queue_type.split(',').map(type => type.trim()).filter(type => type);
    //         if (queueTypes.includes(value)) {
    //             queueTypes = queueTypes.filter((type) => type !== value);
    //         } else {
    //             queueTypes.push(value);
    //         }
    //         updatedQueues[index].queue_type = queueTypes.join(',');
    //     }
    //
    //     setQueues(updatedQueues);
    // };
    const handleQueueChange = async (index, field, value) => {
        const updatedQueues = [...queues];

        if (field === "queue_url") {
            updatedQueues[index].queue_url = value;
            try {
                const response = await axios.post(expressURL + "api/sqs/validatesqsurl", { url: value });
                console.log("SQS URL Validation Response:", response.data);

                if (response.status === 200 && response.data.status === 'success') {
                    console.log("Valid SQS URL");
                    // Clear any previous error message
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [`queue_url_${index}`]: "", // Clear error
                    }));
                } else {
                    // Handle invalid SQS URL
                    console.log("Invalid SQS URL");
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [`queue_url_${index}`]: 'Invalid SQS URL',
                    }));
                    // swal({
                    //     title: 'Error!',
                    //     text: 'Invalid SQS URL.',
                    //     icon: 'error',
                    //     button: "OK",
                    // });
                }
            } catch (error) {
                console.error("Error during SQS URL validation:", error);
                // Display error message
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`queue_url_${index}`]: 'Invalid SQS URL',
                }));
                // swal({
                //     title: 'Error!',
                //     text: 'Failed to validate SQS URL. Please try again.',
                //     icon: 'error',
                //     button: "OK",
                // });
            }
        } else if (field === "queue_type") {
            if (typeof updatedQueues[index].queue_type !== "string") {
                updatedQueues[index].queue_type = "";
            }
            let queueTypes = updatedQueues[index].queue_type.split(',')
                .map(type => type.trim())
                .filter(type => type);

            if (queueTypes.includes(value)) {
                queueTypes = queueTypes.filter((type) => type !== value);
            } else {
                queueTypes.push(value);
            }

            updatedQueues[index].queue_type = queueTypes.join(',');
        }

        // Update the queue state
        setQueues(updatedQueues);
        validateForm();
    };
    // 3. New validation function to check the form validity
    const validateForm = () => {
        // Check if all queue URLs are valid
        const allUrlsValid = queues.every((queue, index) => {
            const urlError = errors[`queue_url_${index}`];
            // return !urlError; // Returns true if there's no error
            return queue.queue_url && !urlError;
        });

        // Check if all queues have at least one queue type selected
        const allTypesValid = queues.every(queue => queue.queue_type.length > 0);

        // Enable the button if both URL and type validations pass
        setIsFormValid(allUrlsValid && allTypesValid);
    };
    useEffect(() => {
        validateForm();
    }, [queues, errors]);
    const validateNewQueue = () => {
        // Check if there is a new queue that was added and not yet validated
        // const newQueueIndex = queues.length - 1; // Assuming new queue is always added at the end
        //
        // // Only validate if a new queue was added
        // if (isQueueAdded) {
        //     const queue = queues[newQueueIndex];
        //     const urlError = errors[`queue_url_${newQueueIndex}`];
        //
        //     // Check if the queue URL and queue type are valid
        //     const isUrlValid = queue.queue_url && !urlError;
        //     const isTypeValid = queue.queue_type.length > 0;
        //
        //     // If both URL and type are valid, allow saving
        //     if (isUrlValid && isTypeValid) {
        //         setIsFormValid(true);
        //         setIsQueueAdded(false); // Queue has been validated
        //     } else {
        //         setIsFormValid(false); // Disable buttons until valid
        //     }
        // }
        // Modified logic: Ensure new queue is fully validated before marking it valid
        const newQueueIndex = queues.length - 1;  // Assuming new queue is last
        const queue = queues[newQueueIndex];
        const urlError = errors[`queue_url_${newQueueIndex}`];

        const isUrlValid = queue.queue_url && !urlError;  // Check URL validation
        const isTypeValid = queue.queue_type.length > 0;  // Check if queue type is selected

        // Update form validity based on new queue's validation state
        setIsFormValid(isUrlValid && isTypeValid);
    };


    /*const handleQueueChange = (index, field, value) => {
        setQueues((prevQueues) => {
            const updatedQueues = [...prevQueues];
            if (field === 'queue_type') {
                // Check if the type already exists in the array
                if (updatedQueues[index].queue_type.includes(value)) {
                    // If it exists, remove it
                    updatedQueues[index].queue_type = updatedQueues[index].queue_type.filter((type) => type !== value);
                } else {
                    // If it doesn't exist, add it
                    updatedQueues[index].queue_type.push(value);
                }
            } else {
                updatedQueues[index][field] = value;
            }
            return updatedQueues;
        });
    };*/


// Add a new queue
    const addQueue = () => {
        setQueues([...queues, { queue_url: "", queue_type: [] }]);
        validateForm();
    };
    const addNewQueue = () =>{
        // Add the new queue to the list
        setQueues([...queues, { queue_url: '', queue_type: [] }]);

        // Mark that a new queue was added and buttons should be disabled
        setIsQueueAdded(true);
        setIsFormValid(false); // Disable buttons until validation
    }

// Remove a queue
    const removeQueue = (index) => {
        const newQueues = queues.filter((_, i) => i !== index);
        setQueues(newQueues);
        setIsFormValid(true);
    };

// Handle save operation
//     const handleSave = async (e) => {
//         e.preventDefault();
//
//         const isValid = queues.every(queue => queue.queue_type.length > 0);
//         if (!isValid) {
//             swal({
//                 title: 'Validation Error!',
//                 text: 'Please select at least one queue type for each queue.',
//                 icon: 'warning',
//                 button: "OK",
//             });
//             return;
//         }
//
//         const payload = {
//             platform_id: cookies.clientPlatformID,
//             client_id: cookies.clientClientID,
//             instance_endpoint: instanceEndpoint,
//             instance_status: instanceStatus,
//             status: status,
//             queues: queues,
//         };
//
//         try {
//             const response = await axios.post(expressURL + "api/loadbalancer/createloadbalancerconfig", payload);
//             if (response.status === 200) {
//                 swal({
//                     title: 'Success!',
//                     text: 'Loadbalancer configuration added successfully.',
//                     icon: 'success',
//                     button: "OK",
//                 }).then(() => {
//                     // This will reload the page
//                     window.location.reload();
//                 });
//                 handleAddClose();
//             } else {
//                 throw new Error('Failed to save');
//             }
//         } catch (error) {
//             swal({
//                 title: 'Error!',
//                 text: 'Something went wrong. Please try again.',
//                 icon: 'error',
//                 button: "OK",
//             });
//         }
//     };
//     const handleSave = async (e) => {
//         e.preventDefault();
//
//         // Validate all SQS URLs before proceeding
//         let allValid = true;
//         const updatedErrors = {};
//
//         for (let i = 0; i < queues.length; i++) {
//             const queue = queues[i];
//
//             // Validate queue URL
//             try {
//                 const response = await axios.post(expressURL + "api/sqs/validatesqsurl", { url: queue.queue_url });
//                 if (response.status !== 200 || response.data.status !== 'success') {
//                     // Invalid SQS URL, set error and mark as invalid
//                     updatedErrors[`queue_url_${i}`] = 'Invalid SQS URL';
//                     allValid = false;
//                 }
//             } catch (error) {
//                 updatedErrors[`queue_url_${i}`] = 'Invalid SQS URL';
//                 allValid = false;
//             }
//         }
//
//         // Update the errors state
//         setErrors((prevErrors) => ({
//             ...prevErrors,
//             ...updatedErrors
//         }));
//
//         // If any queue URL is invalid, show a validation error and don't proceed
//         if (!allValid) {
//             swal({
//                 title: 'Validation Error!',
//                 text: 'One or more queue URLs are invalid. Please fix them before saving.',
//                 icon: 'error',
//                 button: "OK",
//             });
//             return;
//         }
//
//         // Validate other fields (queue_type length)
//         const isValid = queues.every(queue => queue.queue_type.length > 0);
//         if (!isValid) {
//             swal({
//                 title: 'Validation Error!',
//                 text: 'Please select at least one queue type for each queue.',
//                 icon: 'warning',
//                 button: "OK",
//             });
//             return;
//         }
//
//         // If everything is valid, proceed with saving
//         const payload = {
//             platform_id: cookies.clientPlatformID,
//             client_id: cookies.clientClientID,
//             instance_endpoint: instanceEndpoint,
//             instance_status: instanceStatus,
//             status: status,
//             queues: queues,
//         };
//
//         try {
//             const response = await axios.post(expressURL + "api/loadbalancer/createloadbalancerconfig", payload);
//             if (response.status === 200) {
//                 swal({
//                     title: 'Success!',
//                     text: 'Load balancer configuration added successfully.',
//                     icon: 'success',
//                     button: "OK",
//                 }).then(() => {
//                     // This will reload the page
//                     window.location.reload();
//                 });
//                 handleAddClose();
//             } else {
//                 throw new Error('Failed to save');
//             }
//         } catch (error) {
//             swal({
//                 title: 'Error!',
//                 text: 'Something went wrong. Please try again.',
//                 icon: 'error',
//                 button: "OK",
//             });
//         }
//     };
    // 4. Update the handleSave function
    const handleSave = async (e) => {
        e.preventDefault();
        // Reset field errors
        setFieldErrors({
            instanceStatus: "",
            instanceEndpoint: "",
            status: ""
        });

        // Validate fields and update errors
        let hasError = false;
        const errors = {};

        if (!instanceStatus) {
            errors.instanceStatus = "Instance status is required";
            hasError = true;
        }
        if (!instanceEndpoint) {
            errors.instanceEndpoint = "Instance endpoint is required";
            hasError = true;
        }
        if (!status) {
            errors.status = "Status is required";
            hasError = true;
        }

        // Update state with errors
        setFieldErrors(errors);

        // Call validateForm to check all URLs and types
        validateNewQueue(); // Validate URLs and queue types
        // Check if form is valid
        if (hasError || !isFormValid) {
            // swal({
            //     title: 'Validation Error!',
            //     text: 'One or more queue URLs are invalid or queue types are not selected. Please fix them before saving.',
            //     icon: 'error',
            //     button: "OK",
            //     closeOnClickOutside: false,
            // });
            return;
        }

        // Proceed with saving
        const payload = {
            platform_id: cookies.clientPlatformID,
            client_id: cookies.clientClientID,
            instance_endpoint: instanceEndpoint,
            instance_status: instanceStatus,
            status: status,
            queues: queues,
        };

        try {
            const response = await axios.post(expressURL + "api/loadbalancer/createloadbalancerconfig", payload);
            if (response.status === 200) {
                swal({
                    title: 'Success!',
                    text: 'Load balancer configuration added successfully.',
                    icon: 'success',
                    button: "OK",
                    closeOnClickOutside: false,
                }).then(() => {
                    window.location.reload();
                });
                handleAddClose();
            } else {
                throw new Error('Failed to save');
            }
        } catch (error) {
            swal({
                title: 'Error!',
                text: 'Something went wrong. Please try again.',
                icon: 'error',
                button: "OK",
                closeOnClickOutside: false,
            });
        }
    };



    //
    // const handleSaveEdit = async () => {
    //     // Validate other inputs using validateEdit() (if it exists)
    //     const isValid = validateEdit();
    //
    //     // Call validateForm to check all URLs and types
    //     validateForm(); // This will check if URLs are valid and queue types are selected
    //
    //     // Step 1: If form is invalid, show an error and block submission
    //     if (!isFormValid || !isValid) {
    //         swal({
    //             title: 'Validation Error!',
    //             text: 'One or more queue URLs are invalid or queue types are not selected. Please correct them before saving.',
    //             icon: 'error',
    //             button: "OK",
    //         });
    //         return; // Block the save if form is invalid
    //     }
    //
    //     // Step 2: Revalidate all queue URLs before saving (if necessary)
    //     let invalidUrls = false;
    //     const updatedErrors = { ...errors }; // Copy the current errors state
    //
    //     for (let index = 0; index < queues.length; index++) {
    //         const queueUrl = queues[index].queue_url;
    //
    //         if (queueUrl) { // Only validate non-empty URLs
    //             try {
    //                 const response = await axios.post(expressURL + "api/sqs/validatesqsurl", { url: queueUrl });
    //                 if (response.status === 200 && response.data.status === 'success') {
    //                     updatedErrors[`queue_url_${index}`] = ""; // Clear the error if valid
    //                 } else {
    //                     updatedErrors[`queue_url_${index}`] = 'Invalid SQS URL'; // Set error if invalid
    //                     invalidUrls = true; // Mark URL as invalid
    //                 }
    //             } catch (error) {
    //                 updatedErrors[`queue_url_${index}`] = 'Invalid SQS URL'; // Set error on failure
    //                 invalidUrls = true; // Mark URL as invalid
    //             }
    //         }
    //     }
    //
    //     setErrors(updatedErrors); // Update the error state
    //
    //     // Step 3: If any invalid URLs are detected, show an error and block submission
    //     if (invalidUrls) {
    //         swal({
    //             title: 'Validation Error!',
    //             text: 'One or more queue URLs are invalid. Please correct them before saving.',
    //             icon: 'error',
    //             button: "OK",
    //         });
    //         return; // Block the save if there are invalid URLs
    //     }
    //
    //     // Step 4: If all URLs and other inputs are valid, proceed with saving
    //     const updatedConfig = {
    //         ...editedRow,
    //         queues: queues,
    //         deployment_id: editedRow.deployment_id,
    //     };
    //
    //     try {
    //         const response = await axios.put(expressURL + "api/loadbalancer/updateloadbalancerconfig", updatedConfig); // Adjust endpoint
    //
    //         if (response.status === 200) {
    //             handleEditClose();
    //             swal({
    //                 title: 'Success!',
    //                 text: 'Load balancer configuration updated successfully.',
    //                 icon: 'success',
    //                 button: "OK",
    //                 closeOnClickOutside: false,
    //             }).then(() => {
    //                 // This will reload the page
    //                 window.location.reload();
    //             });
    //         } else {
    //             throw new Error('Failed to update');
    //         }
    //     } catch (error) {
    //         console.error("Error updating configuration:", error);
    //         swal({
    //             title: 'Error!',
    //             text: 'Something went wrong. Please try again.',
    //             icon: 'error',
    //             button: "OK",
    //             closeOnClickOutside: false,
    //         });
    //     }
    // };
    const handleSaveEdit = async (e) => {
        e.preventDefault();
        // Validate other inputs using validateEdit() (if it exists)
        const isValid = validateEdit();

        // Call validateForm to check all URLs and types
        validateForm(); // This will check if URLs are valid and queue types are selected

        // Step 1: If form is invalid, show an error and block submission
        if (!isFormValid || !isValid) {
            swal({
                title: 'Validation Error!',
                text: 'One or more queue URLs are invalid or queue types are not selected. Please correct them before saving.',
                icon: 'error',
                button: "OK",
            });
            return; // Block the save if form is invalid
        }

        // Step 2: If all validations pass, proceed with saving
        const updatedConfig = {
            ...editedRow,
            queues: queues,  // Updated queues after changes
            deployment_id: editedRow.deployment_id,  // Maintain the existing deployment ID
        };

        try {
            // Call the API for updating the load balancer configuration
            const response = await axios.put(expressURL + "api/loadbalancer/updateloadbalancerconfig", updatedConfig);

            if (response.status === 200) {
                // Close the edit modal and show success message
                handleEditClose();
                swal({
                    title: 'Success!',
                    text: 'Load balancer configuration updated successfully.',
                    icon: 'success',
                    button: "OK",
                    closeOnClickOutside: false,
                }).then(() => {
                    // Reload the page to reflect changes
                    window.location.reload();
                });
            } else {
                throw new Error('Failed to update');
            }
        } catch (error) {
            console.error("Error updating configuration:", error);
            swal({
                title: 'Error!',
                text: 'Something went wrong. Please try again.',
                icon: 'error',
                button: "OK",
                closeOnClickOutside: false,
            });
        }
    };



    const validateEdit = () => {
        const newErrors = {};
        if (!editedRow?.instance_endpoint) {
            newErrors.instance_endpoint = "Instance Endpoint is required.";
        }
        if (!editedRow?.instance_status) {
            newErrors.instance_status = "Instance Status is required.";
        }
        if (!editedRow?.status) {
            newErrors.status = "Status is required.";
        }
        queues.forEach((queue, index) => {
            if (!queue.queue_url) {
                newErrors[`queue_url_${index}`] = `Queue URL ${index + 1} is required.`;
            }
            if (queue.queue_type.length === 0) {
                newErrors[`queue_type_${index}`] = `At least one Queue Type must be selected for Queue ${index + 1}.`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleEditModalOpen = () => {
        // Reset the form validity and queue tracking when the modal opens
        setIsFormValid(true);  // Enable the buttons by default
        setIsQueueAdded(false); // No queue is added initially
        // You can reset any other states if needed
        setEditOpen(true); // This will open the modal
    };

    const PrevNextArrow = (current, type, originalElement) => {
        const handleClick = (e) => {
            e.preventDefault();
        };
        if (type === 'prev') {
            return <button onClick={handleClick}><i className="mdi mdi-chevron-left"/></button>;
        }
        if (type === 'next') {
            return <button onClick={handleClick}><i className="mdi mdi-chevron-right"/></button>;
        }
        return originalElement;
    };

    // const paginatedData = filteredSubClientDetails.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    const paginatedData = subClientDetails;
    return (
        <>
            {/* Modal for Editing */}
            <Modal show={editOpen} onHide={handleEditClose} onShow={handleEditModalOpen} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Configuration</Modal.Title>
                </Modal.Header>
                <form>
                    <Modal.Body>
                        <div>
                            <div className="col-md-12 mb-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <TextField
                                            fullWidth
                                            label="Deployment ID"
                                            name="deployment_id"
                                            value={editedRow?.deployment_id || ""}
                                            variant="standard"
                                            className="mb-3 profile-input config-input"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            fullWidth
                                            label="Instance Endpoint"
                                            name="instance_endpoint"
                                            value={editedRow?.instance_endpoint || ""}
                                            onChange={handleChange}
                                            variant="standard"
                                            className="profile-input config-input"
                                        />
                                        {errors.instance_endpoint && <div className="error-message c-red f-12">{errors.instance_endpoint}</div>}
                                    </div>
                                </div>
                            </div>

                            {/*<div className="col-md-12 mb-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <TextField
                                            fullWidth
                                            label="Instance Status"
                                            name="instance_status"
                                            value={editedRow?.instance_status || ""}
                                            onChange={handleChange}
                                            variant="standard"
                                            className="mb-3 profile-input config-input"
                                        />
                                        {errors.instance_status && <div className="error-message c-red f-12">{errors.instance_status}</div>}
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            fullWidth
                                            label="Status"
                                            name="status"
                                            value={editedRow?.status || ""}
                                            onChange={handleChange}
                                            variant="standard"
                                            className="profile-input config-input"
                                        />
                                        {errors.status && <div className="error-message c-red f-12">{errors.status}</div>}
                                    </div>
                                </div>
                            </div>*/}
                            <div className="col-md-12 mb-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <FormControl fullWidth variant="standard" className="mb-3 profile-input config-input" error={!!errors.instance_status}>
                                            <InputLabel id="instance-status-label">Instance Status</InputLabel>
                                            <Select
                                                labelId="instance-status-label"
                                                name="instance_status"
                                                value={editedRow?.instance_status || ""}
                                                onChange={handleChange}>
                                                <MenuItem value="I">I</MenuItem>
                                                <MenuItem value="A">A</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {errors.instance_status && <div className="error-message c-red f-12">{errors.instance_status}</div>}
                                    </div>

                                    <div className="col-md-6">
                                        <FormControl fullWidth variant="standard" className="profile-input config-input" error={!!errors.status}>
                                            <InputLabel id="status-label">Status</InputLabel>
                                            <Select
                                                labelId="status-label"
                                                name="status"
                                                value={editedRow?.status || ""}
                                                onChange={handleChange}>
                                                <MenuItem value="I">I</MenuItem>
                                                <MenuItem value="A">A</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {errors.status && <div className="error-message c-red f-12">{errors.status}</div>}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mb-3">
                                {queues.map((queue, index) => (
                                    <div key={index} className="mb-3">
                                        <div className="col-md12 mb-3">
                                            <TextField
                                                fullWidth
                                                label={`Queue URL ${index + 1}`}
                                                name={`queues[${index}].queue_url`}
                                                value={queue.queue_url || ""}
                                                onChange={(e) => handleQueueChange(index, 'queue_url', e.target.value)}
                                                variant="standard"
                                                required
                                                className="profile-input config-input"
                                            />
                                            {errors[`queue_url_${index}`] && <div className="error-message c-red f-12">{errors[`queue_url_${index}`]}</div>}
                                        </div>
                                        <div className="col-md-12 queue-type p-0">
                                            <h6 className="queue-lable">Queue Type</h6>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={queue.queue_type.includes("process")}
                                                        onChange={() => handleQueueChange(index, "queue_type", "process")}
                                                        sx={{
                                                            color: queue.queue_type === 'process' ? 'rgba(0, 0, 0, 0.54)' : 'default',
                                                            '&.Mui-checked': {
                                                                color: 'rgba(0, 0, 0, 0.54)',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Process"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={queue.queue_type.includes("validate")}
                                                        onChange={() => handleQueueChange(index, "queue_type", "validate")}
                                                        sx={{
                                                            color: queue.queue_type === 'validate' ? 'rgba(0, 0, 0, 0.54)' : 'default',
                                                            '&.Mui-checked': {
                                                                color: 'rgba(0, 0, 0, 0.54)',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Validate"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={queue.queue_type.includes("report")}
                                                        onChange={() => handleQueueChange(index, "queue_type", "report")}
                                                        sx={{
                                                            color: queue.queue_type === 'report' ? 'rgba(0, 0, 0, 0.54)' : 'default',
                                                            '&.Mui-checked': {
                                                                color: 'rgba(0, 0, 0, 0.54)',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Report"
                                            />
                                            {errors[`queue_type_${index}`] && <div className="error-message c-red f-12">{errors[`queue_type_${index}`]}</div>}
                                        </div>
                                        {/*<div className="col-md-12 p-0 queue-type">
                                            <h6 className="queue-lable">Queue Type</h6>
                                            {["Process", "Validate", "Report"].map((type) => (
                                                <FormControlLabel
                                                    key={type}
                                                    control={
                                                        <Checkbox
                                                            checked={queue.queue_type.includes(type)}
                                                            onChange={() => handleQueueChange(index, 'queue_type', type)}
                                                        />
                                                    }
                                                    label={type}
                                                />
                                            ))}
                                            {errors[`queue_type_${index}`] && <div className="error-message c-red f-12">{errors[`queue_type_${index}`]}</div>}
                                        </div>*/}
                                        <div className="float-right">
                                            <button variant="outlined" color="error" className="pt-0 pb-0 btn on-select f-12 px-0" onClick={() => removeQueue(index)}><RemoveOutlinedIcon/> Remove</button>
                                        </div>
                                    </div>
                                ))}
                                <div className="col-md-12 p-0 d-flex justify-content-end">
                                    <button variant="outlined"
                                            className="pt-0 pb-0 mt-4 btn c-pink p-0 on-select f-12"
                                            onClick={addNewQueue}
                                            disabled={!isFormValid} ><AddOutlinedIcon/> Add Queue</button>
                                </div>
                            </div>

                            {/*<div className="col-md-12 mb-3">
                                {queues.map((queue, index) => (
                                    <div key={index} className="mb-3">
                                        <div className="col-md12 mb-3">
                                            <TextField
                                                fullWidth
                                                label={`Queue URL ${index + 1}`}
                                                name={`queues[${index}].queue_url`}
                                                value={queue.queue_url || ""}
                                                onChange={(e) => handleQueueChange(index, 'queue_url', e.target.value)}
                                                variant="standard"
                                                required
                                            />
                                            {errors[`queue_url_${index}`] && <div className="error-message c-red f-12">{errors[`queue_url_${index}`]}</div>}
                                        </div>
                                        <div className="col-md-12 p-0">
                                            <h6 className="queue-lable">Queue Type</h6>
                                            {["Process", "Validate", "Report"].map((type) => (
                                                <FormControlLabel
                                                    key={type}
                                                    control={
                                                        <Checkbox
                                                            checked={queue.queue_type.includes(type)}
                                                            onChange={() => handleQueueChange(index, 'queue_type', type)}
                                                        />
                                                    }
                                                    label={type}
                                                />
                                            ))}
                                            {errors[`queue_type_${index}`] && <div className="error-message c-red f-12">{errors[`queue_type_${index}`]}</div>}
                                        </div>
                                        <Button variant="outlined" color="error" onClick={() => removeQueue(index)}>Remove Queue</Button>
                                    </div>
                                ))}
                                <Button variant="outlined" onClick={addQueue}>Add Queue</Button>
                            </div>*/}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-cancel shadow-sm" variant="secondary" onClick={handleEditClose}>
                            Close
                        </button>
                        <button  className="btn shadow-sm continue-btn"
                                 variant="primary"
                                 onClick={handleSaveEdit}
                                 disabled={!isFormValid}>
                            Save Changes
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={addOpen} onHide={handleAddClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Add Configuration</Modal.Title>
                </Modal.Header>
                <form>
                    <Modal.Body>
                        <div>
                            <div className="col-md-12 mb-3">
                                <div className="row">
                                    <div className="col-md-12">
                                        <TextField
                                            fullWidth
                                            label="Instance Endpoint"
                                            name="instance_endpoint"
                                            value={instanceEndpoint}
                                            onChange={(e) => setInstanceEndpoint(e.target.value)}
                                            variant="standard"
                                            className="profile-input config-input"
                                            required
                                            error={!!fieldErrors.instanceEndpoint} // Check if there's an error
                                            helperText={fieldErrors.instanceEndpoint} // Display the error message
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*<div className="col-md-12 mb-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <TextField
                                            fullWidth
                                            label="Instance Status"
                                            name="instance_status"
                                            value={instanceStatus}
                                            onChange={(e) => setInstanceStatus(e.target.value)}
                                            variant="standard"
                                            className="profile-input config-input"
                                            required
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <TextField
                                            fullWidth
                                            label="Status"
                                            name="status"
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                            variant="standard"
                                            className="profile-input config-input"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>*/}
                            <div className="col-md-12 mb-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <FormControl fullWidth variant="standard" className="profile-input config-input" required error={!!fieldErrors.instanceStatus} >
                                            <InputLabel id="instance-status-label">Instance Status</InputLabel>
                                            <Select
                                                labelId="instance-status-label"
                                                value={instanceStatus}
                                                onChange={(e) => setInstanceStatus(e.target.value)}
                                                label="Instance Status"
                                                name="instance_status"
                                            >
                                                <MenuItem value="I">I</MenuItem>
                                                <MenuItem value="A">A</MenuItem>
                                            </Select>
                                            {/* Display the error message */}
                                            {fieldErrors.instanceStatus && (
                                                <span style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
                                        {fieldErrors.instanceStatus}
                                    </span>
                                            )}
                                        </FormControl>
                                    </div>

                                    <div className="col-md-6">
                                        <FormControl fullWidth variant="standard" className="profile-input config-input" required error={!!fieldErrors.status} >
                                            <InputLabel id="status-label">Status</InputLabel>
                                            <Select
                                                labelId="status-label"
                                                value={status}
                                                onChange={(e) => setStatus(e.target.value)}
                                                label="Status"
                                                name="status"
                                            >
                                                <MenuItem value="I">I</MenuItem>
                                                <MenuItem value="A">A</MenuItem>
                                            </Select>
                                            {/* Display the error message */}
                                            {fieldErrors.status && (
                                                <span style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
                                        {fieldErrors.status}
                                    </span>
                                            )}
                                        </FormControl>
                                    </div>
                                </div>
                            </div>

                            {/* Queue Management */}
                            <div className="col-md-12 mb-3">
                                {queues.map((queue, index) => (
                                    <div className="row mb-3" key={index}>
                                        <div className="col-md-12 mb-3">
                                            <TextField
                                                value={queue.queue_url}
                                                onChange={(e) => handleQueueChange(index, 'queue_url', e.target.value)}
                                                label="Queue URL"
                                                variant="standard"
                                                fullWidth
                                                className="profile-input config-input"
                                                required
                                                error={!!errors[`queue_url_${index}`]} // Highlight input if there's an error
                                                helperText={errors[`queue_url_${index}`]} // Show error message below the field
                                            />
                                        </div>
                                        <div className="col-md-12 queue-type">
                                            <h6 className="queue-lable">Queue Type</h6>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={queue.queue_type.includes("process")}
                                                        onChange={() => handleQueueChange(index, "queue_type", "process")}
                                                        sx={{
                                                            color: queue.queue_type === 'process' ? 'rgba(0, 0, 0, 0.54)' : 'default',
                                                            '&.Mui-checked': {
                                                                color: 'rgba(0, 0, 0, 0.54)',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Process"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={queue.queue_type.includes("validate")}
                                                        onChange={() => handleQueueChange(index, "queue_type", "validate")}
                                                        sx={{
                                                            color: queue.queue_type === 'validate' ? 'rgba(0, 0, 0, 0.54)' : 'default',
                                                            '&.Mui-checked': {
                                                                color: 'rgba(0, 0, 0, 0.54)',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Validate"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={queue.queue_type.includes("report")}
                                                        onChange={() => handleQueueChange(index, "queue_type", "report")}
                                                        sx={{
                                                            color: queue.queue_type === 'report' ? 'rgba(0, 0, 0, 0.54)' : 'default',
                                                            '&.Mui-checked': {
                                                                color: 'rgba(0, 0, 0, 0.54)',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Report"
                                            />
                                        </div>
                                        <div className="float-right">
                                            <button className="float-right btn pt-0 pb-0 on-select f-12 px-0 " color="error" onClick={() => removeQueue(index)}>
                                                <RemoveOutlinedIcon/> Remove
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                {/* Button to add a new queue */}
                                <div className="col-md-12 p-0 d-flex justify-content-end">
                                    <button className="btn pt-0 pb-0 mt-2 c-pink p-0 on-select f-12" onClick={addQueue} disabled={!isFormValid}>
                                        <AddOutlinedIcon/> Add Queue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-cancel shadow-sm" onClick={handleAddClose}>
                            Close
                        </button>
                        <button className="btn shadow-sm continue-btn" onClick={handleSave} disabled={!isFormValid}>
                            Save Changes
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>

            <div className="outer-main">
                <div className="main-container">
                    <div className="col-md-12 top-header py-3">
                        <div className="col-md-12 icon-wrap">
                            <TuneOutlinedIcon className="head-icon"/>
                        </div>
                        <div className="col-md-12 head-wrap">
                            Configuration
                        </div>
                    </div>
                    <div className="col-md-12 py-3">
                        <div className=" col-md-12 py-3 inside-content-wrapper">
                            <div className="p-3">
                                <div className="inner-inner-content p-0">
                                    <div className="row mb-4">
                                        {/*<div className="col-md-6 col-12">*/}
                                        {/*    <TextField*/}
                                        {/*        placeholder="Search Deployment ID"*/}
                                        {/*        variant="outlined"*/}
                                        {/*        value={searchQuery}*/}
                                        {/*        onChange={handleSearchQueryChange}*/}
                                        {/*        InputProps={{*/}
                                        {/*            startAdornment: (*/}
                                        {/*                <InputAdornment position="start">*/}
                                        {/*                    <SearchIcon/>*/}
                                        {/*                </InputAdornment>*/}
                                        {/*            ),*/}
                                        {/*            sx: {*/}
                                        {/*                backgroundColor: '#fff',*/}
                                        {/*                borderRadius: '20px',*/}
                                        {/*                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',*/}
                                        {/*            },*/}
                                        {/*        }}*/}
                                        {/*        sx={{ marginBottom: '20px', width: '300px'  }}*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                        <div className="col-md-12">
                                            <button className="btn shadow-sm continue-btn float-right d-flex justify-content-center align-item-end" onClick={handleAddOpen}>
                                                <span class="material-symbols-outlined pr-2">post_add</span>Add
                                            </button>
                                        </div>
                                    </div>

                                    {/* Table*/}
                                    <div className="verification-history-table">
                                        <TableContainer className="loadbalancer-table-container" component={Paper}>
                                            <Table className="loadbalancer-table">
                                                <TableHead className="">
                                                    <TableRow>
                                                        <TableCell className="no-wrap">#</TableCell>
                                                        <TableCell className="no-wrap">Deployment ID</TableCell>
                                                        <TableCell className="no-wrap">API Endpoint</TableCell>
                                                        <TableCell className="no-wrap">Instance Status</TableCell>
                                                        <TableCell className="no-wrap">Status</TableCell>
                                                        {/*<TableCell className="no-wrap">Queue Url</TableCell>
                                                        <TableCell className="no-wrap">Queue Type</TableCell>*/}
                                                        <TableCell className="no-wrap">Created On</TableCell>
                                                        <TableCell className="no-wrap"></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {loading ? (
                                                        Array.from(new Array(5)).map((_, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell><Skeleton height={30}/></TableCell>
                                                                <TableCell><Skeleton height={30}/></TableCell>
                                                                <TableCell><Skeleton height={30}/></TableCell>
                                                                <TableCell><Skeleton height={30}/></TableCell>
                                                                <TableCell><Skeleton height={30}/></TableCell>
                                                                <TableCell><Skeleton height={30}/></TableCell>
                                                                <TableCell><Skeleton height={30}/></TableCell>
                                                            </TableRow>
                                                        ))
                                                    ) : (
                                                        subClientDetails.length > 0 ? (
                                                            subClientDetails.map((row, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell className="no-wrap">{(currentPage - 1) * pageSize + index + 1}</TableCell>
                                                                    <TableCell className="no-wrap">{row.deployment_id}</TableCell>
                                                                    <TableCell className="no-wrap ellipsis-text">
                                                                        <BootstrapTooltip title={row.instance_endpoint}>
                                                                            <span>{row.instance_endpoint}</span>
                                                                        </BootstrapTooltip>
                                                                    </TableCell>
                                                                    <TableCell className="no-wrap">{row.instance_status}</TableCell>
                                                                    <TableCell className="no-wrap">{row.status}</TableCell>

                                                                    {/*<TableCell className="no-wrap">
                                                                    {row.queues.map((queue, queueIndex) => (
                                                                        <div key={queueIndex}>{queue.queue_url}</div>
                                                                    ))}
                                                                </TableCell>

                                                                <TableCell className="no-wrap">
                                                                    {row.queues.map((queue, queueIndex) => (
                                                                        <div key={queueIndex}>{queue.queue_type}</div>
                                                                    ))}
                                                                </TableCell>*/}

                                                                    {/*<TableCell className="no-wrap">
                                                                    {new Date(row.created_timestamp).toLocaleString()}
                                                                </TableCell>*/}
                                                                    <TableCell className="no-wrap">
                                                                        {row.created_timestamp}
                                                                    </TableCell>
                                                                    <TableCell className="no-wrap">
                                                                        <BootstrapTooltip title="Edit">
                                                                            <IconButton onClick={() => handleEditOpen(row)}>
                                                                                <span class="material-symbols-outlined f-18">edit</span>
                                                                            </IconButton>
                                                                        </BootstrapTooltip>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan={7} className="text-center f-18 font-bold">
                                                                    <p className="mb-0"><img src="/images/no-data.png" alt="No Data Found" style={{ width: '100px', height: '100px' }} /></p>
                                                                    <p className="mb-0">No record available!</p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>

                                    <div className="col-md-12 d-flex justify-content-end p-0 mb-4">
                                        <div className="pagination-container shadow-sm">
                                            <Pagination className="pagination-data border-top f-12 pt-3 pb-2"
                                                        current={currentPage}
                                                        pageSize={pageSize}
                                                // total={filteredSubClientDetails.length}
                                                        total={totalRecords}
                                                // onChange={(page) => setCurrentPage(page)}
                                                        onChange={(page) => {
                                                            setCurrentPage(page); // Set the current page
                                                            // getloadbalancerConfig(); // Fetch data for the new page
                                                        }}
                                                        showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${totalRecords}`}
                                                        showLessItems
                                                        showSizeChanger={false}
                                                        itemRender={PrevNextArrow}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
            {/* Snackbar*/}
            {/*<Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <MuiAlert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    API Key Copied!
                </MuiAlert>
            </Snackbar>*/}
        </>
    );
}

export default LoadbalancerConfiguration;
