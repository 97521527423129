import React, {useEffect, useState, useRef} from "react";
import axios from 'axios';
import { ResponsivePie } from '@nivo/pie';
import Loader from "../Loader";
import configData from "../../config/index.json";
import {useCookies} from "react-cookie";

const PieChart = ({chartData}) => {
    const [pieData, setPieData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cookies, removeCookie] = useCookies(['clientPlatformID']);
    const pieRef = useRef(null);
    let expressURL = configData.express_url;

    useEffect(() => {
        if (pieRef.current) {
            // Select all paths that have a stroke attribute and set their stroke to transparent
            const paths = pieRef.current.querySelectorAll('path[stroke]');
            paths.forEach(path => {
                path.style.stroke = 'transparent';
                path.style.opacity = '0';
            });
        }

        if(chartData){
            const pieData = [
                {
                    id: 'Match',
                    label: 'Match',
                    value: Math.ceil(parseFloat(chartData.overall_match_percentage))
                },
                {
                    id: 'Mismatch',
                    label: 'Mismatch',
                    value: Math.ceil(parseFloat(chartData.overall_mismatch_percentage))
                }
            ];
            setPieData(pieData);
        }

        // if (cookies.clientPlatformID) {
        //     const loggedPlatformID = {"platform_id": cookies.clientPlatformID};
        //
        //     axios.post(expressURL + "api/dashboard/matchmismatchstats", loggedPlatformID)
        //         .then(response => {
        //             console.log("loggedPlatformID", loggedPlatformID);
        //             console.log("/matchmismatchstats response", response.data);
        //
        //             // Extract overall match/mismatch percentages
        //             /*const pieData = [
        //                 { id: 'Match', label: 'Match', value: parseFloat(response.data.overall_match_percentage) },
        //                 { id: 'Mismatch', label: 'Mismatch', value: parseFloat(response.data.overall_mismatch_percentage) }
        //             ];*/
        //             const pieData = [
        //                 {
        //                     id: 'Match',
        //                     label: 'Match',
        //                     value: Math.ceil(parseFloat(response.data.overall_match_percentage))
        //                 },
        //                 {
        //                     id: 'Mismatch',
        //                     label: 'Mismatch',
        //                     value: Math.ceil(parseFloat(response.data.overall_mismatch_percentage))
        //                 }
        //             ];
        //
        //             //setData(transformedData);
        //             setPieData(pieData);
        //             setLoading(false);
        //
        //         })
        //         .catch(err => {
        //             setError(err);
        //             setLoading(false);
        //             setHasData(false);
        //         });
        // }
    }, []);

    const CustomArcLabel = ({ datum, label }) => {
        const backgroundColor = 'rgba(255, 255, 255, 0.7)'; // White with 70% opacity
        const textColor = datum.id === 'Match' ? '#47AA45' : '#E7A343'; // Match the text color to the slice color

        const midAngle = (datum.arc.startAngle + datum.arc.endAngle) / 2;

        const radiusOffset = 1.3; // Adjust this to move the labels closer/farther from the pie chart
        const x = Math.cos(midAngle - Math.PI / 2) * datum.arc.innerRadius * radiusOffset;
        const y = Math.sin(midAngle - Math.PI / 2) * datum.arc.innerRadius * radiusOffset;


        // Set specific x and y coordinates based on slice id
        /*const x = datum.id === 'Match' ? 50 : -50;
        const y = datum.id === 'Match' ? -65 : 65;*/

        return (
            <g transform={`translate(${x},${y})`}>
                <rect
                    x={-20} // Adjust these values to properly position the background rectangle
                    y={-10}
                    width={40}
                    height={25}
                    fill={backgroundColor}
                    rx={8} // Rounded corners
                />
                <text
                    x={0} // Center the text within the rectangle
                    y={3}
                    textAnchor="middle"
                    dominantBaseline="central"
                    fill={textColor}
                    fontSize={14}
                    fontWeight="bold"
                >
                    {label}%
                </text>
            </g>
        );
    };




    return (
        <>
            <div className="row p-3">
                <div className="col-md-4 pie-chart-left">
                    <p className="f-15 fw-light">Chart showing the proportion of match vs mismatch cases</p>
                    <div className="legend">
                        <div className="legend-item">
                            <span className="legend-color-match"></span>
                            <span className="f-12">Match</span>
                        </div>
                        <div className="legend-item">
                            <span className="legend-color-mismatch"></span>
                            <span className="f-12">Mismatch</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 p-0">
                    <div style={{ height: 300 }} className="custom-pie-chart">
                        <ResponsivePie
                            data={pieData} // Use the pieData state
                            margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                            innerRadius={0.6}
                            padAngle={0.7}
                            cornerRadius={2}
                            colors={({ id }) => id === 'Match' ? '#47AA45' : '#E7A343'} // Custom colors for Match and Mismatch
                            borderWidth={1}
                            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                            activeOuterRadiusOffset={8}
                            arcLabelsComponent={CustomArcLabel} // Use the custom arc label component
                            arcLabelsRadiusOffset={0.55} // Adjust this to control the position of the labels
                            arcLinkLabelsSkipAngle={360} // Skip all arc link labels (removes the path lines and labels)
                            arcLinkLabelsTextColor="none" // Ensure no text color is applied to arc link labels
                            arcLinkLabelsColor="none" // Ensure no color is applied to arc link paths
                            // Settings for arc links (connecting paths)
                            //arcLinkLabelsSkipAngle={0}
                            //arcLinkLabelsTextColor="#fff" // Color of the text on the arc links
                            arcLinkLabelsThickness={2}
                            //arcLinkLabelsColor={{ from: 'color' }} // Match arc link color to the slice color
                            // Settings for arc labels (inside the pie chart)
                            //arcLabelsSkipAngle={10}
                            arcLabelsTextColor="#fff" // Make the percentage text inside the pie white
                            // Settings for radial labels and links (if used)
                            radialLabelsSkipAngle={10}
                            radialLabelsTextColor="#333333" // Color for radial label text (outside the pie)
                            radialLabelsLinkColor={{ from: 'color' }} // Match radial link color to slice color
                            sliceLabelsSkipAngle={10}
                            sliceLabelsTextColor="#333333"
                            legends={[]}
                            theme={{
                                labels: {
                                    text: {
                                        fill: '#fff', // Color for pie labels
                                    }
                                },
                                legends: {
                                    text: {
                                        fill: '#fff', // Color for legend text
                                    }
                                }
                            }}
                            tooltip={({ datum }) => (
                                <div className="pie-tootip-cvr">
                                    <div className="pie-tootip-in"
                                        style={{
                                            backgroundColor: datum.color,
                                        }}
                                    />
                                    <span className="f-12">{`${datum.id}: ${datum.value}%`}</span>
                                </div>
                            )}
                        />
                        {/*<ResponsivePie
                            data={pieData}
                            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                            innerRadius={0.6}
                            padAngle={0.7}
                            cornerRadius={3}
                            colors={({ id }) => id === 'Match' ? '#5271FF' : '#CE9A2D'}
                            activeOuterRadiusOffset={8}
                            borderWidth={1}
                            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}

                            // Settings for arc links (connecting paths)
                            arcLinkLabelsSkipAngle={0}
                            arcLinkLabelsTextColor="#fff" // Color of the text on the arc links
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{ from: 'color' }} // Match arc link color to the slice color

                            // Settings for arc labels (inside the pie chart)
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor="#fff" // Make the percentage text inside the pie white

                            // Settings for radial labels and links (if used)
                            radialLabelsSkipAngle={10}
                            radialLabelsTextColor="#fff" // Color for radial label text (outside the pie)
                            radialLabelsLinkColor={{ from: 'color' }} // Match radial link color to slice color

                            sliceLabelsSkipAngle={10}
                            sliceLabelsTextColor="#fff"  // Ensure slice labels are white

                            theme={{
                                labels: {
                                    text: {
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        fill: '#fff', // Set text color for labels
                                    },
                                },
                                legends: {
                                    text: {
                                        fontSize: '14px',
                                        fill: '#fff', // Color for legend text (if legends were included)
                                    }
                                }
                            }}
                            legends={[]} // Hide default legends since custom legends are created
                        />*/}
                        {/*<ResponsivePie
                            data={pieData}
                            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                            innerRadius={0.6}
                            padAngle={0.7}
                            cornerRadius={3}
                            colors={({ id }) => id === 'Match' ? '#5271FF' : '#CE9A2D'}
                            activeOuterRadiusOffset={8}
                            borderWidth={1}
                            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#fff"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{ from: 'color' }}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        2
                                    ]
                                ]
                            }}

                            radialLabelsSkipAngle={10}
                            radialLabelsTextColor="#fff" // Hide the radial label text
                            radialLabelsLinkColor="#010101"
                            sliceLabelsSkipAngle={10}
                            sliceLabelsTextColor="#fff"  // Sets color for slice labels
                            theme={{
                                labels: {
                                    text: {
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        fill: '#fff', // Color for slice labels
                                    },
                                },
                                legends: {
                                    text: {
                                        fontSize: '14px',
                                        fill: '#fff', // Color for legend text
                                    }
                                }
                            }}
                            legends={[]} // Hide default legends since custom legends are created
                        />*/}
                    </div>
                </div>
            </div>
            {/*<div className="col-md-12 p-0 row">
                <div className="col-md-5 p-0">
                    <p className="f-15 fw-light">Chart showing the proportion of match vs mismatch cases</p>
                    <span></span>
                </div>
                <div className="col-md-7 p-0">
                    <div style={{ height: 400, marginTop: '50px' }}>
                        <ResponsivePie
                            data={pieData} // Use the pieData state
                            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                            innerRadius={0.5}
                            padAngle={0.7}
                            cornerRadius={3}
                            colors={{ scheme: 'nivo' }} // Default Nivo color scheme
                            colors={({ id }) => id === 'Match' ? '#5271FF' : '#CE9A2D'} // Custom colors for Match and Mismatch
                            borderWidth={1}
                            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                            radialLabelsSkipAngle={10}
                            radialLabelsTextColor="#333333"
                            radialLabelsLinkColor={{ from: 'color' }}
                            sliceLabelsSkipAngle={10}
                            sliceLabelsTextColor="#333333"
                            legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    justify: false,
                                    translateX: 0,
                                    translateY: 56,
                                    itemsSpacing: 0,
                                    itemWidth: 100,
                                    itemHeight: 18,
                                    itemTextColor: '#999',
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 1,
                                    symbolSize: 18,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                            theme={{
                                labels: {
                                    text: {
                                        fill: '#fff', // Color for pie labels
                                    }
                                },
                                legends: {
                                    text: {
                                        fill: '#fff', // Color for legend text
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>*/}

        </>
    );
};

export default PieChart;
