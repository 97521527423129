import React, {useEffect, useMemo, useState, useRef} from "react";
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, InputAdornment, IconButton,
    Avatar, Box, Snackbar, MenuItem, StepConnector,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import moment from 'moment';
import { useCookies } from 'react-cookie';
import swal from "sweetalert";
import { Stepper, Step, StepLabel, StepContent, Button, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import { Circle } from '@mui/icons-material';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {Modal} from "react-bootstrap";
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import VideoRecorder from 'react-video-recorder';
import configData from "../config/index.json";
import LoadingOverlay from 'react-loading-overlay';
import CancelIcon from '@mui/icons-material/Cancel';
import VerifiedIcon from '@mui/icons-material/Verified';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Webcam from 'react-webcam';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
// import { FFmpeg } from '@ffmpeg/ffmpeg';
// import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import '../assets/css/icatch.css';
import "../style.css";
import Footer from "./Footer";
import MuiAlert from "@mui/material/Alert";

const initialSteps = [
    'Fill Candidate Basic Information',
    'Upload Candidate Government Identification Document (Optional)',
    'Record Consent Of The Candidate',
    'Interview Round 1',
    'Verification'
];

// Custom connector to modify the line colors
const CustomConnector = styled(StepConnector)(({ theme }) => ({
    '& .MuiStepConnector-line': {
        borderColor: '#373A42', // Default color for the line
        borderWidth: 2,
    }
}));

// Custom icon styles based on step state
const CustomStepIconRoot = styled('div')(({ ownerState }) => ({
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#E7A343', // Active step icon color
    }),
    ...(ownerState.completed && {
        color: '#47AA45', // Completed step icon color
    }),
    color: '#373A42', // Default icon color
}));

const CustomCheckIcon = styled(CheckCircleIcon)(({ theme }) => ({
    color: '#fff',
    fontSize: '16px', // Adjust the font size to reduce the checkmark size
}));

function CustomStepIcon(props) {
    const { active, completed } = props;

    return (
        <CustomStepIconRoot ownerState={{ active, completed }}>
            {completed ? (
                <Check style={{ color: '#fff', backgroundColor: '#47AA45', borderRadius: '50%', fontSize: '16px' }} />
            ) : (
                <Circle style={{
                    fontSize: '16px',
                    color: active ? '#E7A343' : '#373A42' // Change icon color when active
                }} />
            )}
        </CustomStepIconRoot>
    );
}

// const ffmpeg = createFFmpeg({ log: true });

function ICatch() {
    const navigate = useNavigate();
    const [interviewRounds, setInterviewRounds] = useState([{ id: 1, targetDocumentFile: null, s3TargetDocumentUrl: null, errors: {} }]);
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState(initialSteps); // Dynamic steps state
    const [interviewRoundCount, setInterviewRoundCount] = useState(1);
    const [errors, setErrors] = useState({});
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [lastName, setLastName] = useState('');
    const [documentType, setDocumentType] = useState('');
    const [s3DocumentUrl, setS3DocumentUrl] = useState("");
    const [uploadedDocumentUrl, setUploadedDocumentUrl] = useState(null);
    const [documentFile, setDocumentFile] = useState(null);
    const [s3SourceDocumentUrl, setS3SourceDocumentUrl] = useState(null); // Store the S3 URL after upload
    const [documentSourceFile, setDocumentSourceFile] = useState(null);
    const [videoPreview, setVideoPreview] = useState(null);
    const [consumptionDetails, setConsumptionDetails] = useState(null);
    const [open, setOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [videoBlob, setVideoBlob] = useState(null);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const webcamRef = useRef(null);
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const [targetDocumentFile, setTargetDocumentFile] = useState(null);
    const [s3TargetDocumentUrl, setS3TargetDocumentUrl] = useState("");
    const [addOpen, setAddOpen] = useState(false);
    const [step, setStep] = useState(0);
    const [caseId, setCaseId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingValidation, setLoadingValidation] = useState(false);
    const [validation, setValidation] = useState({isValid: false, message: ''});
    //const [showStep2, setShowStep2] = useState(true);
    const [cookies, removeCookie] = useCookies(['clientPlatformID','clientClientID']);
    const [clientId, setClientId] = useState(cookies.clientClientID);
    const [videoDuration, setVideoDuration] = useState(0);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [isVerified, setIsVerified] = useState(false);
    const [validatedSteps, setValidatedSteps] = useState({});
    const [errorValidatedSteps, setErrorValidatedSteps] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [verifyingOpen, setVerifyingOpen] = useState(false);
    const [uploadedDocumentUrls, setUploadedDocumentUrls] = useState([]);
    const [countdown, setCountdown] = useState(10); // Set the countdown timer (10 seconds)
    const [isCountdownActive, setIsCountdownActive] = useState(false); // For tracking countdown activity
    const [isDoVerified, setIsDoVerified] = useState(false);
    let expressURL = configData.express_url;
    let iCatchURL = configData.icatch_url;

    //doVerify changes
    const interviewRoundsRef = useRef(interviewRounds);
    const stepsRef = useRef(steps);
    const isValidRef = useRef(validation);

    // Update the refs whenever the states change
    useEffect(() => {
        interviewRoundsRef.current = interviewRounds;
    }, [interviewRounds]);

    useEffect(() => {
        stepsRef.current = steps;
    }, [steps]);

    useEffect(() => {
        if (isCountdownActive && countdown > 0) {
            const countdownInterval = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);

            return () => clearInterval(countdownInterval); // Cleanup interval on unmount or countdown finish
        } else if (countdown === 0) {
            setIsCountdownActive(false); // Stop countdown
        }
    }, [isCountdownActive, countdown]);

    const getWebcamStream = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            webcamRef.current.srcObject = stream;
            webcamRef.current.stream = stream;
            console.log("Webcam stream:", stream); // Log to verify webcam stream
        } catch (error) {
            console.error("Error accessing webcam:", error);
        }
    };

    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));

    const handleNextStep = () => {
        setActiveStep(prevStep => prevStep + 1);
    };

    // const handleAddInterviewRound = () => {
    //     handleAddClose();
    //     const newInterviewRound = `Interview Round ${interviewRoundCount + 1}`;
    //     setSteps([...steps.slice(0, steps.length - 1), newInterviewRound, 'Verification']);
    //     setInterviewRoundCount(interviewRoundCount + 1);
    // };

    // Multiple Interview Rounds
    const handleAddInterviewRound = () => {
        const newRound = {
            id: interviewRounds.length + 1,
            targetDocumentFile: null,
            s3TargetDocumentUrl: null,
            errors: {}
        };
        const updatedRounds = [...interviewRounds, newRound];
        setInterviewRounds(updatedRounds);
        const newStep = `Interview Round ${updatedRounds.length}`;
        const updatedSteps = [...steps.slice(0, steps.length - 1), newStep, 'Verification'];
        setSteps(updatedSteps);
        setActiveStep(updatedSteps.length - 2);
        handleAddClose();
    };



    const handleTargetFileChangeInterview = async (index, event) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }

        const updatedRounds = [...interviewRounds];
        updatedRounds[index].targetDocumentFile = file;
        updatedRounds[index].errors = { ...updatedRounds[index].errors, targetDocumentFile: '' };
        setInterviewRounds(updatedRounds);

        await uploadTargetDocumentToS3Interview(file, index);
    };



    const uploadTargetDocumentToS3Interview = (file, index) => {
        if (!file) {
            return;
        }

        const loggedPlatformID = cookies.clientPlatformID;
        const formData = new FormData();
        const fileExtension = file.name.split('.').pop();
        formData.append('platform_id', loggedPlatformID);
        formData.append('client_code', clientId);
        formData.append('case_id', caseId);
        formData.append('documentName', `interview_${index}.${fileExtension}`);
        formData.append('document', file);
        console.log("upload target ",formData);
        setLoading(true);

        axios.post(expressURL + "api/document-upload/upload", formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then(async response => {
                console.log('Upload successful:', response.data); // Debugging
                const updatedRounds = [...interviewRounds];
                updatedRounds[index].s3TargetDocumentUrl = response.data.url; // Get the URL from the response
                setInterviewRounds(updatedRounds);
                await handleSubmitStep3(index);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.error("Upload failed:", error);  // Debugging
                swal({
                    title: 'Error!',
                    text: 'Failed to upload file. Please try again.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            });
    };
    //End of MIR

    const handleOpen = (event) => {
        event.preventDefault();
        /*setValidation({
            isValid: true,
            message: ''
        });*/
        setOpen(true);
        setVideoPreview(null);
    };

    const handleClose = () => {
        setOpen(false);
        setVideoBlob(null);
    };

    function Cancel() {
        setOpen(false)
    }

    const handleOpenErrorModal = (event) => {
        setErrorModalOpen(true);
        setVideoPreview(null);
    };

    const handleCloseErrorModal = () => {
        setErrorModalOpen(false);
    };

    const handleAddOpen = (event) => {
        // event.preventDefault();
        setAddOpen(true);
    };

    const handleAddClose = () => {
        setAddOpen(false);
    };

    const nohandleAddClose = () => {
        setAddOpen(false);
    };

    const startRecording = () => {
        const stream = webcamRef.current?.video?.srcObject;

        if (!stream) {
            console.error("Webcam stream is not available");
            return;
        }

        setRecordedChunks([]); // Reset previous chunks
        const mediaRecorder = new MediaRecorder(stream); // Initialize MediaRecorder
        mediaRecorderRef.current = mediaRecorder;
        setIsRecording(true); // Mark that recording has started

        // Capture video data as it's available
        mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
                setRecordedChunks((prev) => [...prev, event.data]); // Store chunks
            }
        };

        mediaRecorder.onstop = () => {
            console.log("Recording stopped");
            if (recordedChunks.length > 0) {
                const blob = new Blob(recordedChunks, { type: 'video/webm' });
                const videoUrl = URL.createObjectURL(blob); // Create URL for video preview
                setVideoPreview(videoUrl); // Preview the recorded video
            } else {
                console.error("No video recorded or recordedChunks is empty.");
            }
        };

        mediaRecorder.start(); // Start recording
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop(); // Stop recording
            setIsRecording(false); // Mark that recording has stopped
        }
    };


    /*const handleSave = () => {
        const blob = new Blob(recordedChunks, { type: 'video/webm' });
        uploadSourceFileToS3(blob);
        handleClose();
    };*/

    /*const handleSaveRecordedVideo = async () => {
        if (recordedChunks.length > 0) {
            const blob = new Blob(recordedChunks, { type: 'video/webm' });

            // Show loader during conversion
            //setIsConverting(true);

            // Load ffmpeg if not already loaded
            if (!ffmpeg.isLoaded()) {
                await ffmpeg.load();
            }

            // Convert webm to mp4 using ffmpeg
            const file = new File([blob], 'recordedVideo.webm', { type: 'video/webm' });
            ffmpeg.FS('writeFile', 'video.webm', await fetch(file)); // Write webm file to ffmpeg filesystem

            await ffmpeg.run('-i', 'video.webm', 'output.mp4'); // Convert webm to mp4

            const mp4Data = ffmpeg.FS('readFile', 'output.mp4'); // Read the mp4 data

            const mp4Blob = new Blob([mp4Data.buffer], { type: 'video/mp4' }); // Create a blob from the mp4 data
            const mp4File = new File([mp4Blob], 'recordedVideo.mp4', { type: 'video/mp4' });

            // Stop loader after conversion is done
            //setIsConverting(false);

            // Upload mp4 file to S3
            uploadSourceFileToS3(mp4File);

            handleClose(); // Close the modal after upload
        } else {
            swal({
                title: 'Error!',
                text: 'No recorded video to save. Please try again.',
                icon: 'error',
                button: 'OK',
            });
        }
    };*/
    // Function to convert webm to mp4 and upload
    /*const handleSaveRecordedVideo = async () => {
        if (recordedChunks.length > 0) {
            const blob = new Blob(recordedChunks, { type: 'video/webm' });

            // Show loader during conversion
            //setIsConverting(true);

            // Load ffmpeg if not already loaded
            if (!ffmpeg.isLoaded()) {
                await ffmpeg.load();
            }

            // Convert webm to mp4 using ffmpeg
            const file = new File([blob], 'recordedVideo.webm', { type: 'video/webm' });
            ffmpeg.FS('writeFile', 'video.webm', await fetchFile(file)); // Write webm file to ffmpeg filesystem

            await ffmpeg.run('-i', 'video.webm', 'output.mp4'); // Convert webm to mp4

            const mp4Data = ffmpeg.FS('readFile', 'output.mp4'); // Read the mp4 data

            const mp4Blob = new Blob([mp4Data.buffer], { type: 'video/mp4' }); // Create a blob from the mp4 data
            const mp4File = new File([mp4Blob], 'recordedVideo.mp4', { type: 'video/mp4' });

            // Stop loader after conversion is done
            //setIsConverting(false);

            // Upload mp4 file to S3
            uploadSourceFileToS3(mp4File);

            handleClose(); // Close the modal after upload
        } else {
            swal({
                title: 'Error!',
                text: 'No recorded video to save. Please try again.',
                icon: 'error',
                button: 'OK',
            });
        }
    };*/

    // const convertVideo = async (videoBlob) => {
    //     try {
    //         if (!ffmpeg.isLoaded()) {
    //             await ffmpeg.load();
    //         }
    //
    //         // Convert Blob to File
    //         const file = new File([videoBlob], 'recordedVideo.webm', { type: 'video/webm' });
    //
    //         // Write the file to ffmpeg's virtual filesystem
    //         ffmpeg.FS('writeFile', 'video.webm', await fetchFile(file));
    //
    //         // Run the ffmpeg command to convert webm to mp4
    //         await ffmpeg.run('-i', 'video.webm', 'output.mp4');
    //
    //         // Read the output file
    //         const mp4Data = ffmpeg.FS('readFile', 'output.mp4');
    //         const mp4Blob = new Blob([mp4Data.buffer], { type: 'video/mp4' });
    //         const mp4URL = URL.createObjectURL(mp4Blob);
    //
    //         // Now, you can use mp4URL for video preview or saving
    //         console.log('Video converted successfully:', mp4URL);
    //
    //         return mp4URL;
    //     } catch (error) {
    //         console.error('Error during conversion:', error);
    //     }
    // };

    /*const handleSaveRecordedVideo = async () => {
        if (recordedChunks.length > 0) {
            const blob = new Blob(recordedChunks, { type: 'video/webm' });

            // Call the convertVideo function to convert the webm to mp4
            const mp4URL = await convertVideo(blob);

            if (mp4URL) {
                // Preview or upload the mp4 file
                const mp4Blob = await fetch(mp4URL).then(r => r.blob());
                const mp4File = new File([mp4Blob], 'recordedVideo.mp4', { type: 'video/mp4' });

                // You can now upload mp4File to S3 or use it however you like
                uploadSourceFileToS3(mp4File);

                handleClose(); // Close modal after upload
            }
        } else {
            swal({
                title: 'Error!',
                text: 'No recorded video to save. Please try again.',
                icon: 'error',
                button: 'OK',
            });
        }
    };*/

    /*const handleSaveRecordedVideo = async () => {
        if (recordedChunks.length > 0) {
            const blob = new Blob(recordedChunks, { type: 'video/webm' });

            // Show loader during conversion (if necessary)
            // setIsConverting(true);

            // Load ffmpeg if not already loaded
            if (!ffmpeg.isLoaded()) {
                await ffmpeg.load();
            }

            // Convert webm to mp4 using ffmpeg
            const file = new File([blob], 'recordedVideo.webm', { type: 'video/webm' });
            ffmpeg.FS('writeFile', 'video.webm', await fetchFile(file)); // Write webm file to ffmpeg filesystem

            await ffmpeg.run('-i', 'video.webm', 'output.mp4'); // Convert webm to mp4

            const mp4Data = ffmpeg.FS('readFile', 'output.mp4'); // Read the mp4 data

            const mp4Blob = new Blob([mp4Data.buffer], { type: 'video/mp4' }); // Create a blob from the mp4 data
            const mp4File = new File([mp4Blob], 'recordedVideo.mp4', { type: 'video/mp4' });

            // Stop loader after conversion is done (if necessary)
            // setIsConverting(false);

            // Upload mp4 file to S3
            uploadSourceFileToS3(mp4File);

            handleClose(); // Close the modal after upload
        } else {
            swal({
                title: 'Error!',
                text: 'No recorded video to save. Please try again.',
                icon: 'error',
                button: 'OK',
            });
        }
    };*/

    const blobToFile = (blob, fileName) => {
        const file = new File([blob], fileName, { type: 'video/webm' });
        return file;
    };

    const handleSaveRecordedVideo = async () => {
        if (!videoBlob) {
            setErrors({ apiError: "No recorded video available. Please record a video before saving." });
            return;
        }

        // Convert the recorded video blob to a file object
        const recordedFile = blobToFile(videoBlob, 'recordedConsentVideo.webm');

        // Use the same function for uploading the file to S3
        await uploadSourceFileToS3(recordedFile);
        setDocumentSourceFile(true);
        handleClose();
    };

    const handleDocumentChange = (event) => {
        const { value } = event.target;
        setDocumentType(value);

        // Reset errors when a new document type is selected
        setErrors((prevErrors) => ({
            ...prevErrors,
            documentType: '',
            documentFile: ''
        }));
    };




    /*const handleVideoFileChange = (event) => {
        const file = event.target.files[0];
        setDocumentSourceFile(file);

        if (file) {
            console.log('Selected file:', file.name);
            // Handle the file, upload it, or display it as needed
        }
    };*/

    const handleVideoFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            // Check if the file is an mp4
            if (!file.name.endsWith('.mp4')) {
                setErrors({ documentSourceFile: "Only .mp4 files are allowed" });
                return;
            }

            // Create a preview URL
            const previewUrl = URL.createObjectURL(file);
            setVideoPreview(previewUrl);  // For preview
            setDocumentSourceFile(file);  // For upload

            const videoElement = document.createElement('video');
            videoElement.src = previewUrl;
            videoElement.onloadedmetadata = () => {
                setVideoDuration(videoElement.duration);
            };

            uploadSourceFileToS3(file); // Automatically upload the file on change
        }
    };


    /*const handleTargetFileChange = (event) => {
        const file = event.target.files[0];
        setTargetDocumentFile(file);

        // Clear file upload errors
        if (file) {
            setTargetDocumentFile(file);
            setErrors((prevErrors) => ({
                ...prevErrors,
                documentFile: ''
            }));
        }
    };*/

    const validateStep = () => {
        let isValid = true;
        const newErrors = {};

        // Validation for Step 0 (Candidate Details)
        if (activeStep === 0) {
            if (!firstName.trim()) {
                isValid = false;
                newErrors.firstName = "First name is required";
            }
            if (!lastName.trim()) {
                isValid = false;
                newErrors.lastName = "Last name is required";
            }
        }

        // Validation for Step 1 (Document Upload)
        if (activeStep === 1) {
            if (documentType) {
                if (!documentFile) {
                    isValid = false;
                    newErrors.documentFile = "Document file is required";
                }
            }
        }

        // Validation for Step 2 (Video Upload)
        if (activeStep === 2) {
            if (!documentSourceFile) {
                isValid = false;
                newErrors.documentSourceFile = "Please upload a video file before saving.";
            }
        }

        if (activeStep !== 2 && activeStep === 2 && !documentSourceFile) {
            isValid = false;
            newErrors.documentSourceFile = "Please upload a video file before saving.";
        }

        // if (activeStep === 3) {
        //     if (!targetDocumentFile) {
        //         isValid = false;
        //         newErrors.documentSourceFile = "Please upload a video file before saving.";
        //     }
        // }

        setErrors(newErrors);
        return isValid;
    };

    const handleVerifyingClose= (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setVerifyingOpen(false);
    };


    const handleNext = () => {
        console.log("HANDLE NEXT")
        // Validate the current step
        if (activeStep !== 2 && !validateStep()) {
            console.log("!validateStep RETURN")
            return;
        }
        console.log('activeStep',activeStep)
        if (activeStep === 1 && !documentType) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            return;
        }

        // Check if it's the document upload step and file is missing
        if (activeStep === 1 && documentType && !documentFile) {
            setErrors({ documentFile: "Please upload a document file" });
            return;
        }

        /*const activeRoundIndex = activeStep - 3;
        if (activeRoundIndex < interviewRounds.length && loadingValidation) {
            console.log("next NEXT", loadingValidation, activeStep)
            setVerifyingOpen(true);
            handleAddClose();
            return;
        }*/

        /*const activeRoundIndex = activeStep - 3;

        if (activeRoundIndex < interviewRounds.length && loadingValidation) {
            const currentRound = interviewRounds[activeRoundIndex];
                console.log("Validation still in progress, wait before proceeding.");
                setVerifyingOpen(true);  // Show validation modal
                return;
            //if (!currentRound.s3TargetDocumentUrl || !currentRound.isVerified) {
            if (uploadedDocumentUrls || !isDoVerified) {
                console.log("Document is not uploaded or not verified, cannot proceed.");
                setErrors({ targetDocumentFile: "Please upload and verify the document before proceeding." });
                return;
            }
        }*/


        // Proceed to the next step
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        handleAddClose();
    };


    const handleSkipRound = (roundId) => {
        const activeRoundIndex = activeStep - 3; // Calculate the active round based on step index (subtracting the first 3 static steps)

        if (activeRoundIndex >= 0 && activeRoundIndex < interviewRounds.length) {
            // Remove the corresponding round
            const updatedRounds = interviewRounds.filter((_, index) => index !== activeRoundIndex);
            setInterviewRounds(updatedRounds);

            // Remove the corresponding step label for the active round
            const updatedSteps = steps.filter((_, index) => index !== activeStep);

            // Rebuild steps to renumber Interview Rounds correctly
            const newSteps = updatedRounds.map((_, index) => `Interview Round ${index + 1}`);
            setSteps([...steps.slice(0, 3), ...newSteps, 'Verification']);

            // Set the active step to the last step ("Verification")
            console.log('validation.isValid',validation.isValid);
            if(validation.isValid === true) {
                setActiveStep(updatedSteps.length - 1);
            } else {
                setActiveStep(2);
            }
        }
    };

    /*const handleNext = () => {
        if (validateStep()) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };*/
    /*const handleNext = () => {
        if (activeStep === 1 && documentType && !documentFile) {
            setErrors({ documentFile: "Please upload a document file" });
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };*/

    /*
        const handleNext = () => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        };*/

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    /*const handleSubmitStep0 = (event) => {
        event.preventDefault();
        if (!firstName || !lastName) {
            setErrors({
                firstName: !firstName ? 'First name is required' : '',
                lastName: !lastName ? 'Last name is required' : ''
            });
            return;
        }

        handleNext();
    };*/

    /*const handleSubmitStep0 = async (event) => {
        //step0
        event.preventDefault();

        if (!firstName || !lastName) {
            setErrors({
                firstName: !firstName ? 'First name is required' : '',
                lastName: !lastName ? 'Last name is required' : ''
            });
            return;
        }
        const requestBody = {
            first_name: firstName,
            last_name: lastName,
            email_address: email,
            mobile_number: mobileNo,
            client_code: clientId
        };
        setLoading(true);
        axios.post(iCatchURL + "case/createcase", requestBody)
            .then((response) => {
                console.log("CASE CREATION", response)
                console.log("CASE CREATION", response.data.success)
                if (response.data.success === true) {
                    console.log('Case Created Successfully:', response.data.data.case_id);
                    setCaseId(response.data.data.case_id);
                    handleNext();
                    setLoading(false);
                } else {
                    setLoading(false);
                    console.log("ELSE", response.data.errors)
                    console.log('ERROR creating case:', response.data.errors);
                    setErrors({ apiError: response.data.errors });
                    swal({
                        title: 'Error!',
                        text: response.data.errors || 'Something went wrong.',
                        icon: 'error',
                        button: "OK",
                        closeOnClickOutside: false,
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error('Network error or API call failed:', error);
                setErrors({ apiError: 'Failed to create case. Please try again later.' });
                swal({
                    title: 'Error!',
                    text: 'Something went wrong.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            });
    };*/

    const handleSubmitStep0 = async (event) => {
        event.preventDefault();

        // Input validation
        if (!firstName || !lastName) {
            setErrors({
                firstName: !firstName ? 'First name is required' : '',
                lastName: !lastName ? 'Last name is required' : ''
            });
            return;
        }

        setLoading(true);

        try {
            // Step 1: Call the addclient API first
            const addClientBody = {
                "client_code": clientId,
                "client_name": clientId,
                "client_domain_name": "https://verify.evaluationz.com:300/api/storeWebhook",
                "api_access_token": "token"
            };

            const addClientResponse = await axios.post(iCatchURL + "case/addclient", addClientBody);

            if (addClientResponse.data.success === true) {
                console.log('Client added successfully:', addClientResponse.data.data);

                // Step 2: call the createcase API
                const requestBody = {
                    first_name: firstName,
                    last_name: lastName,
                    email_address: email,
                    mobile_number: mobileNo,
                    client_code: clientId
                };

                const createCaseResponse = await axios.post(iCatchURL + "case/createcase", requestBody);

                if (createCaseResponse.data.success === true) {
                    console.log('Case Created Successfully:', createCaseResponse.data.data.case_id);
                    setCaseId(createCaseResponse.data.data.case_id);
                    handleNext();
                    setLoading(false);
                } else {
                    console.log('ERROR creating case:', createCaseResponse.data.errors);
                    setErrors({ apiError: createCaseResponse.data.errors });
                    swal({
                        title: 'Error!',
                        text: createCaseResponse.data.errors || 'Failed to create case. Please try again.',
                        icon: 'error',
                        button: "OK",
                        closeOnClickOutside: false,
                    });
                }
            } else {
                console.log('ERROR adding client:', addClientResponse.data.errors);
                setErrors({ apiError: addClientResponse.data.errors });
                swal({
                    title: 'Error!',
                    text: addClientResponse.data.errors || 'Failed to add client. Please try again.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            }
        } catch (error) {
            console.error('Network error or API call failed:', error);
            setErrors({ apiError: 'Something went wrong. Please try again later.' });
            swal({
                title: 'Error!',
                text: 'Something went wrong. Please try again.',
                icon: 'error',
                button: "OK",
                closeOnClickOutside: false,
            });
        } finally {
            setLoading(false);
        }
    };

    const uploadGovernmentDocumentToS3 = (file) => {
        const loggedPlatformID = cookies.clientPlatformID;
        const formData = new FormData();
        const fileExtension = file.name.split('.').pop();
        formData.append('platform_id', loggedPlatformID);
        formData.append('client_code', clientId);
        formData.append('case_id', caseId);
        formData.append('documentName', `governmentID.${fileExtension}`);
        formData.append('document', file);

        return axios.post(expressURL + "api/document-upload/upload", formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
    };

    // Function to handle file upload change
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setDocumentFile(file);

        if (file) {
            setDocumentFile(file);
            setErrors((prevErrors) => ({
                ...prevErrors,
                documentFile: ''
            }));
            setLoading(true);
            uploadGovernmentDocumentToS3(file)
                .then((response) => {
                    const s3DocumentUrl = response.data.url;
                    console.log("File uploaded to S3 successfully:", s3DocumentUrl);

                    setS3DocumentUrl(s3DocumentUrl);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error("Error uploading file to S3:", error);
                    setErrors({ documentFile: "Failed to upload file. Please try again." });
                    swal({
                        title: 'Error!',
                        text: 'Failed to upload file. Please try again.',
                        icon: 'error',
                        button: "OK",
                        closeOnClickOutside: false,
                    });
                });
        }
    };

    const handleSubmitStep1 = (event) => {
        // Step 1
        event.preventDefault();
        /*if (documentType && !documentFile) {
            setErrors({ documentFile: "Please upload a document file" });
            return;
        }*/
        /*if (!documentType || !documentFile) {
            setErrors({
                documentType: !documentType ? 'Document type is required' : '',
                documentFile: !documentFile ? 'Document file is required' : ''
            });
            return;
        }*/

        let docType = "";
        switch (documentType) {
            case "GP": // PAN
                docType = "GP";
                break;
            case "DV": // Voter ID
                docType = "DV";
                break;
            case "GD": // Driving License
                docType = "GD";
                break;
            default:
                docType = "";
                break;
        }

        const requestBody = {
            case_id: caseId,
            document_url: s3DocumentUrl,
            document_type: 'PID'
        };
        setLoading(true);
        if (documentFile) {
            axios.post(iCatchURL + "case/uploaddocumentstocase", requestBody)
                .then((response) => {
                    if (response.data.success === true) {
                        console.log('Document uploaded successfully:', response.data);
                        handleNext();
                        setLoading(false);
                    } else {
                        setLoading(false);
                        console.error('Error uploading document:', response.data.errors);
                        setErrors({ apiError: response.data.errors });
                        swal({
                            title: 'Error!',
                            text: response.data.errors || 'Something went wrong.',
                            icon: 'error',
                            button: "OK",
                            closeOnClickOutside: false,
                        });
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    setLoadingValidation(false);
                    console.error('Error uploading document:', error);
                    setErrors({ apiError: 'Failed to upload document. Please try again later.' });
                    swal({
                        title: 'Error!',
                        text: 'Something went wrong while uploading the document.',
                        icon: 'error',
                        button: "OK",
                        closeOnClickOutside: false,
                    });
                });
        } else {
            handleNext()
            setLoading(false);
        }
    };

    /*const handleSubmitStep2= (event) => {
        // Step 2
        event.preventDefault();

        if (!documentSourceFile) {
            setErrors({ documentFile: "Please upload a video file before saving." });
            return;  // Stop execution if no file is selected
        }

        let docType = "S";

        console.log("caseId", caseId);

        const requestBody = {
            case_id: caseId,
            document_url: documentSourceFile,
            document_type: docType
        };

        axios.post(iCatchURL + "case/uploaddocumentstocase", requestBody)
            .then((response) => {
                console.log("Upload ID DOCUMENT response", response)
                if (response.data.success === true) {
                    console.log('Document uploaded successfully:', response.data);
                    handleNext(); // Move to the next step
                } else {
                    console.error('Error uploading document:', response.data.errors);
                    setErrors({ apiError: response.data.errors });
                }
            })
            .catch((error) => {
                console.error('API call failed:', error);
                setErrors({ apiError: 'Failed to upload document. Please try again later.' });
                swal({
                    title: 'Error!',
                    text: 'Something went wrong while uploading the document.',
                    icon: 'error',
                    button: "OK",
                });
            });
    };*/

    const uploadSourceFileToS3 = (file) => {
        const loggedPlatformID = cookies.clientPlatformID;

        // Prepare form data for S3 upload
        const formData = new FormData();
        formData.append("platform_id", loggedPlatformID);
        formData.append("client_code", clientId);
        formData.append("case_id", caseId);
        formData.append("documentName", `sourceVideo.mp4`);
        formData.append("document", file);
        setLoading(true);

        axios.post(expressURL + "api/document-upload/upload", formData, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then((response) => {
                setS3SourceDocumentUrl(response.data.url);
                /*if (response.data) {
                    console.log("Video uploaded successfully to S3:", response.data);
                    setS3SourceDocumentUrl(response.data.url)
                } else {
                    throw new Error("Video upload to S3 failed.");
                }*/
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error uploading video to S3:", error);
                setErrors({ apiError: "Failed to upload. Please try again later." });
                swal({
                    title: 'Error!',
                    text: 'Failed to upload video. Please try again later.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            });
    };

    /*const handleSubmitStep2 = (event) => {
        event.preventDefault();

        if (!s3SourceDocumentUrl) {
            setLoading(false);
            setErrors({ documentSourceFile: "Please upload a video file before saving." });
            return;
        }

        const requestBody = {
            case_id: caseId,
            document_url: s3SourceDocumentUrl,
            document_type: "S"
        };
        setLoading(true);
        axios.post(iCatchURL + "case/uploaddocumentstocase", requestBody)
            .then((response) => {
                if (response.data.success === true) {
                    console.log("Document uploaded successfully:", response.data);
                    //handleNext();
                    setLoading(false);
                } else {
                    setLoading(false);
                    console.error("Error uploading document:", response.data.errors);
                    setErrors({ apiError: response.data.errors });
                    swal({
                        title: 'Error!',
                        text: response.data.errors || 'Something went wrong.',
                        icon: 'error',
                        button: "OK",
                    });

                }
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error:", error);
                setErrors({ apiError: "Failed to upload document. Please try again later." });
                swal({
                    title: 'Error!',
                    text: 'Failed to upload document. Please try again later.',
                    icon: 'error',
                    button: "OK",
                });
            });
    };*/

    /*const fetchConsumptionDetails = async () => {
        setLoading(true);

        const requestBody = {
            client_id: "107",
            case_id: caseId,
            what_is_consumed: "SCAN_WOF"
        };

        try {
            const response = await axios.post(expressURL + "api/validation/consumption-details", requestBody);
            setConsumptionDetails(response.data);
        } catch (error) {
            console.error("Error fetching consumption details:", error);
            //setError("Failed to fetch consumption details. Please try again later.");
            setLoading(false);
        }
    };*/

    const fetchConsumptionDetails = async (documentId) => {
        console.log('documentId fetchConsumptionDetails',documentId)
        try {
            const requestBody = {
                client_id: clientId,
                case_id: caseId,
                what_is_consumed: "SCAN_VAL",
                document_id : documentId
            };
            let response = await axios.post(expressURL + "api/validation/consumptiondetails", requestBody);
            console.log('Consumption API', response);
            let data = response.data;
            setConsumptionDetails(data);
            return data;
        } catch (error) {
            console.error('Error fetching consumption details:', error);
            return null; // Return null on error to handle it in the polling function
        }
    };

    // const handleSubmitStep2 = async (event) => {
    //     event.preventDefault();
    //
    //     if (!s3SourceDocumentUrl) {
    //         setLoading(false);
    //         setErrors({ documentSourceFile: "Please upload a video file before saving." });
    //         return;
    //     }
    //
    //     const requestBody = {
    //         case_id: caseId,
    //         document_url: s3SourceDocumentUrl,
    //         document_type: "S"
    //     };
    //
    //     setLoading(true);
    //
    //     try {
    //         const uploadResponse = await axios.post(iCatchURL + "case/uploaddocumentstocase", requestBody);
    //
    //         if (uploadResponse.data.success === true) {
    //             console.log("Document uploaded successfully:", uploadResponse.data);
    //             let documentId = uploadResponse.data.document_id;
    //             console.log('documentId first',documentId)
    //             const validationBody = {
    //                 case_id: caseId,
    //                 document_url: s3SourceDocumentUrl,
    //                 document_type: "S"
    //             };
    //
    //             const validateResponse = await axios.post(iCatchURL + "case/validatedocument", validationBody);
    //
    //             console.log("validateResponse step2:", validateResponse);
    //
    //             if (validateResponse.data.success === true && validateResponse.data.message === "Document validation initiated Successfully !!!") {
    //                 console.log("Document validated successfully:", validateResponse.data);
    //                 console.log('documentId',documentId)
    //                 if(documentId) {
    //                     const intervalId = setInterval(async () => {
    //                         try {
    //                             console.log("Checking consumption details...", documentId);
    //                             const consumptionData = await fetchConsumptionDetails(documentId);
    //                             if (consumptionData && consumptionData.consumption_id && consumptionData.webhook) {
    //                                 console.log("Consumption data found:", consumptionData.webhook);
    //                                 const webhookData = JSON.parse(consumptionData.webhook);
    //                                 console.log("Parsed Webhook Data:", webhookData);
    //                                 if (webhookData.errors) {
    //                                     setValidation({
    //                                         isValid: false,
    //                                         message: webhookData.errors
    //                                     });
    //                                 } else {
    //                                     handleNext();
    //                                 }
    //                                 clearInterval(intervalId); // Stop polling when data is available
    //                                 setLoading(false);
    //                             } else {
    //                                 console.log("No consumption data available yet, will check again in 1 minute.");
    //                             }
    //                             console.log('consumptionData', consumptionData);
    //                         } catch (intervalError) {
    //                             console.error("Error inside interval when fetching consumption details:", intervalError);
    //                             clearInterval(intervalId); // Stop polling on error
    //                             setLoading(false);
    //                             setErrors({apiError: "Failed to fetch consumption details. Please try again later."});
    //                             swal({
    //                                 title: 'Error!',
    //                                 text: 'An error occurred while fetching consumption details. Please try again later.',
    //                                 icon: 'error',
    //                                 button: "OK",
    //                             });
    //                         }
    //                     }, 6000);
    //                 } else {
    //                     setLoading(false);
    //                     console.error("Error validating document:", validateResponse.data.errors);
    //                     setErrors({ apiError: validateResponse.data.errors });
    //                     swal({
    //                         title: 'Validation Error!',
    //                         text: validateResponse.data.errors || 'Something went wrong during document validation.',
    //                         icon: 'error',
    //                         button: "OK",
    //                     });
    //                 }
    //                 // setLoading(false);
    //                 // handleNext();
    //             } else {
    //                 setLoading(false);
    //                 console.error("Error validating document:", validateResponse.data.errors);
    //                 setErrors({ apiError: validateResponse.data.errors });
    //                 swal({
    //                     title: 'Validation Error!',
    //                     text: validateResponse.data.errors || 'Something went wrong during document validation.',
    //                     icon: 'error',
    //                     button: "OK",
    //                 });
    //             }
    //         } else {
    //             setLoading(false);
    //             console.error("Error uploading document:", uploadResponse.data.errors);
    //             setErrors({ apiError: uploadResponse.data.errors });
    //             swal({
    //                 title: 'Upload Error!',
    //                 text: uploadResponse.data.errors || 'Something went wrong during document upload.',
    //                 icon: 'error',
    //                 button: "OK",
    //             });
    //         }
    //     } catch (error) {
    //         setLoading(false);
    //         console.error("Error during process:", error);
    //         setErrors({ apiError: "Failed to complete the process. Please try again later." });
    //         swal({
    //             title: 'Error!',
    //             text: 'Failed to complete the process. Please try again later.',
    //             icon: 'error',
    //             button: "OK",
    //         });
    //     }
    // };

    const fillProgressBar = async () => {
        return new Promise((resolve) => {
            const interval = setInterval(() => {
                setLoadingProgress((prev) => {
                    if (prev >= 100) {
                        clearInterval(interval); // Stop at 100%
                        resolve(); // Resolve the promise when complete
                        return 100;
                    }
                    return prev + 1; // Increment progress bar
                });
            }, 20); // Adjust the time as needed for smoother progress
        });
    };

    const handleSubmitStep2 = async (event) => {
        event.preventDefault();

        if (!s3SourceDocumentUrl) {
            setLoading(false);
            setErrors({ documentSourceFile: "Please upload a video file before saving." });
            return;
        }

        const requestBody = {
            case_id: caseId,
            document_url: s3SourceDocumentUrl,
            document_type: "S"
        };

        setLoadingValidation(true); // Start loading
        setLoadingProgress(0); // Reset progress
        setLoading(true);
        try {
            const uploadResponse = await axios.post(iCatchURL + "case/uploaddocumentstocase", requestBody);
            console.log("uploadResponse.data.document_id OUT", uploadResponse.data.data.document_id)
            if (uploadResponse.data.success === true) {
                let documentId = uploadResponse.data.data.document_id;
                console.log("uploadResponse.data.document_id", uploadResponse)
                console.log("documentId", documentId)
                const validationBody = {
                    case_id: caseId,
                    document_url: s3SourceDocumentUrl,
                    document_type: "S"
                };
                const validateResponse = await axios.post(iCatchURL + "case/dovalidate", validationBody);
                setLoading(false);
                console.log("validateResponse", validateResponse)
                if (validateResponse.data.success === true) {
                    const firstElementWithNoUrl = interviewRoundsRef.current.find(round => round.s3TargetDocumentUrl === null);
                    console.log('firstElementWithNoUrl',firstElementWithNoUrl);
                    console.log('activeStep',activeStep)
                    if (activeStep === 2 && firstElementWithNoUrl !== undefined) {
                        setActiveStep(firstElementWithNoUrl.id + 2);
                    } else if(validation.isValid) {
                        verify(true);
                    }

                    console.log("Document validation initiated successfully.", validateResponse);
                    /*if (validation.isValid && validation.message && validation.message!== 'Validated') {
                        handleOpenErrorModal()
                    }*/
                    console.log("documentId", documentId);
                    if (documentId) {
                        // startValidationProgress(documentId);
                        const maxLoadingTime = (videoDuration + 180) * 1000;
                        const startTime = Date.now();

                        const intervalId = setInterval(() => {
                            const elapsedTime = Date.now() - startTime;
                            const progressPercentage = Math.min((elapsedTime / maxLoadingTime) * 98, 100);
                            setLoadingProgress(progressPercentage);

                            if (elapsedTime >= maxLoadingTime) {
                                clearInterval(intervalId);
                            }
                        }, 200);

                        const fetchIntervalId = setInterval(async () => {
                            try {
                                const consumptionData = await fetchConsumptionDetails(documentId);
                                console.log("consumptionData", consumptionData)
                                if (consumptionData && consumptionData.consumption_id && consumptionData.webhook) {
                                    const webhookData = JSON.parse(consumptionData.webhook);
                                    console.log("consumptionData.webhook", consumptionData.webhook)
                                    console.log("webhookData", webhookData);
                                    setIsVerified(true);
                                    clearInterval(fetchIntervalId);
                                    clearInterval(intervalId);

                                    await fillProgressBar();

                                    if (webhookData.errors) {
                                        console.log("webhookData.errors", webhookData.errors)
                                        // console.log("webhookData.errors if", webhookData.errors)
                                        setValidation({
                                            isValid: false,
                                            message: webhookData.errors
                                        });
                                        // console.log("validation.isValid validation.message", webhookData);
                                        // console.log("validation.isValid validation.message", validation.message);

                                        if (webhookData.errors && webhookData.errors!== 'Validated') {
                                            console.log("INSIDE IF IN IF")
                                            console.log("validation.isValid", validation.isValid);
                                            /*setValidatedSteps((prev) => ({
                                                ...prev,
                                                [activeStep]: false, // Mark the current step as not validated
                                            }));*/
                                            setErrorValidatedSteps((prev) => ({
                                                ...prev,
                                                [activeStep]: false, // Mark the current step as not validated
                                            }));

                                            handleOpenErrorModal()
                                            setActiveStep(2);
                                        }
                                    } else {
                                        setValidation({
                                            isValid: true,
                                            message: webhookData.message
                                        });
                                        setValidatedSteps((prev) => ({
                                            ...prev,
                                            [activeStep]: true,
                                        }));
                                        const firstElementWithNoUrl = interviewRoundsRef.current.find(round => round.s3TargetDocumentUrl === null);
                                       console.log('firstElementWithNoUrl',firstElementWithNoUrl);
                                       console.log('activeStep',activeStep)
                                        if (activeStep === 2 && firstElementWithNoUrl !== undefined) {
                                            console.log("i am in.!!")
                                            setActiveStep(firstElementWithNoUrl.id + 2);
                                        } else {
                                            verify(true);
                                        }
                                    }
                                    setLoadingValidation(false);
                                }
                            } catch (error) {
                                clearInterval(fetchIntervalId); // Stop polling on error
                                clearInterval(intervalId); // Stop loader progress
                                setLoadingValidation(false); // Stop loader
                                setErrors({ apiError: "Failed to fetch consumption details. Please try again later." });
                                swal({
                                    title: 'Error!',
                                    text: 'An error occurred while fetching consumption details. Please try again later.',
                                    icon: 'error',
                                    button: "OK",
                                });
                            }
                        }, 15000);

                    } else {
                        // handleValidationError("Document ID not found after validation.");
                        setLoadingValidation(false);
                        setLoading(false);
                        setErrors({ apiError: validateResponse.data.errors });
                        console.log("Validation Error!1")
                        swal({
                            title: 'Validation Error!',
                            text: validateResponse.data.errors || 'Something went wrong during document validation.',
                            icon: 'error',
                            button: "OK",
                        });
                    }
                } else {
                    setLoadingValidation(false);
                    setLoading(false);
                    setErrors({ apiError: validateResponse.data.errors });
                    console.log("Validation Error!2")
                    swal({
                        title: 'Validation Error!',
                        text: validateResponse.data.errors || 'Something went wrong during document validation.',
                        icon: 'error',
                        button: "OK",
                    });
                }
            } else {
                setLoadingValidation(false);
                setLoading(false);
                setErrors({ apiError: uploadResponse.data.errors });
                swal({
                    title: 'Upload Error!',
                    text: uploadResponse.data.errors || 'Something went wrong during document upload.',
                    icon: 'error',
                    button: "OK",
                });
            }
        } catch (error) {
            // handleGeneralError(error);
            setLoadingValidation(false);
            setLoading(false);
            setErrors({ apiError: "Failed to complete the process. Please try again later." });
            swal({
                title: 'Error!',
                text: error,
                icon: 'error',
                button: "OK",
            });
        }
    };


    /*const handleUploadError = (errorMessage) => {
        setLoadingValidation(false);
        setLoading(false);
        setErrors({ apiError: errorMessage || "Error occurred during document upload." });
        swal({ title: 'Upload Error!', text: errorMessage, icon: 'error', button: "OK" });
    };

    const handleValidationError = (errorMessage) => {
        setLoadingValidation(false);
        setLoading(false);
        setErrors({ apiError: errorMessage || "Error occurred during document validation." });
        swal({ title: 'Validation Error!', text: errorMessage, icon: 'error', button: "OK" });
    };

    const handleGeneralError = (error) => {
        setLoadingValidation(false);
        setLoading(false);
        setErrors({ apiError: "Failed to complete the process. Please try again later." });
        swal({ title: 'Error!', text: error.message || "An unexpected error occurred.", icon: 'error', button: "OK" });
    };*/

    const uploadTargetDocumentToS3 = (file) => {
        const loggedPlatformID = cookies.clientPlatformID;
        const formData = new FormData();
        const fileExtension = file.name.split('.').pop();
        formData.append('platform_id', loggedPlatformID);
        formData.append('client_code', clientId);
        formData.append('case_id', caseId);
        formData.append('documentName', `${file.name}.${fileExtension}`); // Fixed name
        formData.append('document', file);

        return axios.post(expressURL + "api/document-upload/upload", formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
    };

    // Function to handle target file upload change
    const handleTargetFileChange = (event) => {
        const file = event.target.files[0];
        setTargetDocumentFile(file);

        // Clear file upload errors
        if (file) {
            setTargetDocumentFile(file);
            setErrors((prevErrors) => ({
                ...prevErrors,
                documentFile: ''
            }));
            setLoading(true);
            uploadTargetDocumentToS3(file)
                .then((response) => {
                    const S3TargetDocumentUrl = response.data.url;
                    console.log("File uploaded to S3 successfully:", S3TargetDocumentUrl);
                    setS3TargetDocumentUrl(S3TargetDocumentUrl);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error("Error uploading file to S3:", error);
                    setErrors({ documentFile: "Failed to upload file. Please try again." });
                    swal({
                        title: 'Error!',
                        text: 'Failed to upload file. Please try again.',
                        icon: 'error',
                        button: "OK",
                    });

                });
        }
    };

    /*handleSubmitStep3 with doverify(old works fine)*/
    /*const handleSubmitStep3 = async (index) => {
        // event.preventDefault();
        handleAddClose();
        // if (!s3TargetDocumentUrl) {
        //     setLoading(false);
        //     setErrors({ documentSourceFile: "Please upload a video file before saving." });
        //     return;
        // }

        const round = interviewRounds[index];
        if (!round.s3TargetDocumentUrl) {
            setLoading(false);
            const updatedRounds = [...interviewRounds];
            updatedRounds[index].errors = { targetDocumentFile: "Please upload a video file before saving." };
            setInterviewRounds(updatedRounds);
            return;
        }
        const requestBody = {
            case_id: caseId,
            document_url: round.s3TargetDocumentUrl,
            document_type: "A"
        };
        setLoading(true);
        try {
            const uploadResponse = await axios.post(iCatchURL + "case/uploaddocumentstocase", requestBody);
            console.log("Document uploaded successfully:", uploadResponse.data);
            if (uploadResponse.data.success === true) {
                console.log("Document uploaded successfully:", uploadResponse.data);

                const roundId = `Interview Round ${interviewRoundCount}`;
                const verificationBody = {
                    case_id: caseId,
                    verify: [
                        {
                            type: "SCAN_WB",
                            round_id: roundId,
                            interviewDateTime: new Date().toISOString(),
                            interviewerName: ["Sample Interviewer"],
                            target_url: round.s3TargetDocumentUrl
                        }
                    ]
                };

                const verifyResponse = await axios.post(iCatchURL + "case/doverify", verificationBody);
                console.log("verifyResponse DOVERIFY:", verifyResponse);
                if (verifyResponse.data.success === true && verifyResponse.data.message === "Verification initiated successfully") {
                    // await axios.post(expressURL + "api/validation/setduration",{ "s3Url" : round.s3TargetDocumentUrl, "consumptionId" : verifyResponse.data.data.consumption_id})
                    console.log("Verification initiated successfully:", verifyResponse.data);
                    setLoading(false);
                    handleAddOpen();
                    // handleNext();
                } else {
                    setLoading(false);
                    setLoadingValidation(false);
                    /!*throw new Error(verifyResponse.data.errors || 'Error initiating verification.');*!/
                    swal({
                        title: 'Error!',
                        text: verifyResponse.data.errors || 'Error initiating verification.',
                        icon: 'error',
                        button: "OK",
                    });
                }

            } else {
                setLoadingValidation(false);
                setLoading(false);
                // throw new Error(uploadResponse.data.errors || 'Error uploading document.');
                swal({
                    title: 'Error!',
                    text: uploadResponse.data.errors || 'Error initiating verification.',
                    icon: 'error',
                    button: "OK",
                });
            }

        } catch (error) {
            setLoadingValidation(false);
            setLoading(false);
            console.error("Error:", error);
            swal({
                title: 'Error!',
                text: error.message || 'Process failed. Please try again later.',
                icon: 'error',
                button: "OK",
            });
            setErrors({ apiError: error.message || "Process failed. Please try again later." });
        }
    };*/

    const handleSubmitStep3 = async (index) => {
        const round = interviewRounds[index];
        if (!round.s3TargetDocumentUrl) {
            setLoading(false);
            const updatedRounds = [...interviewRounds];
            updatedRounds[index].errors = { targetDocumentFile: "Please upload a video file before saving." };
            setInterviewRounds(updatedRounds);
            return;
        }
        const requestBody = {
            case_id: caseId,
            document_url: round.s3TargetDocumentUrl,
            document_type: "A"
        };
        setLoading(true);
        try {
            const uploadResponse = await axios.post(iCatchURL + "case/uploaddocumentstocase", requestBody);
            console.log("Document uploaded successfully:", uploadResponse.data);
            if (uploadResponse.data.success === true) {
                const updatedUrls = [...uploadedDocumentUrls, round.s3TargetDocumentUrl];
                setUploadedDocumentUrls(updatedUrls);  // Store the uploaded document URL
                setLoading(false);
                handleAddOpen();  // Open modal to ask if user wants another round
            } else {
                setLoading(false);
                swal({
                    title: 'Error!',
                    text: uploadResponse.data.errors || 'Error uploading document.',
                    icon: 'error',
                    button: "OK",
                });
            }
        } catch (error) {
            setLoading(false);
            swal({
                title: 'Error!',
                text: error.message || 'Process failed. Please try again later.',
                icon: 'error',
                button: "OK",
            });
        }
    };

    const verify = async (isValid) => {
        setAddOpen(false);
        try {
            if (loadingValidation) {
                setActiveStep(2);
                setVerifyingOpen(true);
            }
            console.log("validation.isValid", validation.isValid)
            if (validation.isValid === true || isValid === true) {
                console.log('interviewRoundsRef',interviewRoundsRef.current);
                for (const round of interviewRoundsRef.current) {
                    console.log('round',round)
                    const verificationBody = {
                        case_id: caseId,
                        verify: [
                            {
                                type: "SCAN_WB",
                                round_id: `Interview Round`,  // Adjust with your round logic
                                //interviewDateTime: new Date().toISOString(),
                                interviewDateTime: new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" }),
                                interviewerName: ["Sample Interviewer"],  // Modify as needed
                                target_url: round.s3TargetDocumentUrl
                            }
                        ]
                    };

                    await axios.post(iCatchURL + "case/doverify", verificationBody);
                }
                setActiveStep(stepsRef.current.length - 1);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            //setIsDoVerified(false);
            console.error("Verification error:", error);
            swal({
                title: 'Error!',
                text: 'Verification failed. Please try again later.',
                icon: 'error',
                button: "OK",
            });
        }
    };

    /*const verify = async () => {
        setAddOpen(false);
        setLoading(true);
        try {
            // Loop through all uploaded document URLs and call the verify API
            for (const targetUrl of uploadedDocumentUrls) {
                const verificationBody = {
                    case_id: caseId,
                    verify: [
                        {
                            type: "SCAN_WB",
                            round_id: `Interview Round`,  // Adjust with your round logic
                            interviewDateTime: new Date().toISOString(),
                            interviewerName: ["Sample Interviewer"],  // Modify as needed
                            target_url: targetUrl
                        }
                    ]
                };
                const verifyResponse = await axios.post(iCatchURL + "case/doverify", verificationBody);
                console.log("Verification Response:", verifyResponse.data);
                if (!(verifyResponse.data.success === true && verifyResponse.data.message === "Verification initiated successfully")) {
                    swal({
                        title: 'Error!',
                        text: verifyResponse.data.errors || 'Error initiating verification.',
                        icon: 'error',
                        button: "OK",
                    });
                    break;
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Verification error:", error);
            swal({
                title: 'Error!',
                text: 'Verification failed. Please try again later.',
                icon: 'error',
                button: "OK",
            });
        }
    };*/

    /*const handleSubmitStep2 = (event) => {
        event.preventDefault();
        const loggedPlatformID = cookies.clientPlatformID;

        if (!documentSourceFile) {
            setErrors({ documentSourceFile: "Please upload a video file before saving." });
            return;
        }

        // Prepare the form data for video upload to S3
        const formData = new FormData();
        formData.append("platform_id", loggedPlatformID);
        formData.append("client_code", 107);
        formData.append("case_id", caseId);  // Get case ID from previous steps
        formData.append("documentName", `sourceVideo.${documentSourceFile.name.split('.').pop()}`);
        formData.append("document", documentSourceFile);

        axios.post(expressURL + "api/document-upload/upload", formData, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then((response) => {
                console.log("Source UPLOAD", response)
                if (response.data.success) {
                    console.log("Video uploaded successfully to S3:", response.data);
                    const documentUrl = response.data.url; // S3 URL

                    // Now you can send the document_url and document_type (S) in your next API call
                    const requestBody = {
                        case_id: caseId,
                        document_url: documentUrl,
                        document_type: "S"
                    };

                    return axios.post(iCatchURL + "case/uploaddocumentstocase", requestBody);
                } else {
                    throw new Error("Video upload failed");
                }
            })
            .then(() => {
                handleNext();  // Proceed to the next step
            })
            .catch((error) => {
                console.error("Error:", error);
                setErrors({ apiError: "Failed to upload video. Please try again later." });
            });
    };*/

    // Function to render content based on step
    function getStepContent(stepIndex) {
        if (activeStep === stepIndex) {
            if (steps[stepIndex].includes('Interview Round')) {
                const interviewStartIndex = steps.findIndex(step => step.includes("Interview Round")) ;
                const roundIndex = stepIndex - interviewStartIndex;
                // Ensure roundIndex is valid
                if (roundIndex >= 0 && roundIndex <= interviewRounds.length) {
                    const round = interviewRounds[roundIndex];
                    return (
                        // interviewRounds.map((round, index) => (
                        <div key={round.id}>
                            <p className="f-14">Upload target documents for Interview Round {round.id}</p>
                            <form className="login-form profile-settings">
                                <div className="upload-target-container">
                                    <div className="col-md-12 mb-5">
                                        <label className="f-12">Upload Document</label>
                                        <div className="form-group upload-container">
                                            <div className="custom-file-input-container">
                                                <input
                                                    type="text"
                                                    placeholder="Add File URL"
                                                    className="file-url-input"
                                                    readOnly
                                                    value={round.targetDocumentFile ? round.targetDocumentFile.name : ''}
                                                />
                                                <label htmlFor={`file-upload-${round.id}`} className="upload-label">
                                                    <UploadOutlinedIcon className="upload-icon"/>
                                                    Upload
                                                </label>
                                                <input
                                                    type="file"
                                                    id={`file-upload-${round.id}`}
                                                    className="file-upload-input"
                                                    accept="video/mp4"
                                                    onChange={(e) => handleTargetFileChangeInterview(roundIndex, e)}
                                                />
                                            </div>
                                            {round.errors.targetDocumentFile && (
                                                <div className="error-message c-red f-12">
                                                    {round.errors.targetDocumentFile}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/*<div className="button-container d-flex justify-content-end">*/}
                                    {/*    <button className="btn py-0 save-video" type="submit">Save</button>*/}
                                    {/*</div>*/}
                                </div>
                            </form>
                            {roundIndex >= 1 && (
                                <div className="button-container d-flex justify-content-end mt-2">
                                    <button
                                        className="btn py-0 skip-round forgot-password-link f-14"
                                        type="button"
                                        onClick={handleSkipRound}
                                    >
                                        <KeyboardDoubleArrowRightIcon /> Skip Interview Round {roundIndex + 1}
                                    </button>
                                </div>
                            )}
                        </div>
                        // ))
                        // <div>
                        //     <p className="f-14">In this step, you will upload target documents for each
                        //         interview round.</p>
                        //     <div className="interview-round-hd">
                        //         <FileUploadOutlinedIcon/> Upload Video
                        //     </div>
                        //     <form className="login-form profile-settings" onSubmit={handleSubmitStep3}>
                        //         <div className="upload-target-container">
                        //             <div className="col-md-12 mb-5">
                        //                 <label className="f-12">Upload Document</label>
                        //                 <div className="form-group upload-container">
                        //                     <div className="custom-file-input-container">
                        //                         <input
                        //                             type="text"
                        //                             placeholder="Add File URL"
                        //                             className="file-url-input"
                        //                             readOnly
                        //                             value={targetDocumentFile ? targetDocumentFile.name : ''}
                        //                         />
                        //                         <label htmlFor="file-upload" className="upload-label">
                        //                             <UploadOutlinedIcon className="upload-icon"/>
                        //                             Upload
                        //                         </label>
                        //                         <input
                        //                             type="file"
                        //                             id="file-upload"
                        //                             className="file-upload-input"
                        //                             accept="video/mp4"
                        //                             onChange={handleTargetFileChange}
                        //                         />
                        //                     </div>
                        //                     {errors.targetDocumentFile && (
                        //                         <div className="error-message c-red f-12">
                        //                             {errors.targetDocumentFile}
                        //                         </div>
                        //                     )}
                        //                 </div>
                        //             </div>
                        //             <div className="button-container d-flex justify-content-end">
                        //                 <button className="btn btn-add-interview py-0 save-video" onClick={handleAddInterviewRound}>
                        //                     <AddOutlinedIcon/> Add Interview
                        //                 </button>
                        //                 <button className="btn btn-primary py-0 save-video" type="submit">Save</button>
                        //             </div>
                        //         </div>
                        //     </form>
                        // </div>
                    );
                }
            }
        }
        switch (stepIndex) {
            case 0:
                return (
                    <div>
                        <p className="f-14">Start by creating a new case for the fraud detection process.</p>
                        <div className="mt-4">
                            <form className="login-form profile-settings" onSubmit={handleSubmitStep0}>
                                <div className="col-md-12 p-0 mb-3">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="f-12">Candidate First Name<span className="c-red">*</span></label>
                                            <TextField
                                                fullWidth
                                                placeholder="Enter candidate first name"
                                                value={firstName}
                                                onChange={event => setFirstName(event.target.value)}
                                                variant="standard"
                                                className="mb-3 profile-input"
                                            />
                                            {errors.firstName && <div className="error-message c-red f-12">{errors.firstName}</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="f-12">Candidate Middle Name</label>
                                            <TextField
                                                fullWidth
                                                placeholder="Enter candidate middle name"
                                                value={middleName}
                                                onChange={event => setMiddleName(event.target.value)}
                                                variant="standard"
                                                className="profile-input"
                                            />
                                            {/*{errors.lastName && <div className="error-message c-red f-12">{errors.lastName}</div>}*/}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 p-0 mb-3">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="f-12">Candidate Last Name<span className="c-red">*</span></label>
                                            <TextField
                                                fullWidth
                                                placeholder="Enter candidate last name"
                                                value={lastName}
                                                onChange={event => setLastName(event.target.value)}
                                                variant="standard"
                                                className="mb-3 profile-input"
                                            />
                                            {errors.lastName && <div className="error-message c-red f-12">{errors.lastName}</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="f-12">Candidate Email ID</label>
                                            <TextField
                                                fullWidth
                                                placeholder="Enter candidate Email ID"
                                                value={email}
                                                onChange={event => setEmail(event.target.value)}
                                                variant="standard"
                                                className="profile-input"
                                            />
                                            {errors.email && <div className="error-message c-red f-12">{errors.email}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 p-0 mb-3">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="f-12">Candidate Mobile Number</label>
                                            <TextField
                                                fullWidth
                                                placeholder="Enter candidate Mobile Number"
                                                value={mobileNo}
                                                onChange={event => setMobileNo(event.target.value)}
                                                variant="standard"
                                                className="mb-3 profile-input"
                                            />
                                            {errors.mobileNo && <div className="error-message c-red f-12">{errors.mobileNo}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button type="submit" className="btn shadow-sm continue-btn">
                                        Continue
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div>
                        <p className="f-14">Upload an identification document for the case. This step is not mandatory but can help in the verification process.
                            <BootstrapTooltip title="Info">
                                <InfoOutlinedIcon fontSize="small" />
                            </BootstrapTooltip></p>
                        <div className="mt-4">
                            <form className="login-form profile-settings" onSubmit={handleSubmitStep1}>
                                <div className="col-md-12 p-0 mb-3">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="f-12">Document Type</label>
                                            <div className="form-group">
                                                <select
                                                    className="form-select form-select-sm"
                                                    value={documentType}
                                                    onChange={handleDocumentChange}
                                                    aria-label="Document select"
                                                    defaultValue=""
                                                >
                                                    <option disabled value="">
                                                        Select Document Type
                                                    </option>
                                                    <option value="GP">PAN</option>
                                                    <option value="GV">Voter ID</option>
                                                    <option value="GD">Driving License</option>
                                                </select>
                                                {errors.documentType && (
                                                    <div className="error-message c-red f-12">
                                                        {errors.documentType}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {documentType && (
                                            <div className="col-md-6">
                                                <label className="f-12">Upload Document</label>
                                                <div className="form-group upload-container">
                                                    <div className="custom-file-input-container">
                                                        <input
                                                            type="text"
                                                            placeholder="Add File URL"
                                                            className="file-url-input"
                                                            readOnly
                                                            value={documentFile ? documentFile.name : ''}
                                                        />
                                                        <label htmlFor="file-upload" className="upload-label">
                                                            <UploadOutlinedIcon className="upload-icon" />
                                                            Upload
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id="file-upload"
                                                            className="file-upload-input"
                                                            accept=".jpg, .jpeg, .png"
                                                            onChange={handleFileChange}
                                                        />
                                                    </div>
                                                    {errors.documentFile && (
                                                        <div className="error-message c-red f-12">
                                                            {errors.documentFile}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-12 p-0 d-flex justify-content-start mb-3 mt-3">
                                    <button type="submit" className="btn shadow-sm continue-btn">
                                        Continue
                                    </button>
                                </div>

                                {/*<div className="col-md-12 p-0 d-flex justify-content-start mb-3 mt-3">
                                    <button type="submit" className="btn btn-primary float-end shadow-sm">Continue</button>
                                </div>*/}
                            </form>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div>
                        {!loadingValidation && (
                            <div>
                                <p className="f-14">Upload or record the source document that needs to be verified.</p>
                                <div className="col-md-12 p-0 mb-3">
                                    <form className="login-form profile-settings">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="video-container">
                                                    {/*<img src="/images/videoRecordDefault.png" alt="Second Logo" className="video-record-image" />
                                            <button type="submit" className="btn btn-primary mt-3 py-0 save-video">Save</button>*/}
                                                    {videoPreview ? (
                                                        <video controls src={videoPreview} className="video-record-image" />
                                                    ) : (
                                                        <img src="/images/videoRecordDefault.png" alt="Second Logo" className="video-record-image" />
                                                    )}
                                                    {videoPreview && (
                                                        <div className="col-md-12 p-0 d-flex justify-content-end">
                                                            <button className="btn mt-3 py-0 continue-btn on-select" onClick={handleSubmitStep2}>Validate</button>
                                                        </div>
                                                    )}
                                                </div>

                                            </div>
                                            <div className="col-md-6 button-group m-auto  mx-auto align-content-center align-item-center justify-content-center">
                                                <div className="col-12">
                                                    <button className="btn record-btn w-100" onClick={handleOpen}><VideocamOutlinedIcon /> RECORD CONSENT VIDEO</button>
                                                </div>
                                                <div className="or-divider d-flex justify-content-center">
                                                    <span className="line"></span>
                                                    <span className="or-text my-3">OR</span>
                                                    <span className="line"></span>
                                                </div>
                                                <div className="col-12">
                                                    <div>
                                                        <input
                                                            type="file"
                                                            id="file-upload"
                                                            className="file-upload-input"
                                                            accept="video/mp4"
                                                            onChange={handleVideoFileChange}
                                                            style={{ display: "none" }}
                                                        />

                                                        <button
                                                            className="btn choose-btn w-100"
                                                            onClick={(event) => {
                                                                event.preventDefault(); // Prevent default form submission behavior
                                                               /* setValidation({
                                                                    isValid: true,
                                                                    message: ''
                                                                });*/
                                                                // Reset the file input to allow re-uploading the same file
                                                                document.getElementById('file-upload').value = null;
                                                                document.getElementById('file-upload').click();
                                                            }}>
                                                            <FileUploadOutlinedIcon /> CHOOSE A CONSENT VIDEO
                                                        </button>
                                                    </div>
                                                    {/*<button className="btn choose-btn w-100"><FileUploadOutlinedIcon /> CHOOSE A CONSENT VIDEO</button>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                        {/* Validation loader and message */}
                        {/*{loadingValidation && (
                            <div>
                                <div className="loading-modal">
                                    <div className="loading-content">
                                        <p className="f-14">Validating your video... This may take a few minutes.</p>
                                        <div className="progress">
                                            <div
                                                className="progress-bar progress-bar-striped progress-bar-animated"
                                                style={{ width: `${loadingProgress}%` }}
                                            >
                                                {loadingProgress === 100 ? 'Processing...' : 'Loading...'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button className="btn mt-3 py-0 continue-btn on-select" onClick={handleNext}>Continue</button>
                                </div>
                            </div>
                        )}*/}

                        {/*{!validation.isValid && validation.message && validation.message!== 'Validated' && (
                            <div>
                                <p className="f-14">Validation result</p>
                                <div
                                    className="col-md-12 py-3 validation-error-card mb-3 shadow-sm">
                                    <div className="error-hd">
                                        Error
                                    </div>
                                    <div className="success-sub-hd">
                                        <p><PriorityHighOutlinedIcon className="c-red f-12"/>  {validation.message} </p>
                                    </div>
                                    <div className="success-description pt-1 mb-3">Please address the errors above and upload a valid video.</div>
                                </div>
                            </div>
                        )}*/}
                    </div>
                );
            // case 3:
            //     if (steps[stepIndex].includes('Interview Round')) {
            //         return (
            //             interviewRounds.map((round, index) => (
            //                 <div key={round.id}>
            //                     <p className="f-14">Upload target documents for Interview Round {round.id}</p>
            //                     <form className="login-form profile-settings" onSubmit={(e) => handleSubmitStep3(e, index)}>
            //                         <div className="upload-target-container">
            //                             <div className="col-md-12 mb-5">
            //                                 <label className="f-12">Upload Document</label>
            //                                 <div className="form-group upload-container">
            //                                     <div className="custom-file-input-container">
            //                                         <input
            //                                             type="text"
            //                                             placeholder="Add File URL"
            //                                             className="file-url-input"
            //                                             readOnly
            //                                             value={round.targetDocumentFile ? round.targetDocumentFile.name : ''}
            //                                         />
            //                                         <label htmlFor={`file-upload-${round.id}`} className="upload-label">
            //                                             <UploadOutlinedIcon className="upload-icon" />
            //                                             Upload
            //                                         </label>
            //                                         <input
            //                                             type="file"
            //                                             id={`file-upload-${round.id}`}
            //                                             className="file-upload-input"
            //                                             accept="video/mp4"
            //                                             onChange={(e) => handleTargetFileChangeInterview(index, e)}
            //                                         />
            //                                     </div>
            //                                     {round.errors.targetDocumentFile && (
            //                                         <div className="error-message c-red f-12">
            //                                             {round.errors.targetDocumentFile}
            //                                         </div>
            //                                     )}
            //                                 </div>
            //                             </div>
            //                             <div className="button-container d-flex justify-content-end">
            //                                 <button className="btn btn-primary py-0 save-video" type="submit">Save</button>
            //                             </div>
            //                         </div>
            //                     </form>
            //                 </div>
            //             ))
            //             // <div>
            //             //     <p className="f-14">In this step, you will upload target documents for each
            //             //         interview round.</p>
            //             //     <div className="interview-round-hd">
            //             //         <FileUploadOutlinedIcon/> Upload Video
            //             //     </div>
            //             //     <form className="login-form profile-settings" onSubmit={handleSubmitStep3}>
            //             //         <div className="upload-target-container">
            //             //             <div className="col-md-12 mb-5">
            //             //                 <label className="f-12">Upload Document</label>
            //             //                 <div className="form-group upload-container">
            //             //                     <div className="custom-file-input-container">
            //             //                         <input
            //             //                             type="text"
            //             //                             placeholder="Add File URL"
            //             //                             className="file-url-input"
            //             //                             readOnly
            //             //                             value={targetDocumentFile ? targetDocumentFile.name : ''}
            //             //                         />
            //             //                         <label htmlFor="file-upload" className="upload-label">
            //             //                             <UploadOutlinedIcon className="upload-icon"/>
            //             //                             Upload
            //             //                         </label>
            //             //                         <input
            //             //                             type="file"
            //             //                             id="file-upload"
            //             //                             className="file-upload-input"
            //             //                             accept="video/mp4"
            //             //                             onChange={handleTargetFileChange}
            //             //                         />
            //             //                     </div>
            //             //                     {errors.targetDocumentFile && (
            //             //                         <div className="error-message c-red f-12">
            //             //                             {errors.targetDocumentFile}
            //             //                         </div>
            //             //                     )}
            //             //                 </div>
            //             //             </div>
            //             //             <div className="button-container d-flex justify-content-end">
            //             //                 <button className="btn btn-add-interview py-0 save-video" onClick={handleAddInterviewRound}>
            //             //                     <AddOutlinedIcon/> Add Interview
            //             //                 </button>
            //             //                 <button className="btn btn-primary py-0 save-video" type="submit">Save</button>
            //             //             </div>
            //             //         </div>
            //             //     </form>
            //             // </div>
            //         );
            //     }
            case 4:
                return (
                    <div>
                        <div className="start-verification-container text-center mt-3">
                            <CheckCircleOutlineOutlinedIcon className="success-check"/>
                            <p className="text-center">Verification initiated successfully!!!<br/>
                                Report will be available to download once the <br/>verification is completed under Candidate List tab</p>
                            <button className="btn mt-3 py-0 continue-btn on-select"
                                    onClick={() => navigate('/candidate-list')}>
                                Candidate list
                            </button>
                        </div>
                    </div>
                );
            default:
                return (
                    <div>
                        <div className="start-verification-container text-center mt-3">
                            <CheckCircleOutlineOutlinedIcon className="success-check"/>
                            <p className="text-center">Verification initiated successfully!!!<br/>
                                Report will be available to download once the <br/>verification is completed under Candidate List tab</p>
                            <button className="btn mt-3 py-0 continue-btn on-select"
                                    onClick={() => navigate('/candidate-list')}>
                                Candidate list
                            </button>
                        </div>
                    </div>
                );
        }
    }


    return (
        <>
            <LoadingOverlay active={loading} spinner text="Loading...">
                <Modal className="error-modal"
                       show={errorModalOpen}
                       onHide={handleCloseErrorModal}>
                    <Modal.Body>
                        <div>
                            <Modal.Title className="text-center">
                                <CancelIcon className="c-red error-validation"/>
                                <div className="error-hd">
                                    Error
                                </div>
                            </Modal.Title>
                            <Modal.Title className="f-18 text-center"></Modal.Title>
                            <div className="col-md-12 align-items-center justify-content-center mt-2">
                                <div className="success-sub-hd col-md-12 p-0 f-14">
                                    <p className="mb-2"><PriorityHighOutlinedIcon className="c-red f-12"/> {validation.message} </p>
                                </div>
                                <div className="success-description pt-1 mb-3 col-md-12 p-0 f-14">Please address the errors above and upload a valid video.</div>
                                {/*<p class="notification-modal-description"></p>*/}
                            </div>
                            <div className="float-right">
                                <button className='btn remove-btn shadow-sm px-3 py-0' onClick={handleCloseErrorModal}>Ok</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={open} onHide={handleClose} backdrop="static">
                    <Modal.Body>
                        <div className="col-lg-12 align-items-center justify-content-center mt-2">
                            <div className="mb-3">
                                {/*<img src="/images/videoRecordDefault.png" alt="Second Logo" className="video-record-image-modal" />*/}
                                <VideoRecorder
                                    onRecordingComplete={async (blob) => {
                                        setVideoBlob(blob);
                                        const url = URL.createObjectURL(blob);
                                        setVideoPreview(url);
                                    }}
                                    onError={(error) => {
                                        console.error('Video recording error: ', error);
                                    }}
                                    showReplayControls={true}
                                />
                            </div>
                            <div className="float-right">
                                <button className='btn continue-btn mb-3 shadow-sm py-0' onClick={handleSaveRecordedVideo}>Upload</button>
                            </div>
                            <div className="float-left">
                                <button className='btn remove-btn shadow-sm px-3 py-0' onClick={Cancel}>Cancel</button>
                            </div>
                        </div>
                    </Modal.Body>
                    {/*<Modal.Body>
                            <div className="col-lg-12 align-items-center justify-content-center mt-2">
                                <div className="mb-3">
                                    <img src="/images/videoRecordDefault.png" alt="Second Logo" className="video-record-image-modal" />
                                    <Webcam
                                        audio={true}
                                        ref={webcamRef}
                                        screenshotFormat="image/jpeg"
                                        videoConstraints={{ facingMode: 'user' }}
                                        className="video-record-image-modal"
                                    />
                                </div>
                                <div className="row mb-3">
                                    <button className='col-md-6 btn mb-3 float-left' onClick={startRecording}>
                                        <PlayCircleOutlineOutlinedIcon className="c-blue"/> Start Recording
                                    </button>
                                    <button className='col-md-6 btn mb-3 float-right' onClick={stopRecording}>
                                        <StopCircleOutlinedIcon className="c-red"/> Stop Recording
                                    </button>
                                </div>
                                <div className="">
                                    <button className='btn btn-modal-save mb-3' onClick={handleSaveRecordedVideo}>Save</button>
                                </div>
                                <div className="">
                                    <button className='btn btn-modal-cancel' onClick={Cancel}>Cancel</button>
                                </div>
                            </div>
                        </Modal.Body>*/}
                </Modal>

                <Modal show={addOpen} onHide={handleAddClose} backdrop="static">
                    <Modal.Body>
                        <div className="col-lg-12 align-items-center justify-content-center mt-2">
                            <Modal.Title className="f-15 text-center">
                                <div className="mb-3">
                                    <p>Do you want to create another Interview Round?</p>
                                </div>
                            </Modal.Title>
                            {isCountdownActive && (
                                <div className="text-center">
                                    <p>Validating... Please wait for {countdown} seconds.</p>
                                </div>
                            )}
                            <div className="col-lg-12 d-flex align-items-center justify-content-center mt-2">
                                <button className='btn remove-btn' onClick={verify}>NO</button>
                                <button className='btn continue-btn ml-3' onClick={handleAddInterviewRound}>YES</button>
                            </div>
                            {/*<div className="">
                                    <button className='btn btn-modal-save mb-3' onClick={handleAddInterviewRound}>Yes</button>
                                </div>
                                <div className="">
                                    <button className='btn btn-modal-cancel' onClick={handleSubmitStep3}>No</button>
                                </div>*/}
                        </div>
                    </Modal.Body>
                </Modal>

                <div className="">
                    <div className="main-container">
                        <div className="col-md-12 top-header py-3">
                            <div className="col-md-12 icon-wrap">
                                <span><img src="/images/iCatch-trial-center.png" className="trial-icatch-icon" alt="Trial Center" /></span>
                            </div>
                            <div className="col-md-12 head-wrap">
                                Trial Center
                            </div>
                        </div>
                        <div className="col-md-12 py-3">
                            <div className=" col-md-12 py-3 inside-content-wrapper">
                                <div className="p-3">
                                    <p className="f-14">Step-by-step process to try iCatch</p>
                                    <div className="p-3">
                                        <Stepper activeStep={activeStep} orientation="vertical">
                                            {steps.map((label, index) => (
                                                <Step key={label}>
                                                    <StepLabel
                                                        StepIconComponent={(props) => (
                                                            <CustomStepIcon active={props.active} completed={props.completed}>
                                                                {props.completed ? <CustomCheckIcon /> : <CircleIcon style={{ fontSize: 16 }} />}
                                                            </CustomStepIcon>
                                                        )}
                                                    >
                                                        <Typography
                                                            style={{
                                                                color: activeStep === index ? '#E7A343' : (activeStep > index ? '#47AA45' : '#373A42'),
                                                                fontWeight: 'bold',
                                                                fontSize: '15px'
                                                            }}
                                                        >

                                                            {/*{validatedSteps[index] ? `${label} Validated` : label}*/}
                                                            {label}
                                                            {validatedSteps[index] && (
                                                                <span className="validated-badge">
                                                                    <VerifiedIcon
                                                                        style={{
                                                                            fontSize: '16px',
                                                                            marginRight: '4px',
                                                                        }}
                                                                    />
                                                                    Validated
                                                                </span>
                                                            )}
                                                            {/* Display the loader if validation is in progress && loadingValidation */}
                                                            {index === 2 && loadingProgress > 0 && loadingProgress < 100 &&(
                                                                <div className="progress" style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    <div
                                                                        className="progress-bar progress-bar-striped progress-bar-animated"
                                                                        style={{ width: `${loadingProgress}%` }}
                                                                    >
                                                                        {loadingProgress === 100 ? 'Processing...' : 'Verifying...'}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Typography>
                                                    </StepLabel>
                                                    <StepContent>
                                                        {getStepContent(index)}
                                                        <div>
                                                            {/*<Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleNext}
                                                            style={{ marginTop: 8, marginBottom: 8 }}
                                                            disabled={index === steps.length - 1}
                                                        >
                                                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                                        </Button>*/}
                                                            {/*{index > 0 && (
                                                                    <Button onClick={handleBack} style={{ marginTop: 8, marginBottom: 8 }}>
                                                                        Back
                                                                    </Button>
                                                                )}*/}
                                                        </div>
                                                    </StepContent>
                                                </Step>
                                            ))}
                                        </Stepper>
                                        {activeStep === steps.length && (
                                            <div>
                                                <Typography>All steps completed - you're finished</Typography>
                                                <Button onClick={handleReset}>Reset</Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </LoadingOverlay>
            <Snackbar open={verifyingOpen} autoHideDuration={3000}
                      onClose={handleVerifyingClose}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      sx={{ zIndex: 2002, marginTop: '80px' }}>
                <MuiAlert onClose={handleVerifyingClose} severity="info" sx={{ width: '100%' }}>
                    Please wait for validation to complete.
                </MuiAlert>
            </Snackbar>

            {/*<Snackbar
                open={verifyingOpen}
                autoHideDuration={3000}
                onClose={handleVerifyingClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MuiAlert onClose={handleVerifyingClose} severity="info" sx={{ width: '100%' }}>
                    Please wait for validation to complete.
                </MuiAlert>
            </Snackbar>
            <Snackbar
                open={verifyingOpen}
                autoHideDuration={3000}
                onClose={handleVerifyingClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MuiAlert onClose={handleVerifyingClose} severity="warning" sx={{ width: '100%' }}>
                    Please wait for validation to complete.
                </MuiAlert>
            </Snackbar>*/}
        </>
    );
}

export default ICatch;
