import React, {useEffect, useState} from "react";
import "../style.css";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import {Box, IconButton, TextField, useTheme} from "@mui/material";
import { useContext } from "react";
import configData from "../config/index.json";
import axios from "axios";
import {useCookies} from "react-cookie";
import swal from "sweetalert";
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import {Modal} from "react-bootstrap";
import { Link } from 'react-router-dom';
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";


function Header() {
    const [isActive, setIsActive] = useState(false);
    const [cookies] = useCookies(['clientEmail', 'clientPlatformID', 'clientName']);
    const [formData, setFormData] = useState({
        full_name: cookies.clientName,
        email: cookies.clientEmail,
        message: ''
    });
    const [support, setSupport] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isVerified, setIsVerified] = useState('N');

    let expressURL = configData.express_url;

    useEffect(() => {
        if (cookies.clientEmail !== undefined && cookies.clientEmail !== 'undefined') {
            getProfileDetails();
        }
    }, [cookies.clientEmail]);

    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));

    const getProfileDetails = () => {
        const loggedInEmail = cookies.clientEmail;
        axios.post(expressURL + "icatch/profile/getprofiledetails", {
            email: loggedInEmail
        })
            .then(response => {
                console.log('getprofiledetails',response)
                const mode = response.data.data.mode;
                const initialIsActive = mode === 'P';
                setIsActive(initialIsActive);
                setIsVerified(response.data.data.is_verified);
            })
            .catch(error => {
                console.error('Error fetching profile details:', error);
                swal({
                    title: 'Error!',
                    text: 'Something went wrong.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            });
    };

    const toggleSwitch = () => {
        if (isVerified === 'N') {
            setIsModalOpen(true);
            return;
        }
        const newMode = !isActive ? 'P' : 'S';
        setIsActive(!isActive);
        const loggedPlatformID = cookies.clientPlatformID;
        // Update the mode in the database
        axios.patch(expressURL + "api/toggle/togglemode", {
            platform_id: loggedPlatformID,
            mode: newMode
        })
            .then(response => {
                if (response.data.status === 'success') {
                    console.log(`Mode changed to ${newMode}`);
                }
            })
            .catch(error => {
                console.error('Error updating mode:', error);
                swal({
                    title: 'Error!',
                    text: 'Something went wrong.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            });
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSupportOpenModal = (event) => {
        event.preventDefault();
        setSupport(true);
    };

    const handleSupportCloseModal = () => {
        setSupport(false);
    };

    function Cancel() {
        setSupport(false);
        setFormData(null);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post(expressURL + "api/support/sendemail", formData)
            .then(response => {
                setSupport(false);
                if (response.status === 200) {
                    console.log('Response mail send:', response.data);
                    swal({
                        title: 'Success!',
                        text: 'Email sent successfully!',
                        icon: 'success',
                        button: "OK",
                        closeOnClickOutside: false,
                    }).then((willReload) => {
                        if (willReload) {
                            window.location.reload();
                        }
                    });
                } else {
                    console.error('Mail not sent');
                }
            }).catch(error => {
            console.error('Error:', error);
            swal({
                title: 'Error!',
                text: 'Something went wrong. Please try again later.',
                icon: 'error',
                button: "OK",
                closeOnClickOutside: false,
            });
        });
    };

    return (
        <>
            <Modal show={support} onHide={handleSupportCloseModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title className=" ">Support</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-3 profile-settings">
                        <p className="f-12">Had any questions? Get in touch with us.</p>
                        <form className="login-form profile-settings support-form mt-3" onSubmit={handleSubmit}>
                            <div className="col-md-12 p-0">
                                <div className="row">
                                    <div className="col-md-12">
                                        <TextField
                                            fullWidth
                                            placeholder="Message*"
                                            variant="standard"
                                            className="mb-3 profile-input"
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <div className="col-md-6">
                                    <p className="f-12 mb-0">Mail us at: <a href="mailto:sales@evaluationz.com">sales@evaluationz.com</a></p>
                                </div>
                                <div className="col-md-6">
                                    <button type="submit" className="btn continue-btn float-end shadow-sm">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={isModalOpen} onHide={closeModal} backdrop="static">

                <Modal.Body>
                    <Modal.Title className="f-50 text-center">
                        <i className="mdi mdi-alert-circle-outline c-black"> </i>
                    </Modal.Title>
                    <Modal.Title className="f-18 text-center">Permission Required</Modal.Title>
                    {/*<p className="text-center f-12">To switch to production mode, please get in touch with Evaluationz for authorization.</p>*/}
                    <p className="text-center f-12">Access to production mode requires approval. Please contact support for further assistance.</p>
                    <div className="col-lg-12 d-flex align-items-center justify-content-center mt-2">
                        <button className="btn continue-btn float-end shadow-sm" onClick={closeModal}>Ok</button>
                    </div>
                </Modal.Body>
            </Modal>
            <nav className="navbar navbar-expand-lg navbar-light header p-0">
                <div className="container-fluid p-0">
                    <div className="header-left d-flex align-items-center">
                        <Link to="/dashboard" className="cursor-pointer">
                            <img src="/images/logo.png" alt="First Logo" className="header-logo-inside" />
                        </Link>
                        <div className="divider"></div>
                        {/*<img src="../../public/images/icatch-group-logo.png" alt="Second Logo" className="header-logo" />*/}
                    </div>
                    <div id="navbarText" className="header-right">
                        <div className="col-md-6 icatch-logo-container">
                            <img src="/images/icatch-group-logo.png" alt="Second Logo" className="icatch-group-logo" />
                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                            <BootstrapTooltip title="Support">
                                <div className="mr-3 support-header-icon" onClick={handleSupportOpenModal}>
                                    <SupportAgentOutlinedIcon/>
                                </div>
                            </BootstrapTooltip>
                            <div className="api-doc-cover no-wrap">
                                <ReceiptLongIcon className="mr-2"/>
                                <a href="https://iim.evaluationz.com/#tag/IIM-API's" target="_blank" className="header-link me-3 no-wrap">API Documentation</a>
                            </div>

                            {/*<div className="toggle-switch" onClick={toggleSwitch}>
                                <div className={`slider ${isActive ? 'active' : ''}`}>
                                    <span className="slider-text">{isActive ? 'Live' : 'Demo'}</span>
                                </div>
                            </div>*/}
                            <div className="toggle-switch" onClick={toggleSwitch}>
                                <div className={`slider ${isActive ? 'active' : ''}`}>
                                    <span className="slider-text">{isActive ? 'Live' : 'Demo'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Header;
