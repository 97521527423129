import React from 'react';
import "../style.css";

const Footer = () => {
    return (
        <footer className="footer-inner">
            &copy; 2024 | All Rights Reserved | iCatch Version 2.0.0
        </footer>

    );
};

export default Footer;
