import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import swal from "sweetalert";
import LogIn from "./components/LogIn";
import SignUp from "./components/SignUp";
import Dashboard from "./components/Dashboard";
import ManageSubClient from "./components/ManageSubClient";
import ICatch from "./components/iCatch";
import CandidateList from "./components/CandidateList";
import Configuration from "./components/Configuration";
import LoadbalancerConfiguration from "./components/LoadbalancerConfig";
import ProfileSettings from "./components/ProfileSettings";
import Notification from "./components/Notification";
import Landing from "./components/Landing/Landing";
import Loader from "./components/Loader";
import Validate from "./components/Validate";
import ForgotPassword from "./components/ForgotPassword";
import BarChart from "./components/charts/BarChart";
import PieChart from "./components/charts/PieChart"
import LineChart from "./components/charts/LineChart"
import BillingConsole from "./components/BillingConsole"
import Support from "./components/Support"
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation} from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { Amplify, Auth, Hub } from 'aws-amplify';
import PrivateRoute from './components/PrivateRoute';
import Header from "./components/Header";
import Footer from "./components/Footer";
import SideNav from "./components/SideNav";
import LandingHeader  from "./components/Landing/LandingHeader";
import Banner from "./components/Landing/Banner";
import configData from "./config/index.json";
import ScrollToTop from './components/ScrollToTop';
import {useCookies} from "react-cookie";
Amplify.configure(awsExports);


function App() {
  const [users, setUsers] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [formType, updateFormState] = useState('singIn');
  const [user, updateUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isRegistered, setIsRegistered] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [cookies] = useCookies(['clientPlatformID', 'clientAccessToken']);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  let expressURL = configData.express_url;

  useEffect(() => {
    // setTimeout(() => setLoading(false), 1000);
    /*Hub.listen('auth', (event) => {
      console.log("auth event", event);
      //setIsAuthenticated(event.payload.data);
      console.log("payload.data.message", event.payload.data.message);
      console.log("event.payload.event", event.payload.event)
      if (event.payload.event === 'signIn') {
        setIsAuthenticated(true);
      } else if (event.payload.event === 'signIn_failure') {
        swal({
          text: event.payload.data.message,
          icon: 'info',
          button: 'Got it',
          closeOnClickOutside: false,
        });
      }else if (event.payload.event === 'signOut') {
        setIsAuthenticated(false);
      } else if (event.payload.event === 'signUp') {
        console.log("signUp success");
        /!*swal( {
          text: "Successfully registered. Please verify your account",
          icon: "success",
        });*!/
      } else if (event.payload.event === 'signUp_failure') {
        console.log("inside signUp_failure");
        swal({
          text: event.payload.data.message,
          icon: 'info',
          button: 'Got it',
          closeOnClickOutside: false,
        });
        setIsRegistered(false); // Prevent redirect to the next step
      } else if (event.payload.event === "forgotPasswordSubmit_failure") {
        swal({
          text: event.payload.data.message,
          icon: 'info',
          button: 'Got it',
          closeOnClickOutside: false,
        });
      } else if (event.payload.event === "forgotPassword_failure") {
        swal({
          text: event.payload.data.message,
          icon: 'info',
          button: 'Got it',
          closeOnClickOutside: false,
        });
      }
    })*/
    const checkAuth = () => {
      const clientAccessToken = cookies.clientAccessToken;
      if (clientAccessToken && clientAccessToken !== "undefined") {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false);
    };

    checkAuth();
  }, [cookies]);

  const handleRegistration = () => {
    setIsRegistered(true);
    // setIsAuthenticated(false); // Prevent redirect to dashboard
  }
  const handleLogout = () => {
    setIsAuthenticated(false); // Reset authentication state
    navigate('/landing'); // Redirect to login after logout
  };

  // const fetchUnreadCount = async () => {
  //   const clientPlatformID = {"platform_id": cookies.clientPlatformID};
  //   const response = await axios.post(expressURL + "api/notification/getunreadcount", clientPlatformID);
  //   setUnreadCount(response.data.unread_count); // Update the unread count
  // }

  /*async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        updateUser(user)
        updateFormState('confirmSingIn')
      }
    } catch (err) {
      updateUser(null)
    }
  }*/

  if (loading) return <Loader />;
  //if (isAuthenticated === null) return <div>Loading...</div>;
  return (
      /*<>
        <Loader isLoading={spinnerLoading} />
        <ScrollToTop />
        <div className="app">
          <main className="content">
            {isAuthenticated ? (
                <div className="d-flex flex-column vh-100">
                  <Header  /> {/!* Pass logout function *!/}
                  <div className="d-flex flex-grow-1 outer-app-content-cvr">
                    <SideNav unreadCount={unreadCount} setIsAuthenticated={setIsAuthenticated} />
                    <Routes>
                      {/!* Protected routes *!/}
                      <Route path="/" element={<Navigate to="/dashboard" />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/manage-sub-client" element={<ManageSubClient />} />
                      <Route path="/icatch" element={<ICatch />} />
                      <Route path="/candidate-list" element={<CandidateList />} />
                      <Route path="/billing-console" element={<BillingConsole />} />
                      <Route path="/configuration" element={<Configuration />} />
                      <Route path="/loadbalancer" element={<LoadbalancerConfiguration />} />
                      <Route path="/support" element={<Support />} />
                      <Route path="/profile-settings" element={<ProfileSettings setSpinnerLoading={setSpinnerLoading} />} />
                      <Route path="/notification" element={<Notification />} />
                      <Route path="/barchart" element={<BarChart />} />
                      <Route path="/piechart" element={<PieChart />} />
                      <Route path="/linechart" element={<LineChart />} />
                      <Route path="*" element={<Navigate to="/dashboard" />} />
                    </Routes>
                  </div>
                </div>
            ) : isRegistered ? (
                // Route when user is registered but not authenticated
                <Routes>
                  <Route path="/validate" element={<Validate onRegister={handleRegistration} />} />
                  <Route path="*" element={<Navigate to="/validate" />} />
                </Routes>
            ) : (
                // Routes for unauthenticated users
                <Routes>
                  <Route path="/" element={<Landing />} />
                  <Route path="/login" element={<LogIn setSpinnerLoading={setSpinnerLoading} setIsAuthenticated={setIsAuthenticated} />} />
                  <Route path="/signup" element={<SignUp onRegister={handleRegistration} />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/validate" element={<Validate onRegister={handleRegistration} />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            )}
          </main>
        </div>
      </>*/
      <>
        <Loader isLoading={spinnerLoading} />
        <ScrollToTop/>
        <div className="app">
          <main className="content">
            {isAuthenticated ? (
                <>
                  <div className="d-flex flex-column vh-100">
                    <Header></Header>
                    <div className="d-flex flex-grow-1 outer-app-content-cvr">
                      <SideNav unreadCount={unreadCount} setIsAuthenticated={setIsAuthenticated} setSpinnerLoading={setSpinnerLoading}></SideNav>
                      <Routes>
                        <Route path="/" element={<Navigate to="/dashboard" />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/manage-sub-client" element={<ManageSubClient />} />
                        <Route path="/icatch" element={<ICatch />} />
                        <Route path="/candidate-list" element={<CandidateList />} />
                        <Route path="/billing-console" element={<BillingConsole />} />
                        <Route path="/configuration" element={<Configuration />} />
                        <Route path="/loadbalancer" element={<LoadbalancerConfiguration />} />
                        <Route path="/support" element={<Support />} />
                        <Route path="/profile-settings" element={<ProfileSettings setSpinnerLoading={setSpinnerLoading}/>} />
                        <Route path="/notification" element={<Notification/>} />
                        <Route path="/barchart" element={<BarChart />} />
                        <Route path="/piechart" element={<PieChart />} />
                        <Route path="/linechart" element={<LineChart />} />
                        <Route path="*" element={<Navigate to="/dashboard" />} />
                      </Routes>
                    </div>
                    {/*<Footer />*/}
                  </div>
                </>
            ) : isRegistered ? (
                <Routes>
                  <Route path="/validate" element={<Validate onRegister={handleRegistration} />} />
                  <Route path="*" element={<Navigate to="/validate" onRegister={handleRegistration}/>} />
                </Routes>
            ) : (
                <Routes>
                  <Route path="/" element={<Landing />} />
                  {/*<Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard" /> : <LogIn setSpinnerLoading={setSpinnerLoading} setIsAuthenticated={setIsAuthenticated}/>} />
                  <Route path="/signup" element={isAuthenticated ? <Navigate to="/dashboard" /> : <SignUp onRegister={handleRegistration} />} />*/}
                  <Route path="/login" element={<LogIn setSpinnerLoading={setSpinnerLoading} setIsAuthenticated={setIsAuthenticated}/>} />
                  <Route path="/signup" element={<SignUp onRegister={handleRegistration} />} />
                  <Route path="/forgot-password" element={<ForgotPassword />}/>
                  <Route path="/validate" element={<Validate onRegister={handleRegistration} />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            )}
          </main>
        </div>
      </>

  );
}

export default App;
