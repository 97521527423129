import React, {useEffect, useState} from "react";
import BarChart from "./charts/BarChart";
import PieChart from "./charts/PieChart";
import LineChart from "./charts/LineChart";
import {TextField} from "@mui/material";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from "axios";
import {useCookies} from "react-cookie";
import configData from "../config/index.json";
import Pagination from 'rc-pagination';
import swal from "sweetalert";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Skeleton from '@mui/material/Skeleton';


function Dashboard() {
    const [licenceBalance, setLicenceBalance] = useState([]);
    const [productUsage, setProductUsage] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [clientIDFilter, setClientIDFilter] = useState('');
    const [caseIDFilter, setCaseIDFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [isExpanded, setIsExpanded] = useState(false);
    const [verificationHistory, setVerificationHistory] = useState([]);
    const [cookies, removeCookie] = useCookies(['clientPlatformID']);
    const [perPage, setPerPage] = useState(10);
    const [size, setSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [current, setCurrent] = useState(1);
    const [showChartData, setShowChartData] = useState(false);
    const [chartData, setChartData] = useState({});
    const [loading, setLoading] = useState(true);

    let expressURL = configData.express_url;

    useEffect(() => {
        if (cookies.clientPlatformID !== 'undefined' && cookies.clientPlatformID !== undefined) {
            getLicenceBalance();
            getProductUsage();
            getVerificationhistory(startDate);
            getChartDetails();
        }

        const handleClickOutside = (event) => {
            if (isExpanded && !event.target.closest('.search-bar-container')) {
                setIsExpanded(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isExpanded, startDate, searchTerm, clientIDFilter, caseIDFilter, statusFilter, current, size, cookies.clientPlatformID,current]);

    async function getChartDetails() {
        try {
            if (cookies.clientPlatformID) {
                const loggedPlatformID = {"platform_id": cookies.clientPlatformID};
                let chartDataResponse = await axios.post(expressURL + "api/dashboard/matchmismatchstats", loggedPlatformID)
                console.log("chartDataResponse", chartDataResponse)
                if (chartDataResponse.data.monthly_stats.length > 0 && (chartDataResponse.data.overall_match_percentage > 0 || chartDataResponse.data.overall_mismatch_percentage > 0)) {
                    setShowChartData(true);
                }
                setChartData(chartDataResponse.data)
            }
        } catch (e){
            swal({
                title: 'Error!',
                text: 'Something went wrong.',
                icon: 'error',
                button: "OK",
            });
        }
    }

    function getLicenceBalance() {
        setLoading(true);
        //let platform = {"platform_id" : 18};
        const loggedPlatformID = {"platform_id": cookies.clientPlatformID};
        // console.log("loggedPlatformID", loggedPlatformID);

        axios.post(expressURL + "api/dashboard/licensebalance", loggedPlatformID)
            .then(response => {
                // console.log("response", response);

                if (response.status === 200) {
                    setLoading(false);
                    // console.log("inside getLicenceBalance", response);
                    // console.log("response data licence balance", response.data)
                    setLicenceBalance(response.data);
                    // console.log("licence balance History Data:", verificationHistory);
                    //setFilteredSubClientDetails(response.data);
                } else {
                    // console.error('Failed to fetch verification history');
                }
            })
            .catch(error => {
                setLoading(false);
                // console.error('Error fetching verification history:', error);
                swal({
                    title: 'Error!',
                    text: 'Something went wrong.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            });
    }

    function getProductUsage() {
        setLoading(true);
        //let platform = {"platform_id" : 18};
        const loggedPlatformID = {"platform_id": cookies.clientPlatformID};
        // console.log("loggedPlatformID", loggedPlatformID);

        axios.post(expressURL + "api/dashboard/productusage", loggedPlatformID)
            .then(response => {
                // console.log("response", response);
                if (response.status === 200) {
                    setLoading(false);
                    // console.log("inside getProductUsage", response);
                    // console.log("product Usage response.data", response.data)
                    setProductUsage(response.data);
                    // console.log("licence balance History Data:", verificationHistory);
                    //setFilteredSubClientDetails(response.data);
                } else {
                    // console.error('Failed to fetch verification history');
                }
            })
            .catch(error => {
                setLoading(false);
                // console.error('Error fetching verification history:', error);
                swal({
                    title: 'Error!',
                    text: 'Something went wrong.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            });
    }

    function getVerificationhistory(date = new Date()) {
        setLoading(true);
        const formattedDate = date.toISOString().split('T')[0]; // Format date as "YYYY-MM-DD"
        //
        // const loggedPlatformID = {
        //     platform_id: cookies.clientPlatformID,
        //     selectedDate: formattedDate,
        //     limit: size,  // Page size
        //     offset: (current - 1) * size
        // };
        const payload = {
            platform_id: cookies.clientPlatformID,  // Send platform_id directly
            selectedDate: formattedDate,           // Send selectedDate directly
            limit: size,                           // Page size
            offset: (current - 1) * size           // Pagination offset
        };

        axios.post(expressURL + "api/dashboard/verificationshistory", payload)
            .then(response => {
                if (response.status === 200) {
                    setLoading(false);
                    setVerificationHistory(response.data.data);
                    setTotalRecords(response.data.totalRecords);
                    // console.log("verification history response", response);
                } else {
                    // console.error('Failed to fetch verification history');
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error fetching verification history:', error);
                swal({
                    title: 'Error!',
                    text: 'Something went wrong.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            });

        /*const loggedPlatformID = {
            platform_id: cookies.clientPlatformID,
            selectedDate: date.toISOString().split('T')[0]  // Format date as "YYYY-MM-DD"
        };

        axios.post(expressURL + "api/dashboard/verifications-history", loggedPlatformID)
            .then(response => {
                if (response.status === 200) {
                    setVerificationHistory(response.data);
                    console.log("verification history response", response);
                } else {
                    console.error('Failed to fetch verification history');
                }
            })
            .catch(error => {
                console.error('Error fetching verification history:', error);
            });*/

        /*const loggedPlatformID = {"platform_id": cookies.clientPlatformID};
        console.log("loggedPlatformID", loggedPlatformID);

        axios.post(expressURL + "api/dashboard/verifications-history", loggedPlatformID)
            .then(response => {
                console.log("response", response);

                if (response.status === 200) {
                    console.log("inside")
                    setVerificationHistory(response.data);
                    console.log("Verification History Data:", verificationHistory);
                    //setFilteredSubClientDetails(response.data);
                } else {
                    console.error('Failed to fetch verification history');
                }
            })
            .catch(error => {
                console.error('Error fetching verification history:', error);
            });*/
    }

    const toggleSearchBar = () => {
        setIsExpanded(!isExpanded);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleClientIDFilterChange = (event) => {
        setClientIDFilter(event.target.value);
    };

    const handleCaseIDFilterChange = (event) => {
        setCaseIDFilter(event.target.value);
    };

    const handleStatusFilterChange = (event) => {
        setStatusFilter(event.target.value);
    };

    const handleDateChange = (date) => {
        // console.log('Selected date:', date);
        setStartDate(date);
        getVerificationhistory(date);
    };

    const filteredRows = verificationHistory.filter(row =>
        (row.Client_id || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
        (row.Case_id || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
        (row.Verification_status || '').toLowerCase().includes(searchTerm.toLowerCase())
    );

    // const paginatedRows = filteredRows.slice((current - 1) * size, current * size);
    const paginatedRows = verificationHistory;
    const PaginationChange = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize)
    };

    const PrevNextArrow = (current, type, originalElement) => {
        const handleClick = (e) => {
            e.preventDefault();
        };
        if (type === 'prev') {
            return <button onClick={handleClick}><i className="mdi mdi-chevron-left"/></button>;
        }
        if (type === 'next') {
            return <button onClick={handleClick}><i className="mdi mdi-chevron-right"/></button>;
        }
        return originalElement;
    };
    const PerPageChange = (value) => {
        setSize(value);
        const newPerPage = Math.ceil(filteredRows.length / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    };
    // console.log("VH Data:", verificationHistory);

    return (
        <>
            <div className="outer-main">
                <div className="main-container">
                    <div className="col-md-12 d-flex top-header">
                        <div className="col-md-3 dashboard-top-header">
                            <div className="col-md-12 p-0 icon-wrap">
                                <HomeOutlinedIcon className="head-icon"/>
                            </div>
                            <div className="col-md-12 p-0 head-wrap">
                                Dashboard
                            </div>
                        </div>
                        <div className="top-summary col-12 col-md-12 col-lg-9">
                            <div className="row gx-2">
                                <div className="col-6 col-sm-6 col-md-6 col-lg-3 mb-2 py-0 pl-0 pr-2">
                                    <div className="box">
                                        <div
                                            className="col-md-12 p-0 d-flex justify-content-center align-items-center mt-3">
                                            <div
                                                className="icon-container1 d-flex justify-content-center align-items-center">
                                                <DocumentScannerOutlinedIcon className="summary-icon"/>
                                            </div>
                                        </div>
                                        <div
                                            className="col-md-12 p-0 d-flex justify-content-center align-items-center">
                                            <div className="col-md-12 p-0 text-center">
                                                    <span
                                                        className="summary-text no-wrap">Verification Scans Balance</span>
                                            </div>
                                        </div>
                                        {loading ? (
                                            <div className="col-md-12 p-0 d-flex justify-content-center align-items-center mb-1">
                                                <div className="col-md-12 p-0 text-center d-flex justify-content-center align-items-center">
                                                    <Skeleton className="summary-count no-wrap" variant="text" width={100} height={40} />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="col-md-12 p-0 d-flex justify-content-center align-items-center mb-1">
                                                <div className="col-md-12 p-0 text-center">
                                                    <span className="summary-count no-wrap">{licenceBalance.scan_balance}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-6 col-sm-6 col-md-6 col-lg-3 mb-2 py-0 pl-0 pr-2">
                                    <div className="box">
                                        <div
                                            className="col-md-12 p-0 d-flex justify-content-center align-items-center mt-3">
                                            <div
                                                className="icon-container2 d-flex justify-content-center align-items-center">
                                                <CompareOutlinedIcon className="summary-icon"/>
                                            </div>
                                        </div>
                                        <div
                                            className="col-md-12 p-0 d-flex justify-content-center align-items-center">
                                            <div className="col-md-12 p-0 text-center">
                                                <span className="summary-text no-wrap">ID Scans Balance</span>
                                            </div>
                                        </div>
                                        {loading ? (
                                            <div className="col-md-12 p-0 d-flex justify-content-center align-items-center mb-1">
                                                <div className="col-md-12 p-0 text-center d-flex justify-content-center align-items-center">
                                                    <Skeleton className="summary-count no-wrap" variant="text" width={100} height={40} />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="col-md-12 p-0 d-flex justify-content-center align-items-center mb-1">
                                                <div className="col-md-12 p-0 text-center">
                                                    <span className="summary-count no-wrap">{licenceBalance.id_scan_balance}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-6 col-sm-6 col-md-6 col-lg-3 mb-2 py-0 pl-0 pr-2">
                                    <div className="box">
                                        <div
                                            className="col-md-12 p-0 d-flex justify-content-center align-items-center mt-3">
                                            <div
                                                className="icon-container3 d-flex justify-content-center align-items-center">
                                                <EventAvailableOutlinedIcon className="summary-icon"/>
                                            </div>
                                        </div>
                                        <div
                                            className="col-md-12 p-0 d-flex justify-content-center align-items-center">
                                            <div className="col-md-12 p-0 text-center">
                                                <span className="summary-text no-wrap">Valid Till</span>
                                            </div>
                                        </div>
                                        {loading ? (
                                            <div className="col-md-12 p-0 d-flex justify-content-center align-items-center mb-1">
                                                <div className="col-md-12 p-0 text-center d-flex justify-content-center align-items-center">
                                                    <Skeleton className="summary-count no-wrap" variant="text" width={100} height={40}/>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="col-md-12 p-0 d-flex justify-content-center align-items-center mb-1">
                                                <div className="col-md-12 p-0 text-center">
                                                    <span className="summary-count no-wrap">{licenceBalance.valid_till}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-6 col-sm-6 col-md-6 col-lg-3 mb-2 py-0 pl-0 pr-2">
                                    <Link to="/billing-console" className="link-summary">
                                        <div className="box">
                                            <div
                                                className="col-md-12 p-0 d-flex justify-content-center align-items-center mt-3">
                                                <div
                                                    className="icon-container4 d-flex justify-content-center align-items-center">
                                                        <span
                                                            className="material-symbols-outlined summary-icon">payments</span>
                                                </div>
                                            </div>
                                            <div
                                                className="col-md-12 p-0 d-flex justify-content-center align-items-center">
                                                <div className="col-md-12 p-0 text-center">
                                                    <span className="summary-text no-wrap">Billing</span>
                                                </div>
                                            </div>
                                            <div
                                                className="col-md-12 p-0 d-flex justify-content-center align-items-center mb-1">
                                                <div className="col-md-12 p-0 text-center">
                                                    <span className="summary-count no-wrap">Manage</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 py-3">
                        <div className=" col-md-12 py-3 inside-content-wrapper-dashboard">
                            <div className="heading-dashboard mb-3">
                                <span className="header-dashboard-sub">Product Usage</span>
                            </div>
                            <div className="product-summary-card row px-3 mb-3">
                                <div className="col-12 col-sm-6 col-md-3 prod-summary-card1 shadow-sm mb-3 mb-md-0">
                                    {loading ? (
                                        <div className="col-md-12 p-0 d-flex justify-content-center align-items-center mt-2">
                                            <Skeleton className="summary-count no-wrap" variant="text" width={100} height={40}/>
                                        </div>
                                    ) : (
                                        <div className="col-md-12 p-0 d-flex justify-content-center align-items-center mt-2">
                                            <span className="f-35">{productUsage.total_scan_licenses}</span>
                                        </div>
                                    )}
                                    <div
                                        className="col-md-12 p-0 d-flex justify-content-center align-items-center mb-2">
                                        <span className="sub-topic text-center f-14">Total Case Scanned</span>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-3 prod-summary-card2 shadow-sm mb-3 mb-md-0">
                                    {loading ? (
                                        <div className="col-md-12 p-0 d-flex justify-content-center align-items-center mt-2">
                                            <Skeleton className="summary-count no-wrap" variant="text" width={100} height={40}/>
                                        </div>
                                    ) : (
                                        <div className="col-md-12 p-0 d-flex justify-content-center align-items-center mt-2">
                                            <span className="f-35">{productUsage.total_impersonation_detected}</span>
                                        </div>
                                    )}
                                    <div className="col-md-12 p-0 d-flex justify-content-center align-items-center mb-2">
                                            <span
                                                className="sub-topic text-center f-14">Total Impersonation Detected</span>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-3 prod-summary-card3 shadow-sm mb-3 mb-md-0">
                                    {loading ? (
                                        <div className="col-md-12 p-0 d-flex justify-content-center align-items-center mt-2">
                                            <Skeleton className="summary-count no-wrap" variant="text" width={100} height={40}/>
                                        </div>
                                    ) : (
                                        <div className="col-md-12 p-0 d-flex justify-content-center align-items-center mt-2">
                                            <span className="f-35">{productUsage.voice_breached_total}</span>
                                        </div>
                                    )}
                                    <div className="col-md-12 p-0 d-flex justify-content-center align-items-center mb-2">
                                        <span className="sub-topic text-center f-14">Voice Mismatch</span>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-3 prod-summary-card4 shadow-sm mb-3 mb-md-0">
                                    {loading ? (
                                        <div className="col-md-12 p-0 d-flex justify-content-center align-items-center mt-2">
                                            <Skeleton className="summary-count no-wrap" variant="text" width={100} height={40}/>
                                        </div>
                                    ) : (
                                        <div className="col-md-12 p-0 d-flex justify-content-center align-items-center mt-2">
                                            <span className="f-35">{productUsage.face_breached_total}</span>
                                        </div>
                                    )}
                                    <div className="col-md-12 p-0 d-flex justify-content-center align-items-center mb-2">
                                        <span className="sub-topic text-center f-14">Face Mismatch</span>
                                    </div>
                                </div>
                            </div>
                            {/*Candidate mismatch summary*/}
                            {showChartData && (
                                <div className="candidate-mismatch-summary-container p-3 mb-3">
                                    <div className="col-md-12 p-0">
                                        <span className="candidate-missmatch-hd">Candidate Mismatch Summary</span>
                                    </div>
                                    <div className="row px-3 py-0">
                                        <div className="col-md-6 p-0 custom-chart">
                                            <BarChart chartData={chartData}/>
                                        </div>
                                        <div className="col-md-6 p-0 custom-chart">
                                            <PieChart chartData={chartData}/>
                                        </div>
                                    </div>
                                </div>
                            )
                            }

                            {/*Daily/Weekly/Monthly Usage Trends*/}
                            <div className="usage-trends-container p-3">
                                <div className="col-md-12 p-0">
                                    <span className="usage-trends-hd">Daily/Weekly/Monthly Usage Trends</span>
                                    <p className="f-12 my-3">Line charts showing the number of API calls over
                                        different time periods</p>
                                </div>
                                <div className="col-md-12 p-0">
                                    <LineChart/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 py-3">
                        <div className=" col-md-12 py-3 inside-content-wrapper-dashboard">
                            <div className="heading-dashboard mb-3">
                                <span className="header-dashboard-sub">Verification History</span>
                            </div>
                            <div className="col-md-12 p-0">
                                {/*<div style={{ padding: '20px', backgroundColor: '#F4F4F4', borderRadius: '10px' }}>*/}
                                <div className="row p-0 float-right mr-1 mb-3 search-calendar-container">
                                    {/*<div>*/}
                                    <div className="col d-flex justify-content-end p-0 mr-3">
                                        <div
                                            className={`search-bar-container ${isExpanded ? 'expanded' : 'collapsed'}`}
                                            onClick={toggleSearchBar}
                                        >
                                            <InputBase
                                                className="search-input"
                                                placeholder="Search..."
                                                onClick={(e) => e.stopPropagation()} // Prevent the toggle when clicking on the input field
                                                onChange={handleSearchChange}
                                                style={{
                                                    display: isExpanded ? 'block' : 'none',
                                                }}
                                            />
                                            <SearchIcon
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toggleSearchBar();
                                                }}
                                                style={{
                                                    color: '#373737',
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col p-0 verifiaction-calender">
                                        <IconButton aria-label="calendar" className="">
                                            <CalendarMonthOutlinedIcon className="mr-2"/>
                                            <ReactDatePicker
                                                placeholderText="Calendar"
                                                selected={startDate}
                                                onChange={handleDateChange}
                                                customInput={<InputBase/>}
                                                dateFormat="dd-MM-yyyy"
                                                style={{marginLeft: '10px', width: '100px'}}
                                                popperPlacement="bottom-end" // Align popper to the bottom-right
                                                popperModifiers={{
                                                    preventOverflow: {
                                                        enabled: true,
                                                        escapeWithReference: false,
                                                        boundariesElement: 'viewport', // Prevent the popper from going outside the viewport
                                                    },
                                                    offset: {
                                                        enabled: true,
                                                        offset: '0, 10px', // Adjust the popper offset
                                                    },
                                                }}
                                                popperClassName="custom-datepicker-popper"
                                            />
                                        </IconButton>
                                    </div>

                                    {/*</div>*/}
                                </div>

                                <div className="verification-history-table">
                                    <TableContainer component={Paper} className="verification-history-table-in">
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>#</TableCell>
                                                    <TableCell>Timestamp</TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell>Client ID</TableCell>
                                                    <TableCell>Case ID</TableCell>
                                                    <TableCell>Verification Status</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {loading ? (
                                                    Array.from(new Array(5)).map((_, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell><Skeleton height={30}/></TableCell>
                                                            <TableCell><Skeleton height={30}/></TableCell>
                                                            <TableCell><Skeleton height={30}/></TableCell>
                                                            <TableCell><Skeleton height={30}/></TableCell>
                                                            <TableCell><Skeleton height={30}/></TableCell>
                                                            <TableCell><Skeleton height={30}/></TableCell>
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    paginatedRows.length > 0 ? (
                                                        paginatedRows.map((row, index) => (
                                                            <TableRow key={row.id || index}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                <TableCell>{row.Timestamp}</TableCell>
                                                                <TableCell>{row.What_is_consumed}</TableCell>
                                                                <TableCell>{row.Client_id}</TableCell>
                                                                <TableCell>{row.Case_id}</TableCell>
                                                                <TableCell>
                                                                    {(row.Verification_status === "Success") &&
                                                                        <span className="success-bg">Success</span>
                                                                    }
                                                                    {(row.Verification_status === "Error") &&
                                                                        <span className="error-bg">Error</span>
                                                                    }
                                                                    {(row.Verification_status === "In-Progress") &&
                                                                        <span
                                                                            className="inprogress-bg">In Progress</span>
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell colSpan={8} className="text-center f-18 font-bold p-5">
                                                                <p className="mb-0"><img src="/images/no-data.png"
                                                                                         alt="No Data Found" style={{
                                                                    width: '80px',
                                                                    height: '80px'
                                                                }}/></p>
                                                                <p className="mb-0">No record available!</p>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>

                                <div className="col-md-12 d-flex justify-content-end p-0">
                                    <div className="pagination-container shadow-sm">
                                        <Pagination className="pagination-data border-top f-12 pt-3 pb-2"
                                                    showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${totalRecords}`}
                                                    showLessItems
                                            // onChange={PaginationChange}
                                                    onChange={(page) => {
                                                        setCurrent(page);  // Update current page
                                                        // getVerificationHistory();  // Fetch data for the new page
                                                    }}
                                                    total={totalRecords}
                                                    current={current}
                                                    pageSize={size}
                                                    showSizeChanger={false}
                                                    itemRender={PrevNextArrow}
                                                    onShowSizeChange={PerPageChange}
                                        />
                                    </div>
                                </div>

                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
}

export default Dashboard;
