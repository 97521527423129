import React, {useEffect, useMemo, useState, useRef } from "react";
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import {DataGrid} from '@mui/x-data-grid';
import { TextField, Box, IconButton, Tooltip, InputAdornment, Snackbar  } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import configData from "../config/index.json";
import swal from "sweetalert";
import {Button, Modal} from "react-bootstrap";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Footer from "./Footer";
import MuiAlert from "@mui/material/Alert";

const countryStateData = {
    "India": ["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal"],
    /*"United States": ["California", "Texas", "New York", "Florida", "Illinois"],
    "Canada": ["Ontario", "Quebec", "British Columbia", "Alberta", "Manitoba"],*/
};

const clientData = {
    first_name: '',
    last_name: '',
    email: '',
    country: '',
    state: '',
    logo: ''
}

function ProfileSettings({ setSpinnerLoading }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [fullAddress, setFullAddress] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [states, setStates] = useState([]);
    const [errors, setErrors] = useState({});
    const [cookies] = useCookies(['clientEmail', 'clientAccessToken']);
    const [clientDetails, setClientDetails] = useState(clientData);
    const [passwordResetModalShow, setpasswordResetModalShow] = useState(false);
    const [reenterPassword, setReenterPassword] = useState('');
    const [code, setCode] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [requestNewPasswordVisible, setRequestNewPasswordVisible] = useState(false);
    const [requestConfNewPasswordVisible, setRequestConfNewPasswordVisible] = useState(false);
    const [open, setOpen] = useState(false);
    let expressURL = configData.express_url;

    const addressRef = useRef(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        // **Load Google Maps script**
        const loadGoogleMapsScript = () => {
            const script = document.createElement("script");
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC8nhmzEz7qEVz-zxi__3P4JYpqX3PjV1w&libraries=places`;
            script.async = true;
            script.defer = true;
            script.onload = () => {
                // **Initialize Google Places Autocomplete**
                const autocomplete = new window.google.maps.places.Autocomplete(addressRef.current, {
                    types: ['geocode'],  // Restrict results to geographical locations
                });

                // **Listen for place selection**
                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    if (place.formatted_address) {
                        setFullAddress(place.formatted_address);
                    }
                });
            };
            document.body.appendChild(script);
        };

        loadGoogleMapsScript();
    }, []);

    useEffect(()=>{
        if (cookies.clientEmail) {
            getprofiledetails()
        }
    },[cookies]);

    async function getprofiledetails() {
        console.log("INSIDE")
        const loggedInEmail = {"email":cookies.clientEmail};
        console.log("logedemail", loggedInEmail)
        await axios.post(expressURL + "icatch/profile/getprofiledetails", loggedInEmail)
            .then(response => {
                console.log("client", response.data);
                if (response.data.status === 200) {
                    console.log("client", response.data.data);
                    //setClientDetails(() => ({...clntData, first_name: firstName}));
                    const clientData = response.data.data;
                    console.log("client", clientData);
                    setClientDetails(clientData);

                    setFirstName(clientData.full_name);
                    // setLastName(clientData.last_name);
                    setEmail(clientData.email);
                    setFullAddress(clientData.full_address)
                    /*setCountry(clientData.country);
                    setState(clientData.state);
                    setStates(countryStateData[clientData.country] || []);*/
                    setImagePreviewUrl(clientData.logo);
                } else {
                    console.error('Failed to fetch client details');
                }
            }).catch(error => {
                console.error('Error fetching client details:', error);
                swal({
                    title: 'Error!',
                    text: 'Something went wrong.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            })
    }

    const validateEmail = () => {
        let formErrors = {};
        if (!email) {
            formErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            formErrors.email = "Email address is invalid";
        }
        return formErrors;
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleNewPasswordVisibility = () => {
        setNewPasswordVisible(!newPasswordVisible);
    };

    const toggleRequestNewPasswordVisibility = () => {
        setRequestNewPasswordVisible(!requestNewPasswordVisible);
    };

    const toggleRequestconfNewPasswordVisibility = () => {
        setRequestConfNewPasswordVisible(!requestConfNewPasswordVisible);
    };

    const validatePassword = () => {
        let formErrors = {};
        if (!newPassword) {
            formErrors.newPassword = "Password is required";
        } else if (newPassword.length < 8) {
            formErrors.newPassword = "Password must be at least 8 characters";
        } else if (!/(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])/.test(newPassword)) {
            formErrors.newPassword = "Password must contain at least one number, one uppercase letter, and one special character";
        } else if (newPassword !== reenterPassword) {
            formErrors.reenterPassword = "Passwords do not match";
        } else if (!reenterPassword) {
            formErrors.reenterPassword = "Confirm password is required";
        }
        return formErrors;
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    /*const handleResetRequest = async (event) => {
        event.preventDefault();
        const formErrors = validateEmail();
        if (Object.keys(formErrors).length === 0) {
            try {
                await Auth.forgotPassword(email);
                // setSpinnerLoading(true);
                setOpen(true);
                console.log("password email", email)
                //setpasswordResetModalShow(true);
            } catch (error) {
                console.error('Error requesting password reset:', error);
            }
        } else {
            setErrors(formErrors);
        }
    };*/

    const handleResetRequest = async (event) => {
        event.preventDefault();
        const formErrors = validateEmail();

        if (Object.keys(formErrors).length === 0) {
            try {
                const response = await axios.post(expressURL + "icatch/cognito/requestpasswordreset", {
                    email
                });

                if (response.data.success) {
                    setOpen(true);
                } else {
                    console.error('Error requesting password reset:', response.data.message);
                }
            } catch (error) {
                console.error('Error requesting password reset:', error.response?.data?.message);
                console.error('Error requesting password reset:', error.message);
                swal({
                    text: `${error.response?.data?.message || error.message}`,
                    icon: 'error',
                    button: 'OK',
                });
            }
        } else {
            setErrors(formErrors); // Set form validation errors if any
        }
    };

    const validatePasswords = () => {
        let formErrors = {};
        if (!reenterPassword) {
            formErrors.reenterPassword = "Reentering the password is required";
        } else if (password !== reenterPassword) {
            formErrors.reenterPassword = "Passwords do not match";
        }
        return formErrors;
    };

    const handlePasswordCloseModal = () => setpasswordResetModalShow(false);

    const handlePasswordOpenModal = () => setpasswordResetModalShow(true);

    /*const handleResetConfirm = async (event) => {
        event.preventDefault();
        setSpinnerLoading(true);
        // const timeout = setTimeout(() => setSpinnerLoading(false), 1000);
        const formErrors = validatePassword();
        if (Object.keys(formErrors).length === 0) {
            try {
                await Auth.forgotPasswordSubmit(email, code, newPassword);
                setpasswordResetModalShow(false);
                setSpinnerLoading(false);
                swal({
                    title: 'Success!',
                    text: 'The password has been successfully reset.',
                    icon: 'success',
                    button: 'OK',
                    closeOnClickOutside: false,
                }).then((willReload) => {
                    if (willReload) {
                        window.location.reload();
                    }
                });
            } catch (error) {
                setSpinnerLoading(false);
                console.error('Error resetting password:', error);
                setpasswordResetModalShow(false);
                swal({
                    title: 'Error!',
                    text: 'Invalid verification code provided, please try again.',
                    icon: 'error',
                    button: 'OK',
                    closeOnClickOutside: false,
                });
            }
        } else {
            setSpinnerLoading(false);
            setErrors(formErrors);
        }
    };*/

    const handleResetConfirm = async (event) => {
        event.preventDefault();
        setSpinnerLoading(true);
        const formErrors = validatePassword();

        if (Object.keys(formErrors).length === 0) {
            try {
                const response = await axios.post(expressURL + "icatch/cognito/confirmpasswordreset", {
                    email,
                    code,
                    newPassword
                });

                if (response.data.success) {
                    setpasswordResetModalShow(false);
                    setSpinnerLoading(false);
                    swal({
                        title: 'Success!',
                        text: 'The password has been successfully reset.',
                        icon: 'success',
                        button: 'OK',
                        closeOnClickOutside: false,
                    }).then((willReload) => {
                        if (willReload) {
                            window.location.reload();
                        }
                    });
                } else {
                    setSpinnerLoading(false);
                    console.error('Error resetting password:', response.data.message);
                    swal({
                        title: 'Error!',
                        text: response.data.message || 'An unexpected error occurred.',
                        icon: 'error',
                        button: 'OK',
                        closeOnClickOutside: false,
                    });
                }
            } catch (error) {
                setSpinnerLoading(false);
                console.error('Error resetting password:', error);
                setpasswordResetModalShow(false);
                console.error('Error requesting password reset:', error.response?.data?.message);
                console.error('Error requesting password reset:', error.message);
                swal({
                    text: `${error.response?.data?.message || error.message}`,
                    icon: 'error',
                    button: 'OK',
                }).then((willReload) => {
                    if (willReload) {
                        window.location.reload();
                    }
                });
            }
        } else {
            setSpinnerLoading(false);
            setErrors(formErrors);
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    async function handleImageDelete() {
        const loggedInEmail = {"email":cookies.clientEmail};
        console.log("logedemail", loggedInEmail)
        await axios.post(expressURL + "icatch/profile/deleteprofilelogo", loggedInEmail)
            .then(response => {
                console.log("delete", response.data);
                if (response.data.status === 200) {
                    setSelectedImage(null);
                    setImagePreviewUrl('');
                    swal({
                        title: 'Success!',
                        text: 'Profile Image deleted successfully.',
                        icon: 'success',
                        button: 'OK',
                        closeOnClickOutside: false,
                    }).then((willReload) => {
                        if (willReload) {
                            window.location.reload();
                        }
                    });
                } else {
                    console.error('Failed to delete client logo');
                }
            }).catch(error => {
                console.error('Error fetching client details:', error);
                swal({
                    title: 'Error!',
                    text: 'Something went wrong.',
                    icon: 'error',
                    button: "OK",
                    closeOnClickOutside: false,
                });
            })
    };

    /*const handleProfileUpdate = (e) => {
        e.preventDefault();
        setSpinnerLoading(true);
        Auth.currentAuthenticatedUser()
            .then(user => {
                // If both password fields are provided, attempt to change the password first
                if (password && newPassword) {
                    return Auth.changePassword(user, password, newPassword)
                        .then(() => {
                            console.log("Password changed successfully");
                            return user; // Return user to continue the chain
                        })
                        .catch(error => {
                            if (error.code === 'NotAuthorizedException') {
                                // Incorrect old password
                                console.error('Incorrect old password:', error);
                                swal({
                                    title: 'Error!',
                                    text: 'Incorrect old password. Please try again.',
                                    icon: 'error',
                                    button: 'OK',
                                    closeOnClickOutside: false,
                                });
                                throw new Error('Incorrect old password'); // Throw an error to stop the chain
                            } else {
                                throw error; // Propagate other errors
                            }
                        });
                } else {
                    return user; // No password change required, continue with the same user
                }
            })
            .then(user => {
                // Update other user attributes in Cognito
                return Auth.updateUserAttributes(user, {
                    'email': email,
                    'given_name': firstName,
                    'family_name': firstName,
                    /!*'custom:country': country,
                    'custom:city': state,*!/
                });
            })
            .then(() => {
                console.log("Profile updated in Cognito");

                // Prepare the FormData for the API call to update the profile in the database
                const formData = new FormData();
                formData.append('email', email);
                formData.append('full_name', firstName);
                formData.append('full_address', fullAddress);
                // formData.append('last_name', lastName);
                // formData.append('middle_name', '');
                /!*formData.append('city', state);
                formData.append('country', country);*!/

                console.log('Selected Image:', selectedImage);
                if (selectedImage) {
                    formData.append('logo', selectedImage);
                }

                // Call API to update the profile details in the database
                return axios.post(expressURL + "icatch/profile/updateprofiledetails", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                /!*!// Prepare the data for the API call to update the profile in the database
                const updatedDetails = {
                    email,
                    first_name: firstName,
                    last_name: lastName,
                    middle_name: '',  // You can set this if available
                    city: state,
                    country,
                };

                // Call the API to update the profile details in the database
                return axios.post(expressURL + "icatch/profile/updateprofiledetails", updatedDetails);*!/
            })
            .then(response => {
                if (response.data.status === 200) {
                    setSpinnerLoading(false);
                    console.log("Profile updated in DB");
                    swal({
                        title: 'Success!',
                        text: 'Your profile has been updated successfully.',
                        icon: 'success',
                        button: 'OK',
                        closeOnClickOutside: false,
                    }).then((willReload) => {
                        if (willReload) {
                            window.location.reload();
                        }
                    });
                } else {
                    setSpinnerLoading(false);
                    console.error('Failed to update profile in DB:', response.data.message);
                    swal({
                        title: 'Error!',
                        text: 'Failed to update your profile. Please try again.',
                        icon: 'error',
                        button: 'OK',
                        closeOnClickOutside: false,
                    });
                }
            })
            .catch(error => {
                setSpinnerLoading(false);
                console.error('Error updating profile:', error);
                if (error.message === 'Incorrect old password') {
                    // Old password was incorrect, error already handled above
                    return;
                }

                // Handle other errors
                swal({
                    title: 'Error!',
                    text: error.message || 'An error occurred while updating your profile. Please try again.',
                    icon: 'error',
                    button: 'OK',
                    closeOnClickOutside: false,
                });
            });
    };*/

    const validateForm = () => {
        let formErrors = {};
        if (!firstName) {
            formErrors.firstName = "Company name is required";
        }
        if (!fullAddress) {
            formErrors.fullAddress = "Full address is required";
        }
        return formErrors;
    };

    const handleProfileUpdate = (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        setSpinnerLoading(true);

        // Fetch the access token from cookies (assuming it's stored as a cookie)
        /*const accessToken = document.cookie
            .split('; ')
            .find(row => row.startsWith('accessToken='))
            ?.split('=')[1];*/
        const accessToken = cookies.clientAccessToken;

        if (!accessToken) {
            setSpinnerLoading(false);
            swal({
                title: 'Error!',
                text: 'You are not authenticated. Please log in again.',
                icon: 'error',
                button: 'OK',
                closeOnClickOutside: false,
            });
            return;
        }
        if (Object.keys(formErrors).length === 0) {
            // Prepare the FormData for the API call to update the profile in the database
            const formData = new FormData();
            formData.append('email', email);
            formData.append('full_name', firstName);
            formData.append('full_address', fullAddress);

            // If an image is selected, append it to the form data
            if (selectedImage) {
                formData.append('logo', selectedImage);
            }

            if (password && newPassword) {
                formData.append('oldPassword', password);
                formData.append('newPassword', newPassword);
            }

            //formData.append('accessToken', accessToken);

            // Call API to update the profile details in the database
            axios.post(expressURL + "icatch/profile/updateprofiledetails", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${accessToken}`
                },
            })
                .then(response => {
                    console.log("update response", response)
                    if (response.data.status === 200) {
                        setSpinnerLoading(false);
                        console.log("Profile updated in DB");
                        swal({
                            title: 'Success!',
                            text: 'Your profile has been updated successfully.',
                            icon: 'success',
                            button: 'OK',
                            closeOnClickOutside: false,
                        }).then((willReload) => {
                            if (willReload) {
                                window.location.reload();
                            }
                        });
                    } else {
                        setSpinnerLoading(false);
                        swal({
                            title: 'Error!',
                            text: response.data.message || 'Failed to update your profile. Please try again.',
                            icon: 'error',
                            button: 'OK',
                            closeOnClickOutside: false,
                        });
                    }
                })
                .catch(error => {
                    setSpinnerLoading(false);
                    console.log('Error updating profile:', error.response?.data?.message);
                    swal({
                        title: 'Error!',
                        text: error.response?.data?.message || error.message,
                        icon: 'error',
                        button: 'OK',
                        closeOnClickOutside: false,
                    });
                });
        } else {
            setSpinnerLoading(false);
            setErrors(formErrors);
            let errorMessage = Object.values(formErrors).join('\n');
            console.log("errorMessage", errorMessage);
            /*swal({
                title: 'Error!',
                text: errorMessage || 'Something went wrong.',
                icon: 'error',
                button: "OK",
            });*/
        }
    };

    // Handle country change
    const handleCountryChange = (event) => {
        const selectedCountry = event.target.value;
        setCountry(selectedCountry);
        setStates(countryStateData[selectedCountry] || []);
        setState('');
    };

    let loggedInEmail = cookies.clientEmail;
    console.log("loggedInEmail", loggedInEmail);

    return (
        <>
            <Modal show={passwordResetModalShow} onHide={handlePasswordCloseModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title className=" ">Reset Your Forgotten Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-7 p-0">
                                <div className="form-group">
                                    <input
                                        className="form-control on-select"
                                        placeholder="Email ID"
                                        value={email}
                                        readOnly="true"
                                    />
                                </div>
                            </div>
                            <div className="col-md-5 pr-0">
                                <button type="button"
                                        className="col-md-12 btn btn-upload shadow-sm py-1 f-14"
                                        onClick={handleResetRequest}
                                >Request Reset</button>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleResetConfirm}>
                        <div className="form-group">
                            <label>Verification Code*</label>
                            <input
                                type="text"
                                value={code}
                                onChange={(event) => setCode(event.target.value)}
                                className="form-control on-select"
                                placeholder="Enter the code sent to your email"
                            />
                        </div>

                        <div className="form-group">
                            <label>New Password*</label>
                            <div className="input-group">
                                <input
                                    type={requestNewPasswordVisible ? "text" : "password"}
                                    value={newPassword}
                                    onChange={(event) => {
                                        setNewPassword(event.target.value);
                                        if (errors.newPassword) {
                                            setErrors((prevErrors) => ({ ...prevErrors, newPassword: null }));
                                        }
                                    }}
                                    className="form-control on-select"
                                    placeholder="Enter your new password"
                                />
                                <div className="input-group-append cursor-pointer">
                                    <span className="input-group-text" onClick={toggleRequestNewPasswordVisibility}>
                                        {requestNewPasswordVisible ? <RemoveRedEyeOutlinedIcon className="pswrd-view-icon"/> : <VisibilityOffOutlinedIcon className="pswrd-view-icon"/>}
                                    </span>
                                </div>
                            </div>
                            {errors.newPassword && <div className="error-message c-red f-12">{errors.newPassword}</div>}
                        </div>
                        <div className="form-group">
                            <label>Confirm Password*</label>
                            <div className="input-group">
                                <input type={requestConfNewPasswordVisible ? "text" : "password"}
                                       value={reenterPassword}
                                       onChange={(event) => {
                                           setReenterPassword(event.target.value);
                                           if (errors.reenterPassword) {
                                               setErrors((prevErrors) => ({ ...prevErrors, reenterPassword: null }));
                                           }
                                       }}
                                       className="form-control on-select"
                                       placeholder="Reenter password" />
                                <div className="input-group-append cursor-pointer">
                                   <span className="input-group-text" onClick={toggleRequestconfNewPasswordVisibility}>
                                      {requestConfNewPasswordVisible ? <RemoveRedEyeOutlinedIcon className="pswrd-view-icon"/> : <VisibilityOffOutlinedIcon className="pswrd-view-icon"/>}
                                   </span>
                                </div>
                            </div>
                            {errors.reenterPassword && <div className="error-message c-red f-12">{errors.reenterPassword}</div>}
                        </div>
                        <div className="form-group">
                            <button type="submit" className="col-md-12 btn continue-btn shadow-sm">Reset Password</button>
                        </div>
                    </form>
                </Modal.Body>
                <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                    <MuiAlert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        Check your email for the verification code.
                    </MuiAlert>
                </Snackbar>
            </Modal>

            <div className="outer-main">
                <div className="main-container">
                    <div className="col-md-12 top-header py-3">
                        <div className="col-md-12 icon-wrap">
                            <ManageAccountsIcon className="head-icon"/>
                        </div>
                        <div className="col-md-12 head-wrap">
                            Edit Profile
                        </div>
                    </div>
                    <div className="col-md-12 py-3">
                        <div className=" col-md-12 py-3 inside-content-wrapper">
                            <div className="inner-gray-content-wrap p-3 profile-settings">
                                <form className="login-form profile-settings" onSubmit={handleProfileUpdate}>
                                    <div className="col-md-12 row d-flex mb-5">
                                        <div className="col-md-6 p-0 float-left">
                                            <div className="d-flex align-items-center">
                                                <div className="image-preview">
                                                    {/*<img src="/images/edit-profile.png" alt="Profile" className="profile-picture"/>*/}
                                                    {/*<img src={imagePreviewUrl} alt="Profile" className="profile-picture"/>*/}
                                                    {imagePreviewUrl ? (
                                                        <img src={imagePreviewUrl} alt="Profile" className="profile-picture" />
                                                    ) : (
                                                        <div className="profile-initial">
                                                            {loggedInEmail.charAt(0).toUpperCase()}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="profile-info ml-3">
                                                    <p className="profile-title">Profile Picture</p>
                                                    <p className="profile-subtext">PNG, JPEG under 15 MB</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 p-0 d-flex align-items-center justify-content-end">
                                            {/*<button type="button" className="btn btn-upload btn-sm shadow-sm mr-3">Upload New Picture</button>*/}
                                            <input
                                                type="file"
                                                accept="image/png, image/jpeg"
                                                onChange={handleImageChange}
                                                style={{ display: 'none' }}
                                                ref={fileInputRef}
                                            />
                                            <button
                                                type="button"
                                                className="btn btn-upload btn-sm shadow-sm mr-3"
                                                onClick={() => fileInputRef.current.click()}
                                            >
                                                Upload New Picture
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-delete btn-sm shadow-sm"
                                                onClick={handleImageDelete}
                                            >
                                                Delete
                                            </button>
                                            {/*<button type="button" className="btn btn-delete btn-sm shadow-sm">Delete</button>*/}
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label>Company Name</label>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Company Name"
                                                    value={firstName}
                                                    onChange={(event) => {
                                                        setFirstName(event.target.value);
                                                        if (errors.firstName) {
                                                            setErrors((prevErrors) => ({ ...prevErrors, firstName: null }));
                                                        }
                                                    }}
                                                    variant="standard"
                                                    className="mb-3 profile-input"
                                                />
                                                {errors.firstName && <div className="error-message c-red f-12">{errors.firstName}</div>}
                                            </div>
                                            {/*<div className="col-md-6 pt-3">
                                                <p></p>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Last Name"
                                                    value={lastName}
                                                    onChange={event => setLastName(event.target.value)}
                                                    variant="standard"
                                                    className="profile-input"
                                                />
                                                {errors.lastName && <div className="error-message c-red f-12">{errors.lastName}</div>}
                                            </div>*/}
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label className="mb-0">Contact Email</label>
                                        {/*<p className="p-form">Manage your accounts email address</p>*/}
                                        <TextField
                                            fullWidth
                                            placeholder="Email ID"
                                            value={email}
                                            onChange={event => setEmail(event.target.value)}
                                            variant="standard"
                                            className="mb-3 profile-input"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                        {errors.email && <div className="error-message c-red f-12">{errors.email}</div>}
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label>Full Address</label>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Full Address"
                                                    inputRef={addressRef}
                                                    value={fullAddress}
                                                    onChange={(event) => {
                                                        setFullAddress(event.target.value);
                                                        if (errors.fullAddress) {
                                                            setErrors((prevErrors) => ({ ...prevErrors, fullAddress: null }));
                                                        }
                                                    }}
                                                    variant="standard"
                                                    className="mb-3 profile-input"
                                                />
                                                {errors.fullAddress && <div className="error-message c-red f-12">{errors.fullAddress}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    {/*<div className="col-md-12 mb-3">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Country or region</label>
                                                    <select className="form-select form-select-sm"
                                                            value={country}
                                                            onChange={handleCountryChange}
                                                            aria-label="Country select"
                                                            defaultValue="">
                                                        <option disabled value="">Select country</option>
                                                        {Object.keys(countryStateData).map((country, index) => (
                                                            <option key={index} value={country}>{country}</option>
                                                        ))}
                                                    </select>
                                                    {errors.country && <div className="error-message c-red f-12">{errors.country}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>State or Province</label>
                                                    <select className="form-select form-select-sm"
                                                            value={state}
                                                            onChange={event => setState(event.target.value)}
                                                            aria-label="State select">
                                                        <option disabled value="">Select state</option>
                                                        {states.map((state, index) => (
                                                            <option key={index} value={state}>{state}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}

                                    <div className="col-md-12 mb-3">
                                        <div className="row">
                                            {/*<div className="col-md-6">
                                                <div className="row">*/}
                                            <div className="col-md-6">
                                                <label className="mb-0">Password</label>
                                                {/*<p className="p-form">Modify your current password</p>*/}
                                                <div className="input-wrapper">
                                                    <TextField
                                                        type={passwordVisible ? "text" : "password"}
                                                        fullWidth
                                                        placeholder="Current Password"
                                                        value={password}
                                                        onChange={event => setPassword(event.target.value)}
                                                        variant="standard"
                                                        className="mb-3 profile-input"
                                                    />
                                                    <span className="password-icon" onClick={togglePasswordVisibility}>
                                                                {passwordVisible ? <RemoveRedEyeOutlinedIcon className="pswrd-view-icon"/> : <VisibilityOffOutlinedIcon className="pswrd-view-icon"/>}
                                                            </span>
                                                </div>
                                                {errors.password && <div className="error-message c-red f-12">{errors.password}</div>}
                                            </div>
                                            <div className="col-md-6 input-padding">
                                                <div className="input-wrapper">
                                                    <TextField
                                                        type= {newPasswordVisible ? "text" : "password"}
                                                        fullWidth
                                                        placeholder="New Password"
                                                        variant="standard"
                                                        value={newPassword}
                                                        onChange={event => setNewPassword(event.target.value)}
                                                        className="profile-input"
                                                    />
                                                    <span className="password-icon" onClick={toggleNewPasswordVisibility}>
                                                                {newPasswordVisible ? <RemoveRedEyeOutlinedIcon className="pswrd-view-icon"/> : <VisibilityOffOutlinedIcon className="pswrd-view-icon"/>}
                                                            </span>
                                                </div>
                                            </div>
                                            {/*</div>
                                            </div>*/}
                                            {/*<div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <label className="mb-0">Forgot Password</label>
                                                        <p className="p-form">Reset Your Forgotten Password</p>
                                                        <TextField
                                                            fullWidth
                                                            placeholder="Email ID"
                                                            value={email}
                                                            variant="standard"
                                                            className="mb-3 profile-input f-12"
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                        />
                                                        {errors.email && <div className="error-message c-red f-12">{errors.email}</div>}
                                                    </div>
                                                    <div className="col-md-5 reset-password-button">
                                                        <button type="button"
                                                                className="col-md-12 btn btn-upload shadow-sm"
                                                                onClick={handleResetRequest}
                                                        >Request Reset</button>
                                                    </div>
                                                </div>
                                            </div>*/}
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <div className="row">
                                            <div className="col-md-6">
                                                {/*<label className="mb-0">Forgot Password</label>*/}
                                                {/*<p className="p-form">Reset Your Forgotten Password</p>*/}
                                                <a href="" className="forgot-password-link cursor-pointer"
                                                   onClick={(e) => {e.preventDefault(); handlePasswordOpenModal()}}>
                                                    <LockResetOutlinedIcon/> Reset Your Forgotten Password
                                                </a>
                                                {/*<div className="col-md-5 p-0 reset-password-button">
                                                    <button type="button"
                                                            className="col-md-12 btn btn-upload shadow-sm"
                                                            onClick={handlePasswordOpenModal}
                                                    >Forgot Password</button>
                                                </div>*/}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 d-flex justify-content-end mb-3">
                                        <button type="submit" className="btn continue-btn float-end shadow-sm">Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
}

export default ProfileSettings;
